define("browse/models/package_set", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "browse/mixins/cloudinary_image"], function (exports, _model, _attr, _relationships, _cloudinary_image) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _model.default.extend(_cloudinary_image.default, {
    description: (0, _attr.default)("string"),
    packageIds: (0, _attr.default)(),
    packageTypIeId: (0, _attr.default)("string"),

    packageType: (0, _relationships.belongsTo)("packageType", {
      async: false
    }),

    packages: (0, _relationships.hasMany)("package", {
      async: false
    }),

    isSet: true,

    quantity: computed("packages.@each.availableQuantity", function () {
      return this.get("packages").reduce(function (qty, pkg) {
        return qty + pkg.get("availableQuantity");
      }, 0);
    }),

    isAvailable: computed("packages.[]", "packages.@each.isAvailable", function () {
      return this.get("packages").filterBy("isAvailable").length > 0;
    }),

    images: computed("packages.@each.images.[]", function () {
      var images = [];
      this.get("packages").forEach(function (pkg) {
        var pkgImages = pkg.get("images") ? pkg.get("images").toArray() : [];
        images = images.concat(pkgImages);
      });
      return images;
    }),

    favouriteImage: computed("images.@each.favourite", function () {
      return this.get("images").filterBy("favourite").get("firstObject");
    }),

    otherImages: computed("images.[]", function () {
      var images = this.get("images").filter(function (image, index, self) {
        return self.findIndex(function (t) {
          return t.get("cloudinaryId") === image.get("cloudinaryId");
        }) === index;
      });
      return images.removeObject(this.get("favouriteImage"));
    }),

    sortedImages: computed("otherImages.[]", "image", function () {
      var images = this.get("otherImages").toArray();
      images.unshift(this.get("favouriteImage"));
      return images;
    }),

    displayImage: computed("images.@each.favourite", function () {
      return this.get("favouriteImage") || this.get("images").sortBy("id").get("firstObject") || null;
    }),

    displayImageUrl: computed("displayImage", function () {
      return this.get("displayImage.defaultImageUrl") || this.generateUrl(500, 500, true);
    }),

    previewImageUrl: computed("displayImage", function () {
      return this.get("displayImage.previewImageUrl") || this.generateUrl(265, 265, true);
    }),

    allPackageCategories: alias("packageType.allPackageCategories")
  });
});