define("browse/routes/authorize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var resolve = Ember.RSVP.resolve;
  var Route = Ember.Route;
  exports.default = Route.extend({
    loadIfAbsent: function loadIfAbsent(modelName, id) {
      var store = this.get("store");
      var cachedRecord = store.peekRecord(modelName, id);
      if (cachedRecord) {
        return resolve(cachedRecord);
      }
      return store.findRecord(modelName, id, { reload: true });
    },
    beforeModel: function beforeModel(transition) {
      if (!this.session.get("isLoggedIn")) {
        transition.abort();
        var loginController = this.controllerFor("login");
        loginController.set("attemptedTransition", transition);
        this.transitionTo("login");
        return false;
      }
      return true;
    }
  });
});