define("browse/models/role_permission", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    permissionId: (0, _attr.default)("number"),
    roleId: (0, _attr.default)("number"),

    permission: (0, _relationships.belongsTo)("permission", { async: false }),
    role: (0, _relationships.belongsTo)("role", { async: false })
  });
});