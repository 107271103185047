define("browse/controllers/search_organisation", ["exports", "browse/controllers/browse", "lodash"], function (exports, _browse, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var observer = Ember.observer;
  var debounce = Ember.run.debounce;
  var run = Ember.run;
  exports.default = _browse.default.extend({
    minSearchTextLength: 3,
    displayResults: false,
    hasSearchText: computed("searchText", function () {
      return $.trim(this.get("searchText")).length;
    }),

    onSearchTextChange: observer("searchText", function () {
      if (this.get("searchText").length > this.get("minSearchTextLength")) {
        return this.reloadResults();
      }
      this.set("displayResults", false);
    }),

    reloadResults: function reloadResults() {
      this.hideResults();
      debounce(this, this.showResults, 500);
    },
    hideResults: function hideResults() {
      var _this = this;

      run(function () {
        _this.set("displayResults", false);
      });
    },
    showResults: function showResults() {
      var _this2 = this;

      run(function () {
        _this2.set("displayResults", true);
      });
    },
    getSearchQuery: function getSearchQuery() {
      return {
        searchText: this.get("searchText")
      };
    },
    getPaginationQuery: function getPaginationQuery(pageNo) {
      return {
        per_page: 25,
        page: pageNo
      };
    },
    trimQuery: function trimQuery(query) {
      // Remove any undefined values
      return _lodash.default.pickBy(query, _lodash.default.identity);
    },


    actions: {
      selectOrganisation: function selectOrganisation(organisation) {
        this.transitionToRoute("account_details", {
          queryParams: { orgId: organisation.id }
        });
      },
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      },
      back: function back() {
        this.transitionToRoute("account_details");
      },
      loadMoreOrganisations: function loadMoreOrganisations(pageNo) {
        var params = this.trimQuery(_lodash.default.merge({
          url: "organisations/names"
        }, this.getSearchQuery(), this.getPaginationQuery(pageNo)));
        return this.store.query("organisation", params);
      }
    }
  });
});