define("browse/utils/helpers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.containsAny = containsAny;
  /********************/
  /* Helper functions */
  /********************/

  function containsAny(str, substrings) {
    for (var i = 0; i !== substrings.length; i++) {
      var substring = substrings[i];
      if (str.indexOf(substring) !== -1) {
        return true;
      }
    }
    return false;
  }
});