define("browse/components/focus-textfield", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var observer = Ember.observer;
  var TextField = Ember.TextField;
  exports.default = TextField.extend({
    tagName: "input",
    type: "text",
    maxlength: "25",
    attributeBindings: ["name", "id", "value", "placeholder"],

    triggerAutofocus: observer("value", function () {
      if (this.get("value").length === 0) {
        this.$().focus();
      }
    }),

    didInsertElement: function didInsertElement() {
      this.$().focus();
    },
    scrollToStart: function scrollToStart() {
      $(".fixed_search_header").addClass("absolute");
      $(".footer").addClass("absolute_footer");
      $(".search").addClass("no-padding");

      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    focusOut: function focusOut() {
      if (this.get("hasFixedInputHeader")) {
        $(".fixed_search_header").removeClass("absolute");
        $(".footer").removeClass("absolute_footer");
        $(".search").removeClass("no-padding");
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("hasFixedInputHeader")) {
        this.element.addEventListener("touchstart", this.scrollToStart);
      }
    }
  });
});