define("browse/controllers/my_account", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    accountService: service(),

    actions: {
      showPhoneOverlay: function showPhoneOverlay() {
        this.get("accountService").setOverlayVisibility(true);
      },
      gotoDeleteAccount: function gotoDeleteAccount() {
        this.transitionToRoute("delete_account");
      },


      //Fix: Too deeply nested component(3 levels) failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});