define("browse/helpers/display-datetime", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var buildHelper = Ember.Helper.helper;
  exports.default = buildHelper(function (value, params) {
    var parseDate = Date.parse(value);
    var _params$format = params.format,
        format = _params$format === undefined ? "DD MM YY" : _params$format;


    if (parseDate) {
      if (format === "preset:timeago") {
        return moment(parseDate).fromNow();
      }
      return moment(parseDate).format(params.format);
    } else {
      return "";
    }
  });
});