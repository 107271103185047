define("browse/controllers/package_set", ["exports", "browse/config/environment", "lodash"], function (exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Controller$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var computed = Ember.computed;
  var observer = Ember.observer;
  var alias = Ember.computed.alias;
  var empty = Ember.computed.empty;
  var gt = Ember.computed.gt;
  var sort = Ember.computed.sort;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  exports.default = Controller.extend((_Controller$extend = {
    messageBox: service(),
    application: controller(),
    packageCategory: controller(),
    requestedQty: 1,
    queryParams: ["categoryId", "sortBy"],
    prevPath: null,
    packageUnavailableInSet: false,
    categoryId: null,
    cart: service(),
    settings: service(),
    sortBy: "createdAt",
    noNextItem: empty("nextItem"),
    noPreviousItem: empty("previousItem"),
    hideThumbnails: gt("item.sortedImages.length", 1),
    smallScreenPreviewUrl: alias("item.displayImage.smallScreenPreviewImageUrl"),
    itemNotAvailableShown: false,
    hasCartItems: alias("application.hasCartItems"),
    isMobileApp: _environment.default.cordova.enabled,

    freeDeliveryEnabled: computed(function () {
      return this.get("settings").readBoolean("browse.free_delivery_enabled");
    }),

    freeDeliveryPackageId: computed(function () {
      return this.get("settings").readString("browse.free_delivery_package_id");
    }),

    freeDeliveryQuantityAvailable: computed(function () {
      var packageId = this.get("freeDeliveryPackageId");
      var pkg = this.store.peekRecord("package", packageId);
      return pkg && pkg.get("availableQuantity") > 0;
    }),

    direction: null,

    canRedirectToStock: computed.alias("session.currentUser.canRedirectToStock"),

    presentInCart: computed("model", "cart.counter", function () {
      var _this = this;

      if (this.get("model.isSet")) {
        var partOfSetPresentInCart = this.get("model.packages").map(function (pkg) {
          return _this.get("cart").contains(pkg);
        }).some(function (value) {
          return value;
        });
        return !!partOfSetPresentInCart;
      }
      return this.get("cart").contains(this.get("model"));
    }),

    allPackages: computed("model", "model.isSet", "model.packagesAndSets.@each.isAvailable", function () {
      var record = this.get("model");
      if (!record) {
        this.send("noItemsPresent");
        return [];
      }
      return record.get("isSet") ? record.get("packages") : [record];
    })
  }, _defineProperty(_Controller$extend, "packageUnavailableInSet", computed("model", "model.isSet", "allPackages.@each.isAvailable", "allPackages.@each.availableQuantity", function () {
    if (!this.get("model")) {
      return true;
    }
    var quantity = this.get("allPackages").any(function (pkg) {
      return pkg.get("allowWebPublish") === false || pkg.get("availableQuantity") === 0;
    });
    return !!quantity;
  })), _defineProperty(_Controller$extend, "categoryObj", computed("categoryId", function () {
    if (this.get("categoryId")) {
      return this.store.peekRecord("package_category", this.get("categoryId"));
    }
  })), _defineProperty(_Controller$extend, "linkDisplayName", computed("prevPath", "categoryObj", function () {
    var prevPath = this.get("prevPath");
    if (prevPath === "search_goods") {
      return this.get("i18n").t("search_goods.back");
    }
    return this.get("categoryObj.name");
  })), _defineProperty(_Controller$extend, "showPrevNextButtons", computed("prevPath", function () {
    return this.get("prevPath") !== "search_goods";
  })), _defineProperty(_Controller$extend, "selectedSort", computed("sortBy", function () {
    return [this.get("sortBy")];
  })), _defineProperty(_Controller$extend, "sortedItems", sort("categoryObj.packagesAndSets", "selectedSort")), _defineProperty(_Controller$extend, "nextItem", computed("model", "sortedItems.[]", function () {
    var currentItem = this.get("model");
    var items = this.get("sortedItems").toArray();
    return items[items.indexOf(currentItem) + 1];
  })), _defineProperty(_Controller$extend, "previousItem", computed("model", "sortedItems.[]", function () {
    var currentItem = this.get("model");
    var items = this.get("sortedItems").toArray();
    return items[items.indexOf(currentItem) - 1];
  })), _defineProperty(_Controller$extend, "previewUrl", computed("item.previewImageUrl", {
    get: function get() {
      return this.get("item.previewImageUrl");
    },
    set: function set(key, value) {
      return value;
    }
  })), _defineProperty(_Controller$extend, "setAndRedirectToCategory", function setAndRedirectToCategory(category) {
    if (category) {
      var parentId = category.get("parentId");
      if (parentId) {
        this.get("packageCategory").set("selectedCategoryId", category);
      }
      this.transitionToRoute("package_category", parentId || category.id);
    } else {
      this.transitionToRoute("browse");
    }
  }), _defineProperty(_Controller$extend, "actions", {
    setRequestedQty: function setRequestedQty(value) {
      this.set("requestedQty", value);
    },
    showPreview: function showPreview(image) {
      this.set("previewUrl", image.get("previewImageUrl"));
    },

    noItemsPresent: function noItemsPresent() {
      var _this2 = this;

      var i18n = this.get("i18n");
      this.get("messageBox").custom(i18n.t("browse.item_unavailable"), i18n.t("okay"), function () {
        _this2.transitionToRoute("browse");
      });
    },
    goToStockItem: function goToStockItem(inventoryNumber) {
      var finalUrl = void 0;

      if (this.get("isMobileApp") && cordova.platformId === "android") {
        // jshint ignore:line
        finalUrl = "android-app://hk.goodcity.stockstaging/https/" + _environment.default.APP.STOCK_ANDROID_APP_HOST_URL + "/items/" + inventoryNumber;
        window.open(finalUrl, "_system");
      } else {
        finalUrl = _environment.default.APP.STOCK_APP_HOST_URL + "/items/" + inventoryNumber;
        window.open(finalUrl, "_blank");
      }
    },
    setDirectionAndRender: function setDirectionAndRender(direction) {
      this.set("direction", direction);
      var targetItem = direction === "moveRight" ? this.get("previousItem") : this.get("nextItem");

      if (targetItem) {
        if (targetItem.get("isItem")) {
          this.transitionToRoute("item", targetItem, {
            queryParams: {
              sortBy: this.get("sortBy"),
              categoryId: this.get("categoryId")
            }
          });
        } else {
          this.transitionToRoute("package", targetItem, {
            queryParams: {
              sortBy: this.get("sortBy"),
              categoryId: this.get("categoryId")
            }
          });
        }
      }
    },
    setChildCategory: function setChildCategory(category) {
      this.setAndRedirectToCategory(category);
    },
    back: function back() {
      var prevPath = this.get("prevPath");
      if (prevPath === "search_goods") {
        return this.transitionToRoute("search_goods");
      }
      this.setAndRedirectToCategory(this.get("categoryObj"));
    }
  }), _Controller$extend));
});