define("browse/models/purpose", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var TRANSLATION_KEYS = {
    trade: "order.for_charity_sale",
    organisation: "order.for_our_charity",
    client: "order.for_client"
  };

  exports.default = _model.default.extend({
    i18n: service(),

    nameEn: (0, _attr.default)("string"),
    nameZhTw: (0, _attr.default)("string"),
    identifier: (0, _attr.default)("string"),

    description: computed("nameEn", function () {
      var name = this.get("nameEn");
      return name && this.get("i18n").t(TRANSLATION_KEYS[name.toLowerCase()]);
    })
  });
});