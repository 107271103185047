define("browse/routes/orders/goods", ["exports", "browse/routes/orders/detail"], function (exports, _detail) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var all = Ember.RSVP.all;
  var hash = Ember.RSVP.hash;
  exports.default = _detail.default.extend({
    loadPackagesOf: function loadPackagesOf(order) {
      var _this = this;

      return this.get("store").query("orders_package", {
        search_by_order_id: order.get("id")
      }).then(function (ops) {
        return all(ops.map(function (op) {
          return _this.loadIfAbsent("package", op.get("packageId"));
        }));
      });
    },
    model: function model(params) {
      var _this2 = this;

      return this._super.apply(this, arguments).then(function (data) {
        var order_ids = params.order_id;
        var store = _this2.get("store");
        data.gcRequests = store.query("goodcity_request", { order_ids: order_ids });
        data.packages = _this2.loadPackagesOf(data.order);
        return hash(data);
      });
    }
  });
});