define("browse/routes/login", ["exports", "browse/routes/session"], function (exports, _session) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    controllerName: "authenticate",

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set("mobile", null);
      controller.set("email", null);
    }
  });
});