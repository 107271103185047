define("browse/routes/package_set", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _browse_pages.default.extend({
    model: function model(params) {
      return this.store.peekRecord("package_set", params["id"]);
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      if (model) {
        controller.set("previewUrl", model.get("previewImageUrl"));
      }
      this.controllerFor("application").set("pageTitle", this.get("i18n").t("itemdetail.view"));
    }
  });
});