define("browse/components/book-appointment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    session: service(),
    settings: service(),

    allowAppointments: computed(function () {
      return this.get("settings").readBoolean("browse.allow_appointments");
    }),

    disabled: computed.not("allowAppointments"),

    actions: {
      redirectAsPerUserDetails: function redirectAsPerUserDetails() {
        if (this.get("session").accountDetailsComplete()) {
          this.get("router").transitionTo("request_purpose", {
            queryParams: {
              bookAppointment: true,
              orderId: null,
              prevPath: this.get("prevPath") || null,
              editRequest: null
            }
          });
        } else {
          this.get("router").transitionTo("account_details", {
            queryParams: { bookAppointment: true, onlineOrder: false }
          });
        }
      },
      redirectToLogin: function redirectToLogin() {
        this.get("router").transitionTo("login", {
          queryParams: { bookAppointment: true }
        });
      }
    }
  });
});