define("browse/models/user", ["exports", "ember-data/attr", "browse/models/addressable", "ember-data/relationships"], function (exports, _attr, _addressable, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _addressable.default.extend({
    firstName: (0, _attr.default)("string"),
    lastName: (0, _attr.default)("string"),
    mobile: (0, _attr.default)("string"),
    createdAt: (0, _attr.default)("date"),
    email: (0, _attr.default)("string"),
    title: (0, _attr.default)("string"),
    isEmailVerified: (0, _attr.default)("boolean"),
    isMobileVerified: (0, _attr.default)("boolean"),

    organisations: (0, _relationships.hasMany)("organisation", {
      async: false
    }),
    organisationsUsers: (0, _relationships.hasMany)("organisationsUsers", {
      async: false
    }),

    activeOrganisationsUsers: computed.filterBy("organisationsUsers", "isActive"),
    activeOrganisations: computed.mapBy("activeOrganisationsUsers", "organisation"),

    defaultOrganisationsUser: computed("activeOrganisationsUsers.[]", function () {
      return this.get("activeOrganisationsUsers").sortBy("id").get("lastObject");
    }),

    defaultOrganisation: computed.alias("defaultOrganisationsUser.organisation"),

    userRoles: (0, _relationships.hasMany)("userRoles", {
      async: false
    }),
    roles: (0, _relationships.hasMany)("roles", {
      async: false
    }),

    currentUserRoles: computed("userRoles.[]", function () {
      return this.get("userRoles").map(function (userRole) {
        return userRole.get("role");
      });
    }),

    canRedirectToStock: computed("currentUserRoles", function () {
      var roles = this.get("currentUserRoles");
      return roles.find(function (r) {
        return r.get("permissionNames").indexOf("can_manage_packages") >= 0 && r.get("permissionNames").indexOf("can_login_to_stock") >= 0;
      });
    }),

    mobileWithoutCountryCode: computed("mobile", function () {
      var mobile = this.get("mobile");
      return mobile ? mobile.indexOf("+852") >= 0 ? mobile.substring("4") : mobile : "";
    }),

    positionInOrganisation: computed("organisationsUsers.[]", function () {
      var organisationsUser = this.get("organisationsUsers.firstObject");
      return organisationsUser ? organisationsUser.get("position") : "";
    }),

    preferredContactNumber: computed("organisationsUsers.[]", function () {
      var organisationsUser = this.get("organisationsUsers.firstObject");
      return organisationsUser ? organisationsUser.get("preferredContactNumber") : "";
    }),

    organisationName: computed("organisationsUsers.[]", function () {
      var organisation = this.get("organisationsUsers.firstObject.organisation");
      var language = JSON.parse(window.localStorage.getItem("language"));
      if (organisation) {
        return language === "en" ? organisation.get("nameEn") : organisation.get("nameZhTw");
      } else {
        return "";
      }
    }),

    fullName: computed("firstName", "lastName", function () {
      return this.get("title") + " " + this.get("firstName") + " " + this.get("lastName");
    }),

    isInfoComplete: computed("firstName", "lastName", "email", "title", function () {
      return this.hasValue("firstName", "lastName", "email", "title");
    }),

    hasValue: function hasValue() {
      var hasValue = true;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      for (var i = 0; i < args.length; i++) {
        if (!this.get(args[i]) || !this.get(args[i]).length) {
          hasValue = false;
          break;
        }
      }
      return hasValue;
    },


    //*****************
    // Utility methods
    //*****************

    roleNames: function roleNames() {
      return this.get("userRoles").getEach("role.name");
    },
    hasRole: function hasRole(role) {
      return this.roleNames().indexOf(role) >= 0;
    }
  });
});