define("browse/locales/zh/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    offline_error: "未能完成操作，請檢查網路連線。",
    "language.en": "English",
    "language.zh": "中文",
    "company.name": "國際十字路會",
    by: "了解",
    ok: "確定",
    unexpected_error: "出錯了",
    not_allowed_error: "你未獲授權進行此動作。",
    QuotaExceededError: "網站可能在瀏覽器的<b>私密瀏覽模式</b>下不能正常運作。請嘗試</br><ul><li><a href='http://www.apple.com/itunes/' style='color: black!important; background-color: #dee4eb !important;'>下載iOS 應用程式</a></li><li>使用瀏覽器的常規模式</li><li>使用Chrome 的私密瀏覽模式</li></ul>",
    okay: "確定",
    go_to_top: "到頁頂",
    show: "顯示",
    date: "日期",
    edit: "修改",
    time: "時間",
    contact: "聯絡電話",
    draft: "等待提交",
    submitted: "已提交",
    processing: "處理中",
    closed: "已完成",
    cancelled: "已取消",
    not_now: "稍後",
    not_found: "對不起，您想要的物資已不存在。",
    awaiting_dispatch: "等待派送",
    dispatching: "派送中",
    items_not_available: "您的購物車内有已不存在的物資",
    unauthorized: "您沒有此權限。",
    back: "返回",
    no_search_results: "沒有搜尋結果",
    cancel: "取消",
    continue: "繼續",
    save_changes: "儲存修正",
    discard_changes: "放棄修正",
    morning: "早上",
    afternoon: "下午",
    invalid_category_error: "你所搜尋的種類不存在。",

    messages: {
      you: "閣下",
      chat_note: "有關這個指示請跟我們的職員聯絡",
      send: "傳送",
      day: {
        today: "今天",
        morning: "早上",
        afternoon: "下午"
      },
      offer_details: "捐贈詳情",
      your_response: "你的回覆",
      supervisor_warning: "此對話功能為慈善機構員工專用。請各職員及義工使用管理程式作溝通。"
    },

    banner: {
      presented_by: "建立機構",
      funded_by: "捐助機構"
    },

    application: {
      loading: "正在加載...",
      sidebar: {
        home: "主頁",
        browse: "網上瀏覽",
        appointments: "到訪我們的中心",
        offers: "直接從捐贈者接收",
        faq: "常問問題",
        about: "關於我們",
        terms: "條款",
        privacy_policy: "私隱政策",
        version: "版本",
        login: "登入"
      }
    },

    my_notifications: {
      heading: "{{name}}的捐贈",
      all_notifications: "所有新信息",
      show_unread: "顯示未讀信息",
      mark_all_read: "全部標示為已讀",
      no_unread: "沒有未讀信息"
    },

    submitted_orders: {
      submit_order: "提交訂單",
      select_option: "請揀選以下選項",
      create_new: "為這些物品建立新訂單",
      state: "狀態",
      due_date: "到期日",
      client_name: "服務使用者名稱",
      add_items: "新增這些物品至 <b>{{code}}</b>",
      not_confirmed: "未確定"
    },

    browse: {
      title: "好人好巿 (慈善組織)",
      browse_title: "瀏覧貨品",
      all_goods: "所有物資",
      filter_results: "過濾這些結果 <span>&#x25be;</span>",
      intro_text: "如果你是香港非牟利機構的職員，你可以瀏覽下面的類別或使用上面的圖示搜索，並申請你需要的物品。",
      ngo_offer: "特別優惠:",
      ngo_offer_description: "點擊申請免費送貨（僅限網上訂單）。",
      ngo_offer_more_details: "請在此處閱讀有關申請過程及更多詳細資訊。",
      faq: "請在此處閱讀有關申請過程及更多詳細資訊。",
      item_unavailable: "物品未能提供。"
    },

    home: {
      introduction: "供香港慈善機構及其服務使用者申請的高質素捐贈物品。",
      mobile_introduction: "以高質素捐贈物品幫助香港社會",
      introduction_video: "觀看簡介",
      faq_text: "申請資格及詳情",
      access_goods: "申領物資的三種方法",
      learn_more: "了解更多",
      order_goods: {
        title: "網上瀏覽我們的庫存",
        covid: "因應新冠病毒疫情，直至五月三十一日期間，未能安排自取物品。只限送貨。",
        subtitle: "網上訂單會由我們屯門會址送出。",
        action: "瀏覽物資",
        learn_how: "瀏覽教學"
      },
      visit: {
        title: "預約到訪我們的中心",
        covid: "因應新冠病毒疫情，暫時關閉。立即登記五月三十一日後的預約。",
        subtitle: "在我們屯門會址選取物資",
        action: "預約時間",
        new_appointment: "新的預約"
      },
      offers: {
        title: "直接從捐贈者接收",
        subtitle: "瀏覽及回應在香港各區的捐贈."
      }
    },

    about: {
      title: "關於",
      introduction: {
        opening: "好人好市令社福機構申領物資變得更容易。你需要在首次登記時，揀選你所屬的機構並進行登記。如你未能從清單上找到你所屬的機構，請<a href='mailto:contact@goodcity.hk'>聯絡我們</a>。",
        eligible_orgs: "符合資格的機構包括：",
        charities: "獲豁免繳稅的慈善團體 <a href='https://www.ird.gov.hk/chi/tax/ach_index.htm'>(詳列見此)</a>",
        social_welfare: "社福機構單位 <a href='https://www.swd.gov.hk/tc/index/site_download/page_listofserv/'>(詳列見此)</a>",
        educational: "教學團體及機構 <a href='https://applications.edb.gov.hk/schoolsearch/schoolsearch.aspx?langno=2'>(詳列見此)</a>",
        other_groups: "其他團體及機構將按個別服務性質處理及審批",
        ways: "我們的服務有三種模式...",
        browse_online: "網上瀏覽/申領：",
        browse_online_desc: "網上訂單會由我們屯門會址送出",
        book: "預約到訪我們的中心：",
        book_desc: "親身在我們屯門會址選取物資",
        direct: "直接從捐贈者接收：",
        direct_desc: "瀏覽及回應在香港各區的捐贈",
        faq: "查看我們的常見問題"
      },
      user_guide_title: "使用指南",
      tap_button: "點擊下面的按鈕開始，或",
      see_our_faqs: "查看我們的常見問題",
      coming_soon: "即將面世",
      transport_options: "運輸選項",
      collection_transport_description: "自行安排車輛於指定時間到國際十字路會提取物資",
      van_transport_description: "國際十字路會安排租用貨車於指定時間將物資運送到受惠人或受惠機構地址",
      van_transport_note: "請注意，貨車租用費需由受惠人或受惠機構承擔。除特殊情況外，我們一般不能提供免費運送物資服務。",

      order_goods: {
        title: "網上申領",
        action: "網上瀏覽",
        step_1: {
          desc: "於網上瀏覽一系列可供選取物資"
        },
        step_2: {
          desc: '新增物資到 "購物車" 然後提交'
        },
        step_3: {
          desc: "請填妥下列資料:",
          bullet_1: "聯絡資料(新用戶適用)",
          bullet_2: "申請物資原因",
          bullet_3: "受惠人資料(如代服務使用者申請)",
          bullet_4: "運輸選項(親臨本會提取物資/安排運送服務)"
        },
        step_4: {
          desc: "我們團隊將會",
          bullet_1: "核對您的申請資格(新用戶適用)",
          bullet_2: "致電確認您的訂單及核對其他資料",
          bullet_3: "準備好您的已選物資，等待您來提取或安排運送。"
        }
      },

      visit: {
        title: "",
        action: "預約時間",
        new_appointment: "新的預約",
        guide_intro: "\n        \u5982\u679C\u60A8\u4EE3\u8868\u4EFB\u4F55\u5728\u9999\u6E2F\u7279\u5225\u884C\u653F\u5340\u8A3B\u518A\u7684\u975E\u725F\u5229\u6A5F\u69CB\u6216\u662F\u9999\u6E2F\u653F\u5E9C\u5C6C\u4E0B\u793E\u6703\u798F\u5229\u6A5F\u69CB\uFF0C\u60A8\u53EF\u4EE5\u7533\u8ACB\u9810\u7D04\u6642\u9593\u89AA\u81E8\u672C\u6703\u6311\u9078\u7269\u8CC7\u3002\u4EE5\u4E0B\u662F\u7533\u8ACB\u7A0B\u5E8F:\n      ",
        guide_step_1: "用您的手提電話號碼登記(或登入)",
        guide_step_2: "請詳細描述您所需要的物資類別",
        guide_step_3: "選擇預約時間",
        guide_step_4: "我們同事將會致電確認您的預約",
        onsite_guide_intro: "當您(或您的服務使用者)於預約時間到達本會:",
        onsite_guide_step_1: "我們將會協助您於存貨中選擇適合您的物資",
        onsite_guide_step_2: "如果您要求了租用貨車，我們會幫您安排車輛。注意: 你 (或你的服務使用者) 需支付運送費用",
        onsite_guide_step_3: "載貨然後將物資運送到收貨地點"
      },

      direct: {
        title: "直接從捐贈者接收",
        intro: "我們可以讓你的慈善機構與捐贈者聯繫，直接從他們那裏接收物品。",
        step_1: "查看您感興趣的捐贈。",
        step_2: "點擊“回應此捐贈”",
        step_3: "使用您的手機號碼註冊（或登錄）（新用戶登錄後將被要求提供一些基本的資料作註冊。）",
        step_4: "描述你對這些物品感興趣的原因",
        step_4a: "使用螢幕底部的聊天工具告訴我們的工作人員您感興趣的捐贈物品。",
        step_4b: "我們的工作人員將與捐贈者協調。如果捐贈者希望與你聯絡，我們會將讓你們聯繫起來。",
        action: "查看捐贈"
      }
    },

    offers: {
      no_offers_available: "There are currently no offers available directly from donors. You may wish to access the stock in our Tuen Mun Center: either",
      browse_online: "browse stock online",
      or: ", or",
      book_to_visit: "book to visit.",
      view_my_offers: "To view offers you have responded to in the past,",
      go_to_dashboard: "go to your dashboard.",
      available_offers: "Offer(s) below are available directly from donors located around Hong Kong. Non-profit organizations can express their interest by responding to an offer.",
      available_offers_read_more: "Read more details about the process here."
    },

    category: {
      filter: "過濾項",
      all: "所有",
      sort: {
        title: "選項",
        newfirst: "最近放在最先",
        oldfirst: "最舊放在最先"
      }
    },

    itemdetail: {
      view: "查看項目",
      previous: "上一項",
      next: "下一項",
      condition: "質量",
      quantity: "數量",
      description: "描述",
      dimension: "尺寸",
      size: "尺寸",
      categories: "分類",
      image_unavailable: "沒有照片提供",
      inventory_number: "存貨編號"
    },

    shareableOffers: {
      list_of_items: "物品清單",
      offer_details: "捐贈詳情",
      no_description_available: "沒有物品詳細資料",
      offer_instruction: "捐贈教學",
      offered: "已捐贈",
      view_offer: "查看捐贈",
      chat_message_for_expired_offers: "此捐贈不再接受申請。請查看其他捐贈。",
      chat_message_for_offers: "請使用下面的聊天工具與Goodcity的工作人員溝通，以表示您對本捐贈的興趣。",
      respond_offer: "回覆此捐贈",
      disabled_chat: "聊天工具已被停用。",
      show_offer_details: "顯示捐贈詳情",
      hide_offer_details: "隱藏捐贈詳情"
    },

    page: {
      previous: "上一頁",
      next: "下一頁"
    },

    login: {
      hk_mobile_or_email_only: "流動電話號碼（只局限於香港）/電郵地址",
      login: "登入",
      welcome_text: "歡迎",
      get_started: "在此開始",
      input_mobile_email: "請提供您的電郵地址或手機號碼",
      sms_email_pincode: "我們會發訊息或電郵通知閣下的驗證碼以便安全性登入。曾登入過嗎？請使用相同的電話號碼或電郵地址。",
      continue: "繼續",
      privacy: "我們不會公開或分享您的資料。我們只會使用以完成您的申請。請看 ",
      policy: "隱私政策",
      mobile_email_warning: "請提供正確的電郵或香港手機號碼",
      explanation: "如果你是香港非牟利機構或社會福利組織的員工，你可以預約（可由員工親身或由你的服務使用者）在我們的屯門會址揀選物資。",
      explanation_read_more_link: "請在此處閱讀有關申請過程及更多詳細資訊。"
    },

    search_goods: {
      contact_us: "未能找到需要的物資？請聯絡我們以便進一步協助。",
      placeholder: "搜尋物資",
      title: "搜尋結果",
      done: "完成",
      search_info: "你可以搜尋物資，例如「書枱」或「嬰兒車」",
      back: "＜ 返回搜尋"
    },

    account: {
      account_details: "帳號詳情",
      welcome: "歡迎！",
      contact_details: "請完成聯絡資料以繼續",
      organisation: "機構名稱",
      title: "稱謂",
      first: "名",
      last: "姓",
      position: "職位",
      email: "電郵",
      mobile: "流動電話號碼 (非必須)",
      mobile_label: "允許您申請來自好人好巿的短訊更新",
      preffered_contact_number: "選取這聯絡號碼",
      work: "請使用工作地址",
      done: "完成",
      back: "Back",
      contact_us: "未能尋找你的機構？請聯絡我們尋求協助。",
      organisation_warning: "你必須選擇有效的機構以繼續",
      title_warning: "請輸入稱謂",
      first_name_warning: "請輸入名字",
      last_name_warning: "請輸入姓氏",
      position_warning: "請輸入職位",
      mobile_warning: "無效的流動電話號碼",
      email_warning: "請輸入有效的電子郵件地址",
      preffered_contact_label: "請提供我們可聯絡您的電話號碼。",
      preffered_contact_warning: "閣下需要提供聯絡號碼",
      user_info: "用戶資料不齊",
      change_phone_number: {
        title: "變更電話號碼",
        description: "請輸入新的電話號碼",
        change_phone_number: "請輸入收到的驗證碼"
      },
      user_title: {
        mr: "先生",
        mrs: "太太",
        miss: "小姐",
        ms: "女士"
      },
      delete: "刪除賬戶"
    },

    delete_account: {
      title: "刪除賬戶",
      crf_privacy_policy: "國際十字路會私隱政策",
      section1: "刪除你的賬戶後，你將無法再登入GoodCity.HK",
      section2: "如若將來你想再使用服務，歡迎你重新建立新賬戶。",
      section3: "請注意你的部分資料可能會被保留，以符合的合規和審計要求。",
      section4: "所有資料將按照我們的政策處理。",
      section5: "如若按下面的按鈕，你將會立即登出GoodCity.HK的服務。",
      yes_delete: "是的，請刪除我的賬戶！",
      cancel: "取消",
      goback: "返回",
      view_orders: "瀏覽訂單",
      sorry1: "抱歉！在選訂過程中，你恕未能刪除你的賬戶。",
      sorry2: "你可以取消訂單，又或待收妥物資後，你的申領申請就會自動完成。",
      sorry3: "如需更多幫助，請在選訂過程中聯絡我們。"
    },

    logout: {
      logout: "登出"
    },

    _resend: {
      try_again: "請在 {{timer}} 秒後再試",
      please_wait: "請等候 {{timer}} 秒以重新索取驗證碼",
      no_sms: "收不到短訊？",
      email_correct: "{{email}} 是否正確? ",
      phone_correct: "{{phone}} 是否正確? ",
      go_back: "返回輸入頁",
      check_junk: "請檢查您的垃圾郵箱",
      not_working: "仍然未能繼續 ? 請聯絡 <a class='auth_links' href='mailto:contact@goodcity.hk'>contact@goodcity.hk</a> 尋求協助.",
      resend: "重新發送"
    },

    _verification_pin: {
      send_code: "我們已發送單次密碼至",
      input_code: "請輸入密碼以保安檢查",
      auth_error: "抱歉! 請輸入正確密碼"
    },

    gogovan: {
      book_van: {
        title: "預約貨車",
        location: "接收地點（只限香港）",
        select_day: "日期/時間",
        crossroads_time: "只限十字路會辦公時間",
        requirements: "額外要求",
        speak_english: "需以英語溝通 + $5",
        van_trolly_cost: "需借用手推車（每輛10元）",
        truck_trolly_cost: "需借用手推車（每輛20元）",
        porterage: "需協助搬運（需與司機商量）",
        longer_goods: "貨物長於6尺（183厘米)",
        longer_goods_6ft: "貨物長於6尺（183厘米） +$10",
        longer_goods_6ft_tall: "貨物長於6尺（183厘米）和高於2尺（60厘米）+$20",
        extra_time_charge: "附加時間（首15分鐘的上貨時間是免費的，超時會有額外收費。）",
        date: "日期",
        time: "時間",
        am: "上午",
        pm: "下午",
        vehicle: "車輛種類",
        delivery_district: "接收地點",
        learn_about_prices: "請到GoGoVan網站查閲更多收費詳情。",
        best_price_estimate: "預計",
        delivery_contact_person: "聯絡人姓名",
        booking_with_name: "閣下的名字及聯絡電話會用作預約GoGoVan之用。",
        driver_call_to_confirm: "司機會致電該號碼以核對預約詳情和接收時間。"
      }
    },

    cancel_booking: {
      title: "取消預約",
      cancel_booking_warning: "取消今次預約",
      draft_cancel_booking_warning: "這將會永遠取消在今次預約內的所有資料.",
      cancel_booking_info: "這將會取消今次預約及其被需求貨品的有關要求.",
      cancel_booking_reason_title: "取消預約的原因"
    },

    my_dashboard: {
      title: "控制頁",
      orders: "訂單",
      bookings: "Bookings",
      online_orders: "網上訂單",
      create_new_booking: "建立新的預約",
      account: {
        account_text: "帳戶",
        name: "名稱",
        your_details: "你的資料",
        verified_text: "已認證"
      },
      from_donors: {
        offer: "Offer",
        open_for_response: "接受新的回覆",
        closed_for_response: "不接受新的回覆",
        respond_offers: "查看/回覆捐贈",
        your_responses: "你對捐贈者的回應",
        title: "直接從捐贈者接收",
        closed_offer: "未能提供你所找尋的捐贈。"
      }
    },

    my_orders: {
      my_orders: "我的訂單",
      processing: "處理中",
      submitted: "訂單已經提交",
      draft: "等待提交",
      submitted_by: "提交者",
      due_date: "到期日",
      submitted_for_client: "為服務使用者提交",
      awaiting_dispatch: "等待派送",
      cancelled: "已取消",
      dispatching: "派送中",
      closed: "訂單已完成",
      order_transports: {
        gogovan_transport: "由貨車運送",
        collection_transport: "自行提取",
        appointment_transport: "預約",
        shipment_transport: "運送安排",
        unknown_transport: "其他"
      },
      details: {
        note: "注意：如你的訂單已獲確定，你必須聯絡我們團隊以更改訂單內的物品。",
        link_to_browse: "瀏覽物資並將物資加入此訂單",
        update_message: "本訂單的內容已更新。",
        type: "輸入",
        transport: "交通",
        appointment: "預約",
        goods_requested: "已申請貨物",
        goods_ordered: "貨物",
        not_applicable: "不适用的",
        request: {
          request_purpose: "申請目的",
          purpose_of_goods: "貨物用途",
          number_benefiting: "受惠數量",
          description_of_needs: "申請詳情"
        },
        beneficiary: {
          appointment_details: "預約的資料",
          client_information: "服務對象資料",
          id_type: "所持身份證種類",
          id_number: "身份證號碼",
          client_name: "服務對象姓名",
          client_phone: "服務對象電話號碼"
        },
        tabs: {
          booking: "預約",
          goods: "貨物"
        },
        state_titles: {
          draft: "草稿（未提交）",
          submitted: "已提交（未確認）",
          processing: "處理中",
          awaiting_dispatch: "安排中（未發送）",
          dispatching: "已發送",
          closed: "已完成",
          cancelled: "已取消"
        },
        state_descriptions: {
          draft: "訂單並未提交。您仍然可以修改訂單。",
          submitted: "為了獲得更詳細資料，我們的職員可能會與你或受惠人聯絡。",
          dispatching: "貨物在運送途中。",
          processing: "我們的職員正在處理您的訂單。他們可能會與您聯絡已獲得更多信息。如果一切正常，他們將安排您的貨品發貨。",
          awaiting_dispatch: "您的訂單已安排發貨。 一旦發貨，此頁面便會更新。 ",
          closed: "此訂單已完成，所有貨物已成功發貨。如有任何其他申請，請提交另一訂單。",
          cancelled: "取消"
        },
        types: {
          visit: "參觀貨倉",
          delivery: "網上訂單"
        },
        transports: {
          gogovan_transport: "租商用車輛",
          collection_transport: "親身提取",
          unknown_transport: "其他"
        }
      }
    },

    requested_package: {
      max_quantity: "\u6578\u91CF (max {{quantity}})",
      boundaries: "\u6578\u91CF\u7531 1 \u81F3 {{max}}",
      single_quantity: "\u53EA\u80FD\u63D0\u4F9B 1 \u4EF6\u7269\u54C1"
    },

    item: {
      request_item: "申請項目",
      remove_item: "刪除項目",
      unavailable_item: "此物品已沒有存貨。"
    },

    cart_content: {
      your_request: "您的申請",
      continue_browsing: "繼續瀏覽",
      submit_request: "提交申請",
      remove: "刪除",
      unavailable: "對不起！這件物資已不存在。",
      notice: "成功申請的物資將於一至兩個十字路會工作天内送達。",
      empty_cart: "您的購物車是空的。",
      find_items: "尋找合適的物資。",
      unavailable_and_add_item_to_proceed: "這件物資已不存在。下訂單前請在購物車中添加新的物資。"
    },

    order: {
      flash_submit_message: "已成功提交訂單",
      flash_cancelled_message: "已取消訂單",
      organisation: "機構",
      order_delete_confirmation: "這動作會刪除所有訂單資料，並不能復原。",
      representative: "代表人員",
      cancel_order: "取消訂單",
      default_reason: "改變了主意",
      purpose: "申請目的",
      login: "登入",
      register: "註冊",
      goodcity_order: "好人好市訂單",
      cart: "購物車",
      order_details: "訂單詳情",
      transport_detail: "運輸詳情",
      confirm: "確認資料",
      tick_any: "選擇任何適用的選項",
      select_purpose: "請選擇申請目的",
      for_our_charity: "為機構申請（設施/活動等）",
      for_client: "為服務對象申請（私人用途）",
      for_charity_sale: "為慈善義賣申請（任何形式的交易）",
      return_to_cart: "返回購物車",
      submit_this_order: "確認訂單",
      limited_crossroads_opening_days_and_time: "僅限十字路會的辦公時間。",
      continue_to_confirm_details: "繼續",
      continue_to_transport_details: "繼續",
      hired_van: "已預約貨車（GoGoVan）",
      self_vehicle: "自行安排運輸",
      order_detail_pop_up: "請在購物車中新增物資以提交訂單詳情。",
      transport_order_detail_pop_up: "請先填寫訂單詳情",
      transport_details_pop_up: "請在購物車中新增物資以提交運輸詳情。",
      numbers_warning: "請輸入一個4位數字。",
      transport_details: {
        title: "預約詳情",
        transport_method: "運輸方式: ",
        collect_with_own_vehicle: "我會自行安排運輸接收",
        send_item_with_driver: "我會安排GoGoVan代為接收（服務使用者付費）"
      },
      vehicle: {
        collection_date_time: "接收日期及時間",
        delivery_date_time: "運送日期及時間"
      },
      gogovan: {
        requirements: {
          speak_english: "英語溝通",
          borrow: "需借用手推車",
          carry: "需搬運"
        }
      },
      cart_details: {
        cart_summary: "顯示訂單詳情"
      },
      purpose_section: {
        title: "申請目的*"
      },
      client_information: {
        title: "服務對象資料",
        id_type_held_by_client: "服務對象所持身份證明種類",
        verify: "我們將在送抵時用此身份證明以核實服務對象身份。",
        data_confidentiality: "所有服務對象資料均嚴格保密。",
        hk_id: "香港身份證",
        asrf_id: "尋求庇護者確認表格",
        first_name: "服務對象的名",
        family_name: "服務對象的姓",
        name_instruction: "在身份證明文件中顯示",
        name_warning: "您必須在此輸入您的名稱。",
        phone_number: "服務對象的聯絡電話號碼",
        mobile_warning: "請輸入有效的香港流動電話號碼。",
        for_client: "此訂單是給予機構自用還是服務對象使用?",
        phone_number_instruction: "我們將會用此號碼確認行程和資料。",
        hk_id_instruction: {
          title: "請輸入服務對象香港身份證最後的四個數字。",
          sub_title: "您不需要包含括號內的數字。"
        },
        rbcl: {
          title: "請輸入確認表格的身份證明號碼(只限數字)。",
          sub_title: "四個字母後的數字，例如RBCL“”"
        }
      },
      request_purpose: {
        title: "申請目的",
        purpose: "貨物用途",
        for_organisation: "予機構自用",
        for_client: "予服務對象",
        people_count_info: "多少人會從這些物品中受惠？",
        people_count_warning: "您必須在這裡輸入一個數字",
        district_label: "這些物資將在香港哪區使用?",
        "district-label-info": "此項能協助我們作出準確的紀錄及數據",
        select_placeholder: "請選擇區域",
        need_description: "描述申請需求",
        need_description_info: "請簡述申請貨物的原因。",
        description_warning: "抱歉，描述的位置不能留空。"
      },
      goods_details: {
        title: "貨物詳情",
        info_1: "請指出所需每件貨物的類型和數量。",
        info_2: "我們會根據現有的庫存項目定期更新資料庫。 如我們未有列出你希望申領的物品或你有其他特別要求，請發送電子郵件至",
        info_3: "我們會盡力提供幫助。",
        request_more: "申請更多貨物",
        type_label: "貨物類型",
        quantity_label: "數量",
        description_details_label: "項目詳情（可選）",
        no_goods_error: "需要申請最少一件物品",
        no_time_slot_selected_error: "請選擇預約時間",
        validation_error: {
          invalid_details: "物資的種類及數量不能留空"
        }
      },
      request: {
        remove_req: "從此訂單中刪除申請",
        remove: "移除"
      },
      appointment: {
        transport: "運輸方式",
        self_vehicle: "服務對象將自攜帶車輛",
        hire_vehicle: "服務對象需要有關僱用車輛的信息",
        confirm_labor: "確認理解搬運需要",
        labor_info: "如果貨物太大而服務對象無法獨自搬運，請建議他們安排搬運卸載的人手。抱歉，國際十字路會不提供搬運，而僱用的貨車司機亦不提供免費的搬運服務。",
        labor_confirmation: "我確認理解搬運需要。",
        date: " 預約日期",
        time: "預約日"
      },
      online_order: {
        preferred_date: "偏好的日期",
        goods_limit: "我們不能保存貨品多於幾天.",
        order_confirmation: "處理這指令前我們將會確定送貨或收貨日期.",
        choose_date: "選擇有效的日期",
        good_limit_dates: "我們不能保存貨品過於以下日期.",
        staff_contact: "處理這指令前我們的職員將會確定收貨或送貨時間.",
        self_vehicle: "接收者會從國際十字路會接收",
        hire_vehicle: "接收者會僱用貨車運貨（接收者會付款給貨車司機）",
        labor_info: "國際十字路會義工會幫忙上貨於車輛內,但不會幫忙落貨.我們的職員將會接觸閣下去討論及確定有關僱用工人去處理落貨的費用事宜.",
        delivery_address_short: "Delivery Address",
        delivery_address_details: "Delivery Address Details",
        delivery_address_subtitle: "Provide address and any instructions for the driver",
        address_fields: {
          street: "Street",
          flat: "Flat",
          building: "Building",
          notes: "Instructions"
        }
      },
      booking_success: {
        success: "成功！",
        reference_number: "參考編號",
        status: "狀態",
        what_happen_after_booking: "現在發生了什麼事情？",
        after_booking_info_1: "我們的職員正在處理您的訂單。他們可能會與您聯絡已獲得更多信息。如果一切正常，您的訂單將被標記為已確認。",
        after_booking_info_2: "請到您的操控板查看或修改訂單/預約/偏好等",
        visit_dashboard: "進入您的操控板"
      },
      confirm_booking: {
        title: "確認",
        your_details: {
          title: "閣下的資料",
          phone: "電話號碼",
          name: "姓名",
          organisation: "機構名稱",
          position: "職位",
          email: "電郵",
          preferred_contact_number: "選取這聯絡號碼"
        },
        request_purpose_detail: {
          title: "要求的目的",
          purpose_of_goods: "需求貨品的目的",
          number_benefiting: "受惠數量",
          description_of_need: "需求描述"
        },
        client_info_detail: {
          title: "客戶資料",
          id_type: "閣下身份証明類型",
          id_number: "身份証明號碼",
          client_name: "客戶姓名",
          client_phone: "客戶電話號碼"
        },
        goods_detail: {
          title: "貨品資料",
          no_description: "未有提供資料"
        },
        schedule_summary: {
          title: "預約資料",
          transport: "運輸",
          labour: "運輸工人",
          labour_info: "客戶明白運輸工人的要求及提供運輸工人的需要.",
          time: "時間"
        },
        submit_order: "傳送指令",
        info: "請覆核以下資料及傳送。<br/>如閣下有需要改變資料，請按下‘回轉’"
      }
    },
    terms: {
      title: "使用條款及細則",
      info: '好人好市.hk為十字路會聯網的一部分。使用好人好市.hk網頁http://www.goodcity.hk ("好人好市網站")，即表示閣下同意閣下與十字路會及其旗下所屬部門 ("十字路會", "我們" and "我們的")之間的使用條款。',
      agreement: {
        title: "閣下的同意書",
        terms_of_use: "閣下同意跟從下列使用條款使用好人好市網站。",
        use_authority: "閣下同意僅以個人身份使用好人好市網站。假如閣下代表公司、合作夥伴、聯會、政府或其他機構使用好人好市網頁，閣下必須代表並保證已經得到貴機構之授權，並代表其遵從下列使用條款。",
        lawful_use: "閣下同意僅為合法目的使用好人好市網站。",
        security: "閣下同意不會採取任何可能損害好人好市網站保安之手段，導致他人無法讀取好人好市網站及其內容，並/或對之造成損害。",
        access_attempt: "閣下同意不會企圖讀取好人好市網站上，任何不對閣下開放的內容；",
        website_rights: "閣下同意不會以任何可能損害第三方權益的方式使用好人好市網站。"
      },
      changes: {
        title: "使用條款的更改",
        info: "我們或會間中發布、修正或增加有關閣下使用好人好市網站的特定指引、政策及條款，而這些指引、政策及條款為使用條款的一部份。請細閱好人好市網站上的使用條款，並間中重閱。使用條款經過修正後繼續使用好人好市網站，即表示閣下同意經改正後的條款。",
        contact_us: "如閣下對好人好市網站上的使用條款有任何疑問，請與我們聯絡"
      },
      account: {
        title: "帳戶資料",
        info: "閣下的帳戶名稱為我們的資產。不論任何原因或肇事者，一旦閣下的帳戶終止使用或遭暫停，我們能夠收回該帳戶名稱，並重新使用。閣下有責任保障登入帳戶及好人好市網站的密碼。假如閣下認為密碼或帳戶遭入侵，必須儘快電郵至privacy@crossroads.org.hk通知我們。"
      },
      privacy: {
        title: "私隱政策",
        info: "我們十分重視閣下的私隱。當閣下登記，經由好人好市接收物資時，我們或回查詢閣下的個人資料，我們已經準備收集個人資料聲名（PICS），詳列我們有關個人資料的私隱政策及執行細節。",
        pics: "閣下可以隨時翻閱PICS",
        privacy_link: "十字路會完整的私隱政策亦可供查詢",
        use: "這些政策為好人好市網站使用條款的一部分。",
        here: "這裏"
      },
      intellectual: {
        title: "知識產權",
        info: "好人好市網站包含的一切知識產權（包括將來一切的資料更新、系統更新及新版本）及任何相聯或相關的程式、系統及服務將一直屬於我們及我們的發牌人。除了在得到我們明確授權外，閣下並無權利使用我們的知識產權。特別指出，如事前未得到我們的書面同意，閣下並無權使用我們的商標或產品名稱，標誌，網域名稱或其他明顯的品牌細節。",
        grant: "僅在合乎使用條款的情況下，我們給予閣下使用好人好市網站的使用權利，該使用權利供閣下個人，但不限於閣下一人使用，授權不得二次授權，不得轉讓，並不需專利費用，我們保留撤回的權利。",
        unauthorised_access: "除了在現行法例導致我們或不會禁止閣下，或閣下事前得到書面同意的情況下，閣下不能從好人好市網站或任何相聯或相關程式、系統或服務中複製、更改、進行逆向工程或提取任何資料，程式源代碼或基礎HTML。在使用好人好市網站時，閣下不能同時使用任何可能導致或嘗試引致上列或其他令好人好市網站以未經授權的方式運行的程式或其他技術。"
      },

      content: {
        title: "閣下的內容",
        info: "當閣下使用好人好市網站時提交、上載、傳輸或展示任何照片、數據、資料、媒介或其他內容（閣下的內容），即表示閣下明白並同意：",
        responsible: "閣下將繼續擁有閣下的內容，並對其負責。",
        no_sell: "我們不會將閣下的內容銷售給第三方。",
        privacy_policy: "我們將跟隨我們的私隱政策使用閣下的內容。",
        right_to_use: "閣下給予我們及與我們相關的公司使用閣下的內容的權利（我們不須向閣下支付任何費用），用以提供我們的服務，並進行宣傳、發展和改善我們的服務的目的，包括我們於將來新增的服務。",
        share: "我們或會向與我們合作的第三方分享閣下的內容，以提供、宣傳、發展及改善我們的服務，但該第三方（非與我們相關的公司）將不會以私人目的單獨使用閣下的內容（意指任何與我們的服務無關的目的），並且",
        suggestions: "閣下提供任何有關好人好事網站的意見或建議均屬自願性質，我們將能夠免費使用，而不須向閣下進行任何義務。"
      },
      warranty: {
        title: "擔保及免責聲明",
        info: "我們保證為好人好市網站及其相關服務提供合理保養及技術。然而，除此擔保外，對於我們的服務、軟件或任何已提交、傳輸或展示的內容，我們並不表示或擔保以及承諾會完全安全或不出錯、或好人好市會和閣下使用的裝置或任何安裝在裝置上的軟件兼容，這包括我們服務中，任何在好人好市網站或其他任何軟件、內容或資料中的顯示、擔保或承諾。好人好市網站僅提供現有、可用的版本。"
      },
      liability: {
        title: "責任",
        info: "無論因為任何情況，好人好市網站及與其相關的公司的整體責任僅限至總計100美元。",
        exclude: "使用條款中沒有任何情況可免除任何由疏忽導致的死亡或人身傷害所造成的一切損失，欺詐誤導或任何其他責任均不能受限於或免除法律責任。",
        indemnify_us: "假如閣下違反任何使用條款，即閣下同意對我們作出賠償，並不能追究我們因此而造成的任何責任或損失。"
      },
      policy_to_children: {
        title: "對兒童政策",
        info: "好人好市網站的使用對象並不包括兒童。然而，我們並無法完全斷定使用者的年齡。假如閣下的年齡為18歲或以下，請勿使用好人好市網站。"
      },
      general: {
        title: "一般",
        info: "這些好人好市網站使用條款完全是閣下和我們之間有關好人好市網站的合約。閣下同意不會就任何並未在這些使用條款中明確列明的條例向我們追究。如使用條款的實施有所延誤，不能將之視為放棄規定下的任何權利。根據其原意，在使用條款遭終止或過期前，使用條款中的任何權利及義務均有效並應得以保存，包括但不限於任何對相關方面責任的義務。",
        hk_law: "使用條款及與其相關或衍生的任何紛爭或索償均受香港特別行政區法律的監管。使用條款衍生出的或相關的任何法律程序皆由，並不限於香港特別行政區法院處理。",
        language: "如使用條款的英文版本與其他語言版本有出入，皆以英文版本為凖。"
      },
      contact: {
        title: "聯絡我們",
        info: "假如閣下對好人好市網站或這些使用條款有任何問題或疑問，請聯絡我們"
      }
    },
    privacy: {
      title: "個人資料",
      confidentiality: "十字路會全力保護在活動中，包括從好人好市的網站www.goodcity.hk（「我們」、「我們的」、「十字路會」」及「好人好市.HK」）上收集的個人資料，確保不會外洩。",
      ask_to_access: "本個人資料收集聲明（PICS）列出我們對處理個人資料的政策及使用情況，包括在您註冊以通過「好人好市.HK」接收物資時，我們要求您提供的個人資料。您可以閱讀我們完整的私隱政策。",
      here: "這裏",
      change_privacy_policy: "我們保證依照香港個人資料(私隱)條例列明的要求，我們可能因時更改此PICS或我們的私隱政策，並會於此處更新及張貼詳情。繼續使用「好人好市.HK」即表示您同意這些變更。",
      child: "假如您為18歲以下青少年，請徵求父母或監護人的同意，方可使用「好人好市.HK」。",
      commitement_to_you: "我們的承諾",
      use_purposely: "我們只會將您提供的個人資料用作收集或其他直接相關的用途；",
      will_not_sell_dara: "我們不會在您不知情（或根據要求，不同意）的情況下，販賣或將您的個人資料轉讓給任何其他機構；",
      unsubscribe: "您隨時都可以取消訂閱我們的通訊；",
      questions: "我們非常重視我們的法律責任，假如您有任何疑問或憂慮，請隨時聯絡我們。",
      personal_data_collected: "個人資料收集",
      provide_data_to_use_services: "當你於「好人好市.HK」註冊時，我們或會要求您提供以下各項個人資料。請注意，部分資料或為必須提供的，我們會於收集時清楚註明。缺少這些資料，我們可能無法向您提供我們的物資或服務：",
      contact_details: "您的姓名、地址、電郵及傳真號碼；",
      gender_and_dob: "您的性別及出生日期；",
      financial_information: "財政資料，例如信用卡資料（假如您向我們付款）及銀行賬戶資料（假如我們向您轉賬金額）；",
      personal_identifier: "身份證明文件資料，如護照及其他官方文件號碼。除非有特殊情況，否則我們不會收集或保存您的香港身份證號碼；",
      responses_to_feedback: "您於問卷上的回答或建議。",
      purpose_and_use: "目的及使用情況",
      will_use_data_for_following: "我們或會使用您提供的個人資料作以下用途，或其他直接相關的用途：",
      new_or_any_invitation: "在您要求或清楚表明的同意下，用作報道我們的活動、計劃、產品、研究及未來項目的新聞，更新或邀請；",
      respond_to_your_questions: "回應您的查詢或提議；",
      to_process_feedback: "處理建議或跟進您的查詢；",
      requested_by_govt_or_legal_claim: "根據法律及條例授權或受政府要求提供，或用以確保或行使我們的合法權利或追究法律責任；",
      deliver_items_to_you: "向您運送物資或處理您的要求；",
      improve_services: "改善我們的服務、活動、產品及內容，以及用作數據及其他分析，以改善我們的效率；",
      prevent_crime: "預防或檢察出罪案或可能的犯罪活動。",
      third_parties: "第三方",
      third_party_organisations: "我們或會保留讓其他組織及個別人士代表我們履行職責，例如協助我們物流安排的合作夥伴GoGoVan。這些第三方或會得到准許取得您的個人資料，以履行其職責，但我們並不會授權他們將這些資料用作其他用途。這些第三方或是位於香港的機構。我們的政策在於選取合乎我們需要，並擁有良好的技術及組織能力，信譽可靠的服務提供者。他們必須擁有保障私隱的良好記錄。",
      social_welfare_dept: "假如你得到其他機構轉介（例如社會福利署或其他機構），我們或須於他們討論，並根據情況於過程中披露或轉移您的個人資料。",
      crossroads_network: "十字路會網絡",
      use_data_in_crossroads_network: "十字路會於全球運作。我們於香港及英國皆設有辦公室，我們將之稱為「十字路會網絡」。我們或會根據前述PICS的目的，於十字路會網絡內披露、分享及轉移您的個人資料。我們要求十字路會網絡的人員皆遵守於此PICS及我們的私隱政策中列明的數據保安及保密的程序。",
      correction_of_data: "存取及更正個人資料",
      right_to_review_and_correct: "您有權利閱覽及更改您所提供的個人資料。假如閱覽或更新的過程中產生任何費用，我們或會向您收取合理的費用。假如您希望閱覽或更新個人資料，或有任何關於此PICS或我們的私隱政策的疑問，歡迎隨時聯絡我們，電郵為：privacy@crossroads.org.hk",
      last_updated: "本聲明最後更新日期為2014年8月。"
    },

    faq: {
      title: "常見問題",
      description: "「妥當配物資，快捷助我城」為好人好市之目標。",
      questions: {
        question1: "好人好市接收甚麼類型的物資？",
        question2: "審查員根據甚麼標準接收物資？",
        question3: "有哪幾種貨運方法？",
        question4: "我可以邀請別人使用好人好市應用程式嗎？",
        question5: "甚麼人受惠於這些物資呢？",
        question6: "身在在海外也可以捐出物資嗎？",
        question7: "為何選址香港？"
      },

      answers: {
        answer1: {
          good_condition: "我們重視物資的狀況是否良好，以示對慈善團體及受惠人士的尊重。",
          useful: "使用好人好市，只需幾分鐘的審查，您就能知道您捐出的物資是否能夠幫助別人。常見的物資有傢俱、家庭用品、電腦、電器等。"
        },

        answer2: {
          review: "我們的審查員會考慮當時的供求情況，並考慮不同物資可能帶來的限制，例如是否符合品質要求、大小限制、安全標準，運輸會否有困難，是否符合當地電壓、兼容、設計的要求等。",
          messaging: "審查員會透過好人好市應用程式裏的通訊功能，查詢有關捐贈物資的資料。這樣，您就不用填寫複雜冗長的表格。大部分的情況中，只要有相片及簡短的描述就足夠了。",
          supervisors: "審查員亦能夠即時知會專業的管理員，他們大多擁有五年或以上在香港處理捐贈物資的經驗。他們處理過很多罕見的物資捐獻，例如是商業用披薩烤箱、十萬件全新西裝、大量假花裝飾等。面對這些古怪又實用的物資，他們非常清楚該如何處理。"
        },
        answer3: {
          gogovan: "<b>租用貨車，快捷妥當：</b> 通過我們的應用程式，您能夠預約貨車，這是運送物資的最快方法。您直接付款給司機。",
          drop_off: "<b>親自運送：</b> 通過我們的應用程式，您能夠預約時間，將物資親自送到我們位於屯門的辦公室。"
        },
        answer4: "社工及慈善機構員工能使用好人好市的特別版網站和手機程式，目前只有受邀機構和人士可以使用特別版程式，但我們希望最終能夠開放給香港所有的註冊社工及慈善機構使用。",

        answer5: {
          title: "我們幫助不同社群的需要：",
          community: "社區/文化團體",
          drug: "濫藥更新人士",
          education: "教育機構",
          elderly: "長者",
          environmental: "環保團體",
          families: "有需要的家庭",
          medical_n_hospitals: "醫護診所及醫院",
          orphanages: "孤兒院",
          special_needs: "特殊需要人士",
          risk: "高危人士",
          rehabilitation: "復康服務",
          service_orgs: "服務機構",
          social_enterprise: "社會企業",
          sports: "殘疾人士體育計劃",
          suicide_prevention: "防止自殺服務",
          domestic_workers: "家傭",
          new_arrivals: "新移民",
          women: "婦女",
          vocational_training: "職業培訓計劃",
          animals_welfare: "動物福利"
        },
        answer6: "不可以。但如果您以公司身份從海外捐出大量物資，您就能夠使用我們的另一服務：",
        global_hand: "環球援手",
        answer7: {
          hk: "香港的地理位置非常獨特，非常適合處理慈善物資捐獻，是全世界最有效率的地方，因為：人口密度高，相對富裕，智能電話極普及，慈善工作井然有序，公民意識亦逐漸成型。",
          crossroads: "十字路會自1995年開始於香港處理各界捐贈的物資，至今發展出各種網上服務（如business.un.org）。這讓我們成為一個獨特的機構，擔當着十字路口的角色，聯繫捐贈物資的供求。"
        }
      }
    }
  };
});