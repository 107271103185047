define('browse/routes/order', ['exports', 'browse/routes/authorize'], function (exports, _authorize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    model: function model(params) {
      return this.store.peekRecord('package', params["order_id"]);
    }
  });
});