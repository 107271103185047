define("browse/mixins/safe_get", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var getWithDefault = Ember.getWithDefault;
  exports.default = Mixin.create({
    safeGet: function safeGet(model, id, attr) {
      var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "N/A";

      if (!attr || !id || !model) {
        return defaultValue;
      }

      var record = this.get("store").peekRecord(model, id);
      return getWithDefault(record, attr, defaultValue);
    }
  });
});