define("browse/routes/my_bookings", ["exports", "browse/routes/my_orders"], function (exports, _my_orders) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = _my_orders.default.extend({
    orderService: service(),

    model: function model() {
      var _this = this;

      return hash({
        organisation: this.store.peekAll("organisation").objectAt(0),
        user: this.store.peekAll("user").objectAt(0),
        orders: this.get("orderService").fetchOrdersOfType({
          shallow: true,
          appointment: true
        })
      }).then(function (res) {
        return _this.get("orderService").loadOrderTransports().then(function () {
          return res;
        });
      });
    }
  });
});