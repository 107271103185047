define("browse/routes/my_notifications", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var applicationController = this.controllerFor("application");
      applicationController.set("hideHeaderBar", false);
      applicationController.set("pageTitle", "Notification");
      controller.on();
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        var applicationController = this.controllerFor("application");
        applicationController.set("hideHeaderBar", true);
        controller.set("notifications", []);
      }

      controller.off();
    }
  });
});