define("browse/controllers/request_purpose", ["exports", "browse/utils/ajax-promise", "browse/config/environment", "browse/mixins/cancel_order"], function (exports, _ajaxPromise, _environment, _cancel_order) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var equal = Ember.computed.equal;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var getOwner = Ember.getOwner;
  exports.default = Controller.extend(_cancel_order.default, {
    showCancelBookingPopUp: false,
    isMobileApp: _environment.default.cordova.enabled,
    myOrders: controller(),
    queryParams: ["orderId", "editRequest", "bookAppointment", "prevPath"],
    prevPath: null,
    editRequest: null,
    orderId: null,
    isEditing: false,
    bookAppointment: false,
    peopleCount: null,
    description: "",
    order: alias("model"),
    selectedId: null,
    isSelfSelected: equal("selectedId", "organisation"),
    user: alias("session.currentUser"),
    cart: service(),
    messageBox: service(),

    selectedDistrict: null,

    districts: computed(function () {
      return this.store.peekAll("district").sortBy("name");
    }),

    getBookingTypeIdFor: function getBookingTypeIdFor(identifier) {
      return this.store.peekAll("booking_type").filterBy("identifier", identifier).get("firstObject.id");
    },
    getSelectedBookingTypeId: function getSelectedBookingTypeId() {
      var order = this.get("model");
      var bookingTypeId = order && order.get("bookingTypeId");
      if (!bookingTypeId) {
        bookingTypeId = this.getBookingTypeIdFor(this.get("bookAppointment") ? "appointment" : "online-order");
      }
      return bookingTypeId;
    },
    isOnlineOrder: function isOnlineOrder() {
      return this.getSelectedBookingTypeId() == this.getBookingTypeIdFor("online-order");
    },


    actions: {
      clearDescription: function clearDescription() {
        this.set("description", "");
      },
      createOrderWithRequestPurpose: function createOrderWithRequestPurpose() {
        var _this = this;

        if (this.isOnlineOrder()) {
          var cartHasItems = this.get("cart.cartItems").length;
          if (!cartHasItems) {
            this.get("messageBox").alert(this.get("i18n").t("order.order_detail_pop_up"), function () {
              _this.transitionToRoute("index");
            });
            return false;
          }
        }

        var user = this.get("user");
        var user_organisation_id = void 0;
        if (user && user.get("organisationsUsers").length) {
          user_organisation_id = user.get("organisationsUsers.firstObject.organisationId");
        }

        var order = this.get("model");
        var url = "/orders";
        var actionType = "POST";
        if (order) {
          url = "/orders/" + order.get("id");
          actionType = "PUT";
        }

        var orderParams = {
          organisation_id: user_organisation_id,
          purpose_description: this.get("description"),
          purpose_ids: [],
          people_helped: Number(this.get("peopleCount")),
          district_id: this.get("selectedDistrict.id"),
          booking_type_id: this.getSelectedBookingTypeId(),
          state: order ? order.get("state") : "draft"
        };

        var loadingView = getOwner(this).lookup("component:loading").append();

        new _ajaxPromise.default(url, actionType, this.get("session.authToken"), {
          order: orderParams
        }).then(function (data) {
          _this.get("store").pushPayload(data);

          var orderId = data.order.id;
          loadingView.destroy();
          if (_this.get("prevPath") === "orders.booking" && _this.get("editRequest")) {
            _this.transitionToRoute("orders.booking", orderId);
            _this.set("prevPath", "null");
            _this.set("editRequest", false);
          } else {
            _this.transitionToRoute("order.client_information", orderId);
          }
        }).catch(function (err) {
          _this.get("messageBox").alert(err.responseJSON.error, function () {
            return _this.transitionToRoute("/my_orders");
          });
        });
      },
      back: function back() {
        var prevPageName = this.get("prevPath");
        var orderId = this.get("order.id") || this.get("orderId");
        if (prevPageName) {
          if (["orders.goods", "orders.booking"].indexOf(prevPageName) >= 0) {
            this.transitionToRoute(prevPageName, orderId);
          } else {
            this.transitionToRoute(prevPageName);
          }
        } else {
          this.transitionToRoute("home");
        }
      }
    }
  });
});