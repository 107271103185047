define("browse/components/small-cart-toggle-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    cart: service(),

    // Set to true to enable navigation to the details
    // Usage: {{small-cart-toggle-view allowClick=true}}
    allowClick: false,
    showCartSummary: false,

    actions: {
      toggleCartSummary: function toggleCartSummary() {
        this.toggleProperty("showCartSummary");
      },
      showItemDetails: function showItemDetails(item) {
        if (!this.get("allowClick")) {
          return;
        }
        this.get("cart").navigateToItemDetails(item);
      }
    }
  });
});