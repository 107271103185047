define("browse/models/requested_package", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    userId: (0, _attr.default)("string"),
    user: (0, _relationships.belongsTo)("user", { async: false }),
    packageId: (0, _attr.default)("string"),
    package: (0, _relationships.belongsTo)("package", { async: false }),
    isAvailable: (0, _attr.default)("boolean"),
    quantity: (0, _attr.default)("number")
  });
});