define("browse/controllers/package_category", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var observer = Ember.observer;
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  var bool = Ember.computed.bool;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ["page"],
    page: 1,
    perPage: 12,
    selectedCategoryId: null,
    sortedItems: sort("categoryObj.packagesAndSets", "selectedSort"),
    currentCategoryId: alias("categoryObj.id"),
    currentCategoryName: alias("categoryObj.name"),
    currentCategory: alias("categoryObj"),

    selectedSort: computed({
      get: function get() {
        return this.get("sortOptions.firstObject.id");
      },
      set: function set(key, value) {
        return value.id || value;
      }
    }),

    navigateToPageTop: observer("page", function () {
      window.scrollTo(0, 0);
    }),

    sortOptions: computed(function () {
      return [{
        name: this.get("i18n").t("category.sort.newfirst"),
        id: ["createdAt:desc"]
      }, { name: this.get("i18n").t("category.sort.oldfirst"), id: ["createdAt"] }];
    }),

    isCategorySelected: bool("selectedCategoryId.id"),

    categoryObj: computed("selectedCategoryId", "model", function () {
      this.set("page", 1);
      var selectedCategoryId = this.get("selectedCategoryId.id");
      return selectedCategoryId ? this.store.peekRecord("package_category", selectedCategoryId) : this.get("model");
    }),

    selectCategories: computed("model", function () {
      return this.get("model.childCategories").map(function (c) {
        return {
          name: c.get("nameWithCount"),
          id: c.get("id")
        };
      });
    })
  });
});