define("browse/components/package-type-overlay", ["exports", "ember-i18n", "lodash"], function (exports, _emberI18n, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var later = Ember.run.later;
  var $ = Ember.$;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  exports.default = Component.extend({
    queryParams: ["changeCode", "reqId"],
    reqId: null,
    changeCode: false,
    order: alias("model"),
    filter: "",
    searchText: "",
    fetchMoreResult: true,
    searchPlaceholder: (0, _emberI18n.translationMacro)("search.placeholder"),
    i18n: service(),
    store: service(),
    packageTypeService: service(),

    allPackageTypes: computed("fetchMoreResult", function () {
      return this.get("store").peekAll("package_type");
    }),

    hasSearchText: computed("searchText", function () {
      return this.get("searchText").trim().length;
    }),

    hasFilter: computed("filter", function () {
      return this.get("filter").trim().length;
    }),

    onSearchTextChange: observer("searchText", function () {
      // wait before applying the filter
      debounce(this, this.applyFilter, 500);
    }),

    applyFilter: function applyFilter() {
      this.set("filter", this.get("searchText"));
      this.set("fetchMoreResult", true);
    },

    filteredResults: computed("filter", "fetchMoreResult", "allPackageTypes.[]", function () {
      var filter = this.get("filter").toLowerCase().trim();
      var types = [];
      var matchFilter = function matchFilter(value) {
        return (value || "").toLowerCase().indexOf(filter) !== -1;
      };

      if (filter.length > 0) {
        this.get("allPackageTypes").forEach(function (type) {
          if (matchFilter(type.get("name")) || matchFilter(type.get("otherTerms"))) {
            types.push(type);
          }
        });
        later(this, this.highlight);
      } else {
        types = types.concat(this.get("allPackageTypes").toArray());
        this.clearHiglight();
      }
      return types.filterBy("allowRequests").rejectBy("name", null).sortBy("name").uniq();
    }),

    highlight: function highlight() {
      var string = this.get("filter").toLowerCase().trim();
      this.clearHiglight();
      $(".codes_results li div").each(function () {
        var text = $(this).text();
        if (text.toLowerCase().indexOf(string.toLowerCase()) > -1) {
          var matchStart = text.toLowerCase().indexOf("" + string.toLowerCase() + "");
          var matchEnd = matchStart + string.length - 1;
          var beforeMatch = text.slice(0, matchStart);
          var matchText = text.slice(matchStart, matchEnd + 1);
          var afterMatch = text.slice(matchEnd + 1);
          $(this).html(beforeMatch + "<em>" + matchText + "</em>" + afterMatch);
        }
      });
    },
    clearHiglight: function clearHiglight() {
      $("em").replaceWith(function () {
        return this.innerHTML;
      });
    },


    actions: {
      clearSearch: function clearSearch() {
        this.set("filter", "");
        this.set("searchText", "");
        this.set("fetchMoreResult", true);
      },
      cancelSearch: function cancelSearch() {
        this.get("packageTypeService").setOverlayVisibility(false);
      },
      assignItemLabel: function assignItemLabel(type) {
        if (!type) {
          return;
        }
        var onSelect = this.getWithDefault("onSelect", _lodash.default.noop);
        onSelect(type);
      }
    }
  });
});