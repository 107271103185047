define("browse/models/offer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  exports.default = _model.default.extend({
    state: (0, _attr.default)("string"),
    sharingExpiresAt: (0, _attr.default)("date"),
    publicUid: (0, _attr.default)("string"),

    packages: (0, _relationships.hasMany)("package", { async: false }),
    offerResponse: (0, _relationships.belongsTo)("offer_response", { async: false }),

    openForResponses: Ember.computed("sharingExpiresAt", function () {
      var expiresAt = this.get("sharingExpiresAt");
      if (expiresAt) {
        return expiresAt > moment();
      } else {
        return Boolean(this.get("publicUid"));
      }
    }),

    // unread order messages
    unreadMessagesCount: computed("offerResponse.messages.@each.state", function () {
      return this.get("offerResponse.messages").filterBy("state", "unread").length;
    }),

    hasUnreadMessages: computed("unreadMessagesCount", function () {
      return this.get("unreadMessagesCount") > 0;
    })
  });
});