define("browse/services/cartscroll", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Service = Ember.Service;
  exports.default = Service.extend({
    resize: function resize() {
      $(".item-collection").height($(".cart-items").height() - $(".cart-controls").height());
    }
  });
});