define("browse/mixins/cancel_order", ["exports", "browse/mixins/async_tasks"], function (exports, _async_tasks) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  var getOwner = Ember.getOwner;
  exports.default = Mixin.create(_async_tasks.default, {
    orderService: service(),
    messageBox: service(),

    deleteOrder: function deleteOrder(order) {
      var _this = this;

      this.runTask(this.get("orderService").deleteOrder(order).then(function () {
        _this.set("showCancelBookingPopUp", false);
        _this.transitionToRoute("home");
      }).catch(function (e) {
        _this.get("messageBox").alert();
      }));
    },
    cancelOrder: function cancelOrder(order) {
      var _this2 = this;

      var div = $(".cancel-appointment-reasons" + order.id);
      var cancellationReason = div.val().trim();

      this.set("cancellationReasonWarning", !cancellationReason);

      if (!cancellationReason) {
        div.addClass("cancel-booking-error");
        return false;
      }

      div.removeClass("cancel-booking-error");

      this.runTask(this.get("orderService").cancelOrder(order, cancellationReason).then(function () {
        _this2.set("showCancelBookingPopUp", false);
        _this2.transitionToRoute("home");
      }).catch(function (e) {
        _this2.get("messageBox").alert();
      }));
    },


    actions: {
      cancelBookingPopUp: function cancelBookingPopUp() {
        this.set("showCancelBookingPopUp", true);
      },
      removePopUp: function removePopUp() {
        this.set("showCancelBookingPopUp", false);
      },
      cancelOrder: function cancelOrder(order) {
        var _this3 = this;

        order = order || this.get("order");
        if (order) {
          if (order.get("state") === "cancelled") {
            this.get("messageBox").alert(this.get("i18n").t("order.already_cancelled"), function () {
              return _this3.transitionToRoute("/my_orders");
            });
          }
          if (order.get("isDraft")) {
            this.deleteOrder(order);
          } else if (order.get("isCancelAllowed")) {
            this.cancelOrder(order);
          }
        }
      }
    }
  });
});