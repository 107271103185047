define("browse/routes/home", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _browse_pages.default.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var applicationController = this.controllerFor("application");
      controller.set("cartLength", applicationController.get("cartLength"));
      controller.set("hasCartItems", applicationController.get("hasCartItems"));
      applicationController.set("pageTitle", this.get("i18n").t("browse.title"));
      applicationController.set("showSidebar", true);
      applicationController.set("hideHeaderBar", false);
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
    }
  });
});