define("browse/adapters/order", ["exports", "browse/adapters/application", "lodash"], function (exports, _application, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      if (_lodash.default.has(snapshot, "adapterOptions.includePackages")) {
        var url = this.buildURL(type.modelName, id, snapshot, "findRecord");
        var query = { include_packages: snapshot.adapterOptions.includePackages };
        return this.ajax(url, "GET", { data: query });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});