define("browse/controllers/orders/detail", ["exports", "browse/controllers/application", "browse/utils/ajax-promise", "browse/mixins/cancel_order"], function (exports, _application, _ajaxPromise, _cancel_order) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _application.default.extend(_cancel_order.default, {
    order: alias("model.order"),
    showCancelBookingPopUp: false,
    unreadMessageCount: computed("order", function () {
      return this.get("order.unreadMessagesCount");
    }),
    hasUnreadMessages: computed("order", function () {
      return this.get("order.hasUnreadMessages");
    }),

    actions: {
      redirectToEdit: function redirectToEdit(routeName) {
        var orderId = this.get("order.id");
        this.transitionToRoute("order." + routeName, orderId, {
          queryParams: { prevPath: "orders.booking" }
        });
      },
      editRequestPurpose: function editRequestPurpose() {
        var orderId = this.get("order.id");
        this.transitionToRoute("request_purpose", {
          queryParams: {
            orderId: orderId,
            bookAppointment: false,
            editRequest: true,
            prevPath: "orders.booking"
          }
        });
      },
      cancelBookingPopUp: function cancelBookingPopUp() {
        this.set("showCancelBookingPopUp", true);
      },
      removePopUp: function removePopUp() {
        this.set("showCancelBookingPopUp", false);
      }
    }
  });
});