define("browse/services/settings", ["exports", "lodash", "browse/computed/local-storage"], function (exports, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var NOT_EMPTY = function NOT_EMPTY(val) {
    return val && val.length > 0;
  };
  var TO_STRING = function TO_STRING(val) {
    return String(val);
  };
  var TO_NUMBER = function TO_NUMBER(val) {
    return Number(val);
  };
  var IS_NUMBER = function IS_NUMBER(val) {
    return !isNaN(TO_NUMBER(val));
  };
  var TO_BOOL = function TO_BOOL(val) {
    if (_lodash.default.isString(val)) {
      return (/^true$/i.test(val)
      );
    }
    return Boolean(val);
  };

  exports.default = Service.extend({
    store: service(),

    /**
     *
     * Settings are loaded from the API in order to configure the
     * behaviour of the app remotely.
     * Any key defined on the backend will override the local values
     *
     * A local value *must* be defined, the service will throw an error
     * otherwise. This behaviour is meant to ensure we have fallback values
     * if the remote config is missing
     *
     *
     * Add any new configuration keys below
     *         ;;;;;
     *         ;;;;;
     *       ..;;;;;..
     *        ':::::'
     *          ':`
     */
    defaults: {
      "browse.online_order.timeslots.booking_margin": 2,
      "browse.appointment_warning_en": "",
      "browse.appointment_warning_zh_tw": "",
      "browse.online_order_warning_en": "",
      "browse.online_order_warning_zh_tw": "",
      "browse.allow_appointments": true,
      "browse.free_delivery_enabled": false,
      "browse.free_delivery_package_id": 0
    },

    // ---- Access methods

    readBoolean: function readBoolean(key) {
      return this.__readValue(key, {
        parser: TO_BOOL
      });
    },
    readString: function readString(key) {
      return this.__readValue(key, {
        parser: TO_STRING,
        validator: NOT_EMPTY
      });
    },
    readNumber: function readNumber(key) {
      return this.__readValue(key, {
        parser: TO_NUMBER,
        validator: IS_NUMBER
      });
    },


    // ---- Helpers

    __validate: function __validate(val, validator) {
      var validators = _lodash.default.compact(_lodash.default.flatten([validator]));
      return _lodash.default.every(validators, function (fn) {
        return fn(val);
      });
    },
    __assertExists: function __assertExists(key) {
      var defaults = this.get("defaults");
      if (_lodash.default.has(defaults, key)) {
        return;
      }

      throw new Error("\n      Settings '" + key + "' has not been defined locally.\n      Please define a local default value before using it\n    ");
    },
    __readValue: function __readValue(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var defaults = this.get("defaults");
      var validator = options.validator,
          _options$parser = options.parser,
          parser = _options$parser === undefined ? _lodash.default.identity : _options$parser;


      this.__assertExists(key);

      var record = this.get("store").peekAll("goodcity_setting").findBy("key", key);

      var val = record && record.get("value");
      if (val && this.__validate(val, validator)) {
        return parser(val);
      }
      return parser(defaults[key]);
    }
  });
});