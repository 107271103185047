define("browse/models/role", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    rolePermissions: (0, _relationships.hasMany)("rolePermission", { async: false }),

    permissions: computed("rolePermissions.[]", function () {
      return this.get("rolePermissions").getEach("permission");
    }),

    permissionNames: computed("permissions.[]", function () {
      return this.get("permissions").getEach("name");
    })
  });
});