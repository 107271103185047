define("browse/components/offers-block", ["exports", "browse/mixins/cloudinary_image", "browse/mixins/safe_get", "lodash"], function (exports, _cloudinary_image, _safe_get, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var getWithDefault = Ember.getWithDefault;
  var computed = Ember.computed;
  var Component = Ember.Component;


  var DEFAULT_LOCATION_NAME = "Hong Kong";

  exports.default = Component.extend(_cloudinary_image.default, _safe_get.default, {
    store: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get("items").forEach(function (item) {
        var chineseDescription = (getWithDefault(item, "attributes.notes_zh_tw", null) || "").trim();

        if (_this.get("i18n.locale") === "zh-tw" && !!chineseDescription) {
          _lodash.default.set(item, "description", chineseDescription);
        } else {
          _lodash.default.set(item, "description", getWithDefault(item, "attributes.notes", ""));
        }
      });
    },


    locationName: computed("district", function () {
      var id = this.get("district");
      return this.safeGet("district", id, "name", DEFAULT_LOCATION_NAME);
    }),

    validDate: computed("record", function () {
      var record = this.get("record");
      return record.submitted_at || record.created_at;
    }),

    items: computed.alias("record.items"),

    initialItems: computed("record", "record.id", "items.[]", function () {
      return getWithDefault(this, "items", []).slice(0, 4);
    })
  });
});