define("browse/components/bookings-icon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  exports.default = Component.extend({
    messages: service(),

    unreadBookingsMessagesCount: alias("messages.unreadBookingsMessagesCount"),

    hasMessages: gt("unreadBookingsMessagesCount", 0)
  });
});