define("browse/controllers/orders/goods", ["exports", "browse/controllers/orders/detail", "lodash"], function (exports, _detail, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = _detail.default.extend({
    order: alias("model.order"),

    getCategoryForCode: function getCategoryForCode(code) {
      var categories = this.get("model.packageCategories");
      var category = categories.find(function (c) {
        return _lodash.default.includes(c.get("packageTypeCodes"), code);
      });
      return category && category.get("name");
    },

    showUpdateMessage: computed("model.order.ordersPackages.[]", function () {
      var isRecentlyUpdated = this.get("model.order.ordersPackages").toArray().some(this.recentUpdatedPackageCheck);
      return isRecentlyUpdated;
    }),

    recentUpdatedPackageCheck: function recentUpdatedPackageCheck(pkg) {
      return moment().diff(pkg.get("createdAt"), "minutes") <= 5;
    },


    hasRequestedGoods: notEmpty("requestedGoods"),

    requestedGoods: computed("order.goodcityRequests.@each.packageType", function () {
      var _this = this;

      var requests = this.getWithDefault("order.goodcityRequests", []);
      return requests.map(function (req) {
        return {
          category: _this.getCategoryForCode(req.get("packageType.code")),
          text: req.get("packageType.name")
        };
      });
    }),

    allPackageSetsOrdered: function allPackageSetsOrdered(packageSet, id) {
      var packages = packageSet.get("packages").toArray();
      return _lodash.default.every(packages, function (pkg) {
        return pkg.get("ordersPackage.orderId") == id;
      });
    },


    hasOrderedGoods: notEmpty("order.ordersPackages"),

    orderedGoods: computed("model.packageCategories", "model.order.ordersPackages.@each.state", "model.order.ordersPackages.@each.quantity", function () {
      var _this2 = this;

      var ordersPackages = this.getWithDefault("order.ordersPackages", []).filter(function (req) {
        return req.get("state") !== "cancelled" && req.get("quantity") > 0;
      });

      var res = [];
      var refs = {};

      ordersPackages.map(function (req) {
        if (!req.get("package.hasSiblingPackages")) {
          res.push(req);
          return;
        }
        var packageSet = req.get("package.packageSet");
        var packageSetId = packageSet.get("id");
        if (refs[packageSetId]) {
          return; // Already processed
        }
        if (_this2.allPackageSetsOrdered(packageSet, req.get("orderId"))) {
          refs[packageSetId] = packageSet;
          res.push(packageSet);
          return;
        }
        res.push(req);
      });
      return _lodash.default.map(res, function (record) {
        return { record: record };
      });
    })
  });
});