define("browse/controllers/my_bookings", ["exports", "browse/controllers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reject = Ember.RSVP.reject;
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  exports.default = _application.default.extend({
    orders: alias("model"),

    sortProperties: ["createdAt:desc"],
    arrangedOrders: sort("model.orders", "sortProperties"),

    preloadOrder: function preloadOrder(order) {
      var _this = this;

      this.startLoading();
      return this.get("store").findRecord("order", order.get("id"), { reload: true }).catch(function (e) {
        _this.stopLoading();
        return reject(e);
      }).then(function (res) {
        _this.stopLoading();
        return res;
      });
    },


    actions: {
      viewOrder: function viewOrder(order) {
        var _this2 = this;

        var id = order.get("id");
        this.preloadOrder(order).then(function () {
          _this2.transitionToRoute("orders.detail", id);
        });
      },
      editOrder: function editOrder(order) {
        var _this3 = this;

        var id = order.get("id");
        this.preloadOrder(order).then(function () {
          _this3.transitionToRoute("request_purpose", {
            queryParams: {
              bookAppointment: _this3.get("isAppointmentDraft"),
              orderId: id,
              prevPath: "my_bookings"
            }
          });
        });
      }
    }
  });
});