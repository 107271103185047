define("browse/components/back-link", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    fallbackRoute: "",

    actions: {
      back: function back() {
        var fallbackRoute = this.get("fallbackRoute") || "/";
        var isRoot = location.pathname === "/";

        if (isRoot) {
          return;
        }

        var noHistory = [1, 2].includes(window.history.length);
        var deepLinked = document.referrer && document.referrer.indexOf(_environment.default.APP.ORIGIN) < 0;

        if (deepLinked || noHistory) {
          return this.get("router").replaceWith(fallbackRoute);
        }

        window.history.back();
      }
    }
  });
});