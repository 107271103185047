define("browse/services/package-type-service", ["exports", "browse/services/api-base-service"], function (exports, _apiBaseService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _apiBaseService.default.extend({
    store: service(),

    isOpen: false,

    setOverlayVisibility: function setOverlayVisibility(val) {
      this.set("openPackageTypeSearch", val);
    },
    packageTypeLookup: function packageTypeLookup() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        Ember.run(function () {
          _this.setOverlayVisibility(true);
          _this.set("onPackageTypeSelect", function (packageType) {
            _this.setOverlayVisibility(false);
            resolve(packageType || null);
          });
        });
      });
    }
  });
});