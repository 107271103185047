define("browse/routes/order/confirm_booking", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    model: function model() {
      var orderId = this.paramsFor("order").order_id;
      return this.store.peekRecord("order", orderId) || this.store.findRecord("order", orderId);
    },
    afterModel: function afterModel() {
      window.scrollTo(0, 0); //https://github.com/dollarshaveclub/ember-router-scroll. Read this link for nested route issue for not scrolling at top of the page
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor("application").set("showSidebar", false);
    },
    deactivate: function deactivate() {
      this.controllerFor("application").set("showSidebar", true);
    }
  });
});