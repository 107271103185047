define("browse/components/image-preview", ["exports", "browse/components/observe-screen-resize"], function (exports, _observeScreenResize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = _observeScreenResize.default.extend({
    lightGalleryObj: null,

    onScreenResized: function onScreenResized() {
      this.initializeLightgallery();
    },
    initializeLightgallery: function initializeLightgallery() {
      var gallery = $("#lightGallery").data("lightGallery");
      if (gallery) {
        gallery.destroy();
      }

      var lightGalleryObj = $("#lightGallery").lightGallery({
        mode: "lg-slide",
        zoom: true,
        download: false,
        scale: 1,
        hideControlOnEnd: true,
        closable: true,
        loop: true,
        counter: true,
        enableTouch: true,
        enableDrag: true,
        selector: ".imageZoom"
      });
      this.set("lightGalleryObj", lightGalleryObj);
    },
    didInsertElement: function didInsertElement() {
      this.initializeLightgallery();
    },
    willDestroyElement: function willDestroyElement() {
      var gallery = $("#lightGallery").data("lightGallery");
      if (gallery) {
        gallery.destroy();
      }
    }
  });
});