define("browse/routes/delete_account", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.peekRecord("user", this.session.get("currentUser.id"))
      });
    },


    // load orders into store to ensure our 'can delete' calculations work
    beforeModel: function beforeModel() {
      var cachedRecords = this.store.peekAll("order");
      if (cachedRecords.get("length") === 0) {
        this.get("store").query("order", {});
      }
    }
  });
});