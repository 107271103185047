define("browse/routes/my_offer_responses", ["exports", "browse/routes/my_orders"], function (exports, _my_orders) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _my_orders.default.extend({
    model: function model() {
      return this.store.query("offer_response", {
        offer_response: { user_id: this.get("session.currentUser").id }
      });
    }
  });
});