define("browse/routes/browse", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _browse_pages.default.extend({
    model: function model() {
      return this.store.peekAll("package_category");
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.on();

      var applicationController = this.controllerFor("application");
      applicationController.set("hideHeaderBar", false);

      controller.toggleProperty("triggerFlashMessage");
      this.controllerFor("application").set("pageTitle", this.get("i18n").t("browse.browse_title"));
    },
    resetController: function resetController(controller) {
      controller.off();
    }
  });
});