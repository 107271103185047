define("browse/routes/request_purpose", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _authorize.default.extend({
    orderId: null,
    order: null,
    isBookAppointment: null,
    session: service(),
    orderService: service(),

    beforeModel: function beforeModel(transition) {
      if (!this._super.apply(this, arguments)) {
        return; // not authorized
      }

      var isAppointment = transition.queryParams.bookAppointment === "true",
          editRequest = transition.queryParams.editRequest === "true";
      this.set("editRequest", editRequest);
      this.set("isBookAppointment", isAppointment);
      this.set("orderId", transition.queryParams.orderId);
    },
    model: function model() {
      var _this = this;

      var orderId = this.get("orderId");
      var isAppointment = this.get("isBookAppointment");
      var loadTask = orderId ? this.loadIfAbsent("order", orderId) : this.get("orderService").getLastDraft({ appointment: isAppointment });

      return loadTask.then(function (order) {
        var districts = _this.store.query("district", {});
        _this.store.pushPayload(districts);
        _this.set("order", order);
        return order;
      });
    },
    setUpFormData: function setUpFormData(model, controller) {
      var order = this.get("order");

      if (!order) {
        controller.set("selectedDistrict", null);
        controller.set("peopleCount", null);
        controller.set("description", null);
        controller.set("selectedId", "organisation");
        return;
      }

      var ordersPurposes = order.get("ordersPurposes");

      if (ordersPurposes.get("length")) {
        controller.set("selectedId", ordersPurposes.get("firstObject").get("purpose.identifier"));
      }

      controller.set("selectedDistrict", order.get("district"));
      controller.set("peopleCount", order.get("peopleHelped"));
      controller.set("description", order.get("purposeDescription"));
      controller.set("isEditing", !order.get("isDraft"));
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set("isEditing", false);
      this.setUpFormData(model, controller, transition);
      this.controllerFor("application").set("showSidebar", false);
      controller.set("model", this.get("order"));
    },
    deactivate: function deactivate() {
      this.controllerFor("application").set("showSidebar", true);
    }
  });
});