define("browse/controllers/search_goods", ["exports", "browse/controllers/browse", "lodash"], function (exports, _browse, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var run = Ember.run;
  var $ = Ember.$;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = _browse.default.extend({
    minSearchTextLength: 2,
    displayResults: false,
    hasSearchText: computed("searchText", function () {
      return $.trim(this.get("searchText")).length;
    }),

    onSearchTextChange: observer("searchText", function () {
      if (this.get("searchText").length > this.get("minSearchTextLength")) {
        return this.reloadResults();
      }
      this.set("displayResults", false);
    }),

    reloadResults: function reloadResults() {
      this.hideResults();
      debounce(this, this.showResults, 500);
    },
    hideResults: function hideResults() {
      var _this = this;

      run(function () {
        _this.set("displayResults", false);
      });
    },
    showResults: function showResults() {
      var _this2 = this;

      run(function () {
        _this2.set("displayResults", true);
      });
    },
    getSearchQuery: function getSearchQuery() {
      return {
        searchText: this.get("searchText")
      };
    },
    getPaginationQuery: function getPaginationQuery(pageNo) {
      return {
        per_page: 25,
        page: pageNo
      };
    },
    trimQuery: function trimQuery(query) {
      // Remove any undefined values
      return _lodash.default.pickBy(query, _lodash.default.identity);
    },


    actions: {
      clearSearch: function clearSearch(isCancelled) {
        this.set("searchText", "");
        if (!isCancelled) {
          $("#searchText").focus();
        }
      },
      cancelSearch: function cancelSearch() {
        $("#searchText").blur();
        this.send("clearSearch", true);
      },
      selectItem: function selectItem(item) {
        if (item) {
          this.transitionToRoute("package", item.id, {
            queryParams: {
              categoryId: item.get("allPackageCategories.firstObject.id")
            }
          });
        }
      },
      back: function back() {
        this.transitionToRoute("browse");
      },
      loadMoreGoods: function loadMoreGoods(pageNo) {
        var params = this.trimQuery(_lodash.default.merge({}, this.getSearchQuery(), this.getPaginationQuery(pageNo)));
        if (this.get("searchText").length > this.get("minSearchTextLength")) {
          return this.store.query("package", params);
        }
        this.hideResults();
      }
    }
  });
});