define("browse/models/organisations_user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    userId: (0, _attr.default)("number"),
    organisationId: (0, _attr.default)("number"),
    position: (0, _attr.default)("string"),
    status: (0, _attr.default)("string"),
    preferredContactNumber: (0, _attr.default)("string"),
    user: (0, _relationships.belongsTo)("user", {
      async: false
    }),
    organisation: (0, _relationships.belongsTo)("organisation", {
      async: false
    }),

    hasPosition: computed("position", function () {
      var position = this.get("position");
      return position && position.length;
    }),

    hasPreferredContactNumber: computed("preferredContactNumber", function () {
      var preferredContactNumber = this.get("preferredContactNumber");
      return preferredContactNumber && preferredContactNumber.length;
    }),

    isInactive: computed.not("isActive"),

    isActive: computed("status", function () {
      return this.get("status") === "pending" || this.get("status") === "approved";
    }),

    isInfoComplete: computed("hasPosition", "hasPreferredContactNumber", function () {
      return this.get("hasPosition") && this.get("hasPreferredContactNumber");
    })
  });
});