define('browse/models/orders_purpose', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    orderId: (0, _attr.default)('number'),
    purposeId: (0, _attr.default)('number'),
    order: (0, _relationships.belongsTo)('order', { async: false }),
    purpose: (0, _relationships.belongsTo)('purpose', { async: false })
  });
});