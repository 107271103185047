define("browse/controllers/my_offer_responses", ["exports", "browse/controllers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _application.default.extend({
    offerResponses: alias("model"),
    messageBox: service(),
    i18n: service(),

    sortProperties: ["createdAt:desc"],
    arrangedOrders: sort("offerResponses", "sortProperties"),

    offers: Ember.computed("offerResponses.[]", function () {
      return this.get("offerResponses").map(function (response) {
        return response.get("offer");
      });
    }),

    actions: {
      visitSharedOffer: function visitSharedOffer(offer) {
        this.transitionToRoute("offers.messages", offer.id, {
          queryParams: {
            uid: offer.get("publicUid")
          }
        });
      }
    }
  });
});