define("browse/controllers/cart", ["exports", "browse/controllers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    actions: {
      displayCart: function displayCart() {
        this.transitionToRoute("/");
      }
    }

  });
});