define("browse/models/gogovan_transport", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    disabled: (0, _attr.default)("boolean"),

    specialId: computed("id", function () {
      return this.get("id") + "_ggv";
    })
  });
});