define("browse/models/booking_type", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    nameEn: (0, _attr.default)("string"),
    nameZh: (0, _attr.default)("string"),
    identifier: (0, _attr.default)("string"),

    isAppointment: computed("identifier", function () {
      return this.get("identifier") === "appointment";
    }),

    isOnlineOrder: computed("identifier", function () {
      return this.get("identifier") === "online-order";
    })
  });
});