define("browse/controllers/home", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    i18n: service(),
    settings: service(),
    isMobileApp: _environment.default.cordova.enabled,
    crossroadsCharityYoutubeVideo: _environment.default.APP.CROSSROADS_YOUTUBE_VIDEO,

    langShort: computed("i18n.locale", function () {
      var lang = this.get("i18n.locale");
      return (/zh/.test(lang) ? "zh_tw" : "en"
      );
    }),

    appointmentWarning: computed("langShort", function () {
      return this.get("settings").readString("browse.appointment_warning_" + this.get("langShort"));
    }),

    onlineOrderWarning: computed("langShort", function () {
      return this.get("settings").readString("browse.online_order_warning_" + this.get("langShort"));
    }),

    freeDeliveryEnabled: computed(function () {
      return this.get("settings").readBoolean("browse.free_delivery_enabled");
    }),

    freeDeliveryPackageId: computed(function () {
      return this.get("settings").readString("browse.free_delivery_package_id");
    }),

    freeDeliveryQuantityAvailable: computed(function () {
      var packageId = this.get("freeDeliveryPackageId");
      var pkg = this.store.peekRecord("package", packageId);
      return pkg && pkg.get("availableQuantity") > 0;
    })
  });
});