define("browse/controllers/my_notifications", ["exports", "lodash"], function (exports, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var later = Ember.run.later;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;


  var getMessageKey = function getMessageKey(msg) {
    return [msg.get("isPrivate") ? "private" : "public", msg.get("messageableType") || "-", msg.get("messageableId") || "-"].join("/");
  };

  exports.default = Controller.extend({
    messagesUtil: service("messages"),
    store: service(),
    logger: service(),
    subscription: service(),

    displayMessages: true,
    showUnread: true,
    notifications: [],

    on: function on() {
      // When a new message arrives, we move it to the top
      this.get("subscription").on("change:message", this, this.onNewNotification);
    },
    off: function off() {
      // When a new message arrives, we move it to the top
      this.get("subscription").off("change:message", this, this.onNewNotification);
    },
    onNewNotification: function onNewNotification(notification) {
      var store = this.get("store");
      var msg = store.peekRecord("message", notification.record.id);
      var messageableId = notification.record.messageable_id;
      var notifications = this.get("notifications");

      if (!messageableId || this.session.router.currentURL !== "/my_notifications") {
        return;
      }

      var notif = notifications.findBy("key", getMessageKey(msg));

      if (notification.operation === "create") {
        if (notif) {
          // Update existing one
          notifications.removeObject(notif);
          msg.set("unreadCount", +notif.get("unreadCount") + 1);
          notif.get("messages").addObject(msg);
        } else {
          // Create new one
          msg.set("unreadCount", 1);
          notif = this.messagesToNotification([msg]);
        }
        notifications.insertAt(0, notif);
      } else if (notification.operation === "update" && notif && notification.record.state === "read") {
        notif.set("unreadCount", 0);
      }
    },


    /**
     * Creates a single notification out of multiple messages
     *
     * @param {*} messages
     * @returns
     */
    messagesToNotification: function messagesToNotification(messages) {
      var props = ["id", "sender", "createdAt", "isPrivate"];
      var lastMessage = messages.sortBy("id").get("lastObject");

      var order = void 0,
          offerResponse = void 0,
          offerId = void 0;
      var recordId = lastMessage.get("messageableId");

      if (lastMessage.get("isOrderMessage")) {
        order = this.get("store").peekRecord("order", recordId) || this.get("store").findRecord("order", recordId);
      }
      if (lastMessage.get("isOfferResponseMessage")) {
        offerResponse = this.get("store").peekRecord("offerResponse", recordId) || this.get("store").findRecord("offerResponse", recordId);

        offerId = offerResponse.get("offerId");
      }

      var notification = EmberObject.create(lastMessage.getProperties(props));
      notification.setProperties({
        key: getMessageKey(lastMessage),
        messages: messages,
        isSingleMessage: computed.equal("unreadCount", 1),
        isThread: computed.not("isSingleMessage"),
        order: order,
        offerResponse: offerResponse,
        text: computed("messages.[]", function () {
          return this.get("messages").sortBy("id").get("lastObject.plainBody");
        }),
        unreadCount: computed("messages.@each.unreadCount", "messages.[]", {
          get: function get() {
            var lastMessage = this.get("messages").sortBy("id").get("lastObject");
            return lastMessage.get("unreadCount");
          },
          set: function set(key, value) {
            return value;
          }
        })
      });
      return notification;
    },


    /**
     * Transform messages into "notifications" object with more UI-friendly properties
     *
     * @param {Message} messages
     * @returns {Object}
     */
    buildNotifications: function buildNotifications(messages) {
      var _this = this;

      var groupedMessages = messages.filter(function (msg) {
        return _this.get("showUnread") ? msg.get("isUnread") : true;
      });

      return _lodash.default.chain(groupedMessages).groupBy(getMessageKey).map(function (msgs) {
        return _this.messagesToNotification(msgs);
      }).value();
    },


    /**
     * Injects API JSON into the store and returns a list of models
     *
     * @param {Object} data
     * @returns {Offer[]}
     */
    toMessageModels: function toMessageModels(data) {
      var _this2 = this;

      this.get("store").pushPayload(data);
      return data.messages.map(function (_ref) {
        var id = _ref.id;

        return _this2.get("store").peekRecord("message", id);
      });
    },


    actions: {
      /**
       * Loads a page of messages
       * Used by the infinite list
       *
       * @param {*} pageNo
       * @returns
       */
      loadMoreMessages: function loadMoreMessages(pageNo) {
        var _this3 = this;

        var state = this.get("showUnread") ? "unread" : "";

        this.get("messagesUtil").queryNotifications(pageNo, state).then(function (data) {
          return _this3.toMessageModels(data);
        }).then(function (messages) {
          var notifications = _lodash.default.chain(messages).groupBy(getMessageKey).map(function (o) {
            return _this3.buildNotifications(o);
          }).flatten().value();

          _this3.get("notifications").addObjects(notifications);
          return notifications;
        });
      },
      view: function view(notification) {
        var message = this.store.peekRecord("message", notification.id);
        var route = this.get("messagesUtil").getRoute(message, notification);
        this.transitionToRoute.apply(this, route);
      },
      markThreadRead: function markThreadRead(notification) {
        if (notification.get("unreadCount") === 1) {
          var message = this.store.peekRecord("message", notification.id);
          this.get("messagesUtil").markRead(message);
          notification.set("unreadCount", 0);
        } else {
          this.send("view", notification);
        }
      },
      toggleShowUnread: function toggleShowUnread() {
        this.set("displayMessages", false);
        this.get("notifications").clear();
        later(this, function () {
          var showUnread = !this.get("showUnread");
          this.set("showUnread", showUnread);
          this.set("displayMessages", true);
        }, 0);
      },
      markAllRead: function markAllRead() {
        var _this4 = this;

        this.get("messagesUtil").markAllRead().then(function () {
          _this4.get("notifications").forEach(function (n) {
            n.set("unreadCount", 0);
          });
        }).catch(function (e) {
          _this4.get("logger").error(e);
        });
      }
    }
  });
});