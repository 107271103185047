define("browse/controllers/package", ["exports", "browse/controllers/package_set"], function (exports, _package_set) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  exports.default = _package_set.default.extend({
    package: alias("model"),

    packageSet: computed.alias("package.packageSet"),

    isPackagePartOfSet: computed("package", "packageSet.packages.[]", function () {
      var packageSet = this.get("packageSet");
      return packageSet && packageSet.get("packages").length > 1;
    })
  });
});