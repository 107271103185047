define("browse/models/organisation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _model.default.extend({
    nameEn: (0, _attr.default)("string"),
    nameZhTw: (0, _attr.default)("string"),
    registration: (0, _attr.default)("string"),
    website: (0, _attr.default)("string"),
    descriptionEn: (0, _attr.default)("string"),
    descriptionZhTw: (0, _attr.default)("string"),
    position: (0, _attr.default)("string"),
    user: (0, _relationships.belongsTo)("user", { async: false }),

    usersCount: alias("organisationsUsers.length"),

    organisationsUsers: (0, _relationships.hasMany)("organisations_user", { async: false }),

    nameAndDescription: computed("nameEn", "descriptionEn", function () {
      return this.get("nameEn") + " " + this.get("descriptionEn");
    })
  });
});