define('browse/models/user_role', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    userId: (0, _attr.default)('number'),
    roleId: (0, _attr.default)('number'),

    user: (0, _relationships.belongsTo)('user', { async: false }),
    role: (0, _relationships.belongsTo)('role', { async: false })
  });
});