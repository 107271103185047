define('browse/models/addressable', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Addressable = _model.default.extend({
    address: (0, _relationships.belongsTo)('address', { async: false })
  });

  exports.default = Addressable;
});