define("browse/routes/application", ["exports", "browse/config/environment", "lodash"], function (exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var getOwner = Ember.getOwner;
  var later = Ember.run.later;
  exports.default = Route.extend({
    logger: service(),
    preloadService: service(),
    messageBox: service(),
    i18n: service(),
    previousRoute: null,
    isErrPopUpAlreadyShown: false,

    unlessIncludesCurrentPath: function unlessIncludesCurrentPath() {
      var currentPath = window.location.href;
      return !(currentPath.indexOf("login") >= 0 || currentPath.indexOf("authenticate") >= 0 || currentPath.indexOf("category") >= 0 || currentPath.indexOf("package") >= 0 || currentPath.indexOf("item") >= 0 || currentPath.indexOf("browse") >= 0 || currentPath.indexOf("offers") >= 0 || window.location.pathname === "/");
    },
    init: function init() {
      var _this = this;
      var storageHandler = function storageHandler(object) {
        var currentPath = window.location.href;
        var authToken = window.localStorage.getItem("authToken");
        if (!authToken && object.unlessIncludesCurrentPath()) {
          object.session.clear();
          object.store.unloadAll();
          object.transitionTo("/login");
        } else if (authToken && (currentPath.indexOf("login") >= 0 || currentPath.indexOf("authenticate") >= 0)) {
          object.transitionTo("/");
        }
      };
      window.addEventListener("storage", function () {
        storageHandler(_this);
      }, false);
    },
    beforeModel: function beforeModel(transition) {
      var _this2 = this;

      try {
        localStorage.test = "isSafariPrivateBrowser";
      } catch (e) {
        this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
      }

      var language = this.get("session.language") || _environment.default.i18n.defaultLocale;
      if (transition.queryParams.ln) {
        language = transition.queryParams.ln === "zh-tw" ? "zh-tw" : "en";
      }
      this.set("session.language", language);
      this.set("i18n.locale", language);
      this.set("previousRoute", transition);
      Ember.onerror = window.onerror = function (error) {
        if (error.errors && error.errors[0] && error.errors[0].status === "401") {
          transition.abort();
        }
        _this2.handleError(error);
      };
      return this.get("preloadService").preloadData();
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render("sidebar", {
        into: "application",
        outlet: "sidebar"
      });
    },
    redirectToLogin: function redirectToLogin() {
      if (this.session.get("isLoggedIn")) {
        this.session.clear();
        this.store.unloadAll();
        var loginController = this.controllerFor("login");
        loginController.set("attemptedTransition", this.get("previousRoute"));
        this.transitionTo("login");
      }
    },
    getErrorMessage: function getErrorMessage(reason) {
      var error = _lodash.default.get(reason, "responseJSON.errors[0]") || _lodash.default.get(reason, "responseJSON.error") || _lodash.default.get(reason, "errors[0]") || _lodash.default.get(reason, "error");

      if (_lodash.default.get(reason, "errors[0].status") === 403) {
        return this.get("i18n").t("not_allowed_error");
      } else if (error && _lodash.default.isString(error)) {
        return error;
      } else if (reason.errors && reason.errors.length && reason.errors[0].detail && reason.errors[0].detail.status === 422) {
        return reason.errors[0].detail.message;
      } else {
        return this.get("i18n").t("unexpected_error");
      }
    },
    showErrorPopup: function showErrorPopup(reason) {
      var _this3 = this;

      this.get("logger").error(reason);
      if (!this.get("isErrPopUpAlreadyShown")) {
        this.set("isErrPopUpAlreadyShown", true);
        this.get("messageBox").alert(this.getErrorMessage(reason), function () {
          _this3.set("isErrPopUpAlreadyShown", false);
          _this3.transitionTo("/");
        });
      }
    },
    offlineError: function offlineError(reason) {
      this.get("messageBox").alert(this.get("i18n").t("offline_error"));
      if (!reason.isAdapterError) {
        this.get("logger").error(reason);
      }
    },
    quotaExceededError: function quotaExceededError(reason) {
      this.get("logger").error(reason);
      this.get("messageBox").alert(this.get("i18n").t("QuotaExceededError"));
    },


    handleError: function handleError(reason) {
      try {
        var status;
        // let hasPopup = Ember.$('.reveal-modal:visible').length > 0;
        try {
          status = parseInt(reason.errors[0].status, 10);
        } catch (err) {
          status = reason.status;
        }

        if (!window.navigator.onLine) {
          this.offlineError(reason);
        } else if (reason.name === "QuotaExceededError") {
          this.quotaExceededError(reason);
        } else if (reason.name === "NotFoundError" && reason.code === 8) {
          return false;
        } else if (status === 401) {
          this.redirectToLogin();
        } else {
          this.showErrorPopup(reason);
        }
      } catch (err) {
        console.log(err);
      }
    },

    actions: {
      loading: function loading() {
        if (!this.loadingView) {
          this.loadingView = getOwner(this).lookup("component:loading").append();
        }
      },
      didTransition: function didTransition() {
        var _this4 = this;

        // Without later() it causes double render error
        // as we're trying to render a page and remove loading
        // indicator at a same time
        later(function () {
          // Clear the loader only if the loader is actually present in the DOM
          if (_this4.loadingView && document.getElementById(_this4.loadingView.elementId)) {
            _this4.loadingView.destroy();
            _this4.loadingView = null;
          }
        }, 100);
      },
      error: function error(reason) {
        try {
          this.handleError(reason);
        } catch (err) {
          console.log(err);
        }
      }
    },

    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("pageTitle", this.get("i18n").t("browse.title"));
    }
  });
});