define("browse/routes/my_account", ["exports", "browse/routes/account_details"], function (exports, _account_details) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _account_details.default.extend({
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var applicationController = this.controllerFor("application");
      applicationController.set("showSidebar", true);
      applicationController.set("pageTitle", this.get("i18n").t("my_dashboard.title"));
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.controllerFor("application").set("pageTitle", this.get("i18n").t("browse.title"));
      }
    }
  });
});