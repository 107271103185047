define("browse/controllers/authenticate", ["exports", "browse/utils/ajax-promise", "browse/config/environment"], function (exports, _ajaxPromise, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var getOwner = Ember.getOwner;

  var timeout = void 0;

  exports.default = Controller.extend({
    queryParams: ["bookAppointment"],
    messageBox: service(),
    application: controller(),
    attemptedTransition: null,
    bookAppointment: false,
    pin: "",
    timer: _environment.default.APP.OTP_RESEND_TIME,
    pinAlreadySent: false,
    cart: service(),
    mobileOrEmail: "",
    loginParam: computed.localStorage(),
    loginParamEmail: computed.localStorage(),
    mobile: "",
    email: "",

    pinFor: computed("email", "mobile", function () {
      if (this.get("email")) {
        return "email";
      } else if (this.get("mobile")) {
        return "mobile";
      }
    }),

    timerFunction: function timerFunction() {
      var _this2 = this;

      var waitTime = this.get("timer");
      if (waitTime === 0) {
        this.resetTimerParameters();
        return false;
      }
      this.set("timer", waitTime - 1);
      timeout = setTimeout(function () {
        _this2.timerFunction();
      }, 1000);
    },
    resetTimerParameters: function resetTimerParameters() {
      this.set("pinAlreadySent", false);
      this.set("timer", _environment.default.APP.OTP_RESEND_TIME);
    },
    setMobileOrEmail: function setMobileOrEmail() {
      var mobileOrEmail = this.get("mobileOrEmail").trim();
      if (/^[456789]\d{7}/.test(mobileOrEmail)) {
        this.set("mobile", "+852" + mobileOrEmail);
      } else {
        this.set("email", mobileOrEmail);
      }
    },


    actions: {
      showItemDetails: function showItemDetails(record) {
        this.get("cart").navigateToItemDetails(record);
      },
      authenticateUser: function authenticateUser(bookAppointment) {
        $(".auth_error").hide();
        var pin = this.get("pin");
        var pin_for = this.get("pinFor");
        var otp_auth_key = this.get("session.otpAuthKey");
        var _this = this;

        new _ajaxPromise.default("/auth/verify", "POST", null, {
          pin: pin,
          otp_auth_key: otp_auth_key,
          pin_for: pin_for
        }).then(function (data) {
          clearTimeout(timeout);
          _this.resetTimerParameters();
          _this.setProperties({
            pin: null
          });
          _this.set("session.authToken", data.jwt_token);
          _this.set("session.otpAuthKey", null);
          _this.store.pushPayload(data.user);
          _this.setProperties({
            pin: null
          });
          _this.transitionToRoute("post_login", {
            queryParams: {
              bookAppointment: bookAppointment
            }
          });
        }).catch(function (jqXHR) {
          $("#pin").closest("div").addClass("error");
          _this.setProperties({
            pin: null
          });
          if (jqXHR.status === 422 && jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.pin) {
            _this.get("messageBox").alert(jqXHR.responseJSON.errors.pin);
          }
          console.log("Unable to authenticate");
        });
      },
      resendPin: function resendPin() {
        var _this3 = this;

        this.setMobileOrEmail();
        var mobile = this.get("mobile");
        var email = this.get("email");
        this.set("loginParam", mobile || email);
        this.set("loginParamEmail", email ? true : false);
        var loadingView = getOwner(this).lookup("component:loading").append();
        var _this = this;
        var user_auth = {
          mobile: mobile,
          email: email,
          address_attributes: {
            district_id: null,
            address_type: null
          }
        };
        new _ajaxPromise.default("/auth/signup", "POST", _this.get("session.authToken"), {
          user_auth: user_auth
        }, null, _this.get("session.language")).then(function (data) {
          _this3.set("session.otpAuthKey", data.otp_auth_key);
          _this3.set("session.loginPage", false);
          _this3.setProperties({
            pin: null
          });
          _this3.set("pinAlreadySent", true);
          _this3.timerFunction();
          _this3.transitionToRoute("authenticate", {
            queryParams: {
              bookAppointment: _this3.get("bookAppointment")
            }
          });
        }).catch(function (error) {
          if ([401].indexOf(error.status) >= 0) {
            _this.get("messageBox").alert(_this3.get("i18n").t("unauthorized"), function () {
              _this.transitionToRoute("/");
            });
          } else if ([422, 403].indexOf(error.status) >= 0) {
            _this.get("messageBox").alert(error.responseJSON.errors);
          }
          throw error;
        }).finally(function () {
          return loadingView.destroy();
        });
      }
    }
  });
});