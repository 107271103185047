define("browse/mixins/async_tasks", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reject = Ember.RSVP.reject;
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  var getOwner = Ember.getOwner;
  exports.default = Mixin.create({
    messageBox: service(),

    // ---- Helpers

    __tasksCount: 0,
    __loadingView: null,

    __incrementTaskCount: function __incrementTaskCount() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      this.__tasksCount += val;
      if (this.__tasksCount > 0) {
        this.showLoadingSpinner();
      } else {
        this.__tasksCount = 0;
        this.hideLoadingSpinner();
      }
    },


    // --- Mixin api

    runTask: function runTask(task) {
      var _this = this;

      this.__incrementTaskCount();
      return task.then(function (res) {
        _this.__incrementTaskCount(-1);
        return res;
      }).catch(function (err) {
        _this.__incrementTaskCount(-1);
        return reject(err);
      });
    },
    showLoadingSpinner: function showLoadingSpinner() {
      if (Ember.testing) {
        return;
      }
      if (!this.__loadingView) {
        this.__loadingView = getOwner(this).lookup("component:loading").append();
      }
    },
    hideLoadingSpinner: function hideLoadingSpinner() {
      if (Ember.testing) {
        return;
      }
      if (this.__loadingView) {
        this.__loadingView.destroy();
        this.__loadingView = null;
      }
    },
    i18nAlert: function i18nAlert(key, cb) {
      this.get("messageBox").alert(this.get("i18n").t(key), cb);
    }
  });
});