define("browse/components/quantity-widget", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var observer = Ember.observer;
  var debounce = Ember.run.debounce;
  var Component = Ember.Component;
  exports.default = Component.extend({
    value: computed("package", function () {
      return this.get("package.requestedPackage") ? this.get("package.requestedPackage.quantity") : 1;
    }),

    onValueChange: observer("value", "package.computedMaxOrderQuantity", function () {
      debounce(this, this.enforceValueMax, 1);
    }),

    performAction: function performAction(value) {
      if (this.get("type") == "request") {
        this.requestAction(value);
      } else {
        this.updateAction(value, this.get("package.id"));
      }
    },
    enforceValueMax: function enforceValueMax() {
      var val = Number(this.get("value"));
      var max = this.get("package.computedMaxOrderQuantity");
      if (val > max) {
        this.set("value", max);
        this.notifyPropertyChange("value");
      }
    },
    validValueCheck: function validValueCheck(pkg) {
      var quantity = +this.get("value");
      return quantity < 1 || quantity > pkg.get("computedMaxOrderQuantity") || !quantity;
    },
    setUpdatedValue: function setUpdatedValue(value, isValueValid) {
      if (isValueValid) {
        this.set("showErrorMessage", false);
        this.set("value", value);
        this.performAction(value);
      } else {
        this.set("showErrorMessage", true);
      }
    },


    actions: {
      incrementQty: function incrementQty(quantity) {
        var incrementedValue = +this.get("value") + 1;
        this.setUpdatedValue(incrementedValue, incrementedValue <= +quantity);
      },
      decrementQty: function decrementQty() {
        var decrementedValue = +this.get("value") - 1;
        this.setUpdatedValue(decrementedValue, decrementedValue >= 1);
      },
      focusOut: function focusOut(pkg) {
        if (this.validValueCheck(pkg)) {
          this.set("showErrorMessage", true);
          this.set("value", 1);
        } else {
          this.set("showErrorMessage", false);
        }
        this.performAction(+this.get("value"));
      },


      // Fix: Failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});