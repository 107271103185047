define("browse/controllers/about", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    i18n: service(),
    settings: service(),

    langShort: computed("i18n.locale", function () {
      var lang = this.get("i18n.locale");
      return (/zh/.test(lang) ? "zh_tw" : "en"
      );
    }),

    appointmentWarning: computed("langShort", function () {
      return this.get("settings").readString("browse.appointment_warning_" + this.get("langShort"));
    }),

    onlineOrderWarning: computed("langShort", function () {
      return this.get("settings").readString("browse.online_order_warning_" + this.get("langShort"));
    })
  });
});