define("browse/services/cart", ["exports", "browse/services/api-base-service", "lodash", "browse/mixins/async_tasks"], function (exports, _apiBaseService, _lodash, _async_tasks) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var resolve = Ember.RSVP.resolve;
  var all = Ember.RSVP.all;
  var alias = Ember.computed.alias;
  var empty = Ember.computed.empty;
  var not = Ember.computed.not;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _apiBaseService.default.extend(_async_tasks.default, {
    store: service(),
    session: service(),
    localStorage: service(),
    preloadService: service(),
    messageBox: service(),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.get("session").addObserver("currentUser", this.onUserChange.bind(this));
      this.onUserChange();
      this.get("preloadService").one("data", function () {
        _this2.restoreGuestItems();
      });
    },


    // ---- Properties

    cartItems: computed(function () {
      return this.get("store").peekAll("requested_package");
    }),

    packages: computed("cartItems.[]", function () {
      return this.get("cartItems").mapBy("package");
    }),

    offlineCartItems: computed("cartItems.[]", "cartItems.@each.id", function () {
      return this.get("cartItems").filter(function (it) {
        return !it.get("id");
      });
    }),

    availableCartItems: computed("cartItems.[]", "cartItems.@each.isAvailable", function () {
      return this.get("cartItems").filterBy("isAvailable", true);
    }),

    unavailableCartItems: computed("cartItems.[]", "cartItems.@each.isAvailable", function () {
      return this.get("cartItems").filterBy("isAvailable", false);
    }),

    hasValidItems: computed("unavailableCartItems", "isNotEmpty", function () {
      return this.get("isNotEmpty") && this.get("unavailableCartItems.length") === 0;
    }),

    canCheckout: computed("availableCartItems.length", function () {
      var availableItemCount = this.get("availableCartItems.length");
      return availableItemCount > 0 && availableItemCount === this.get("cartItems.length");
    }),

    isLoggedIn: alias("session.authToken"),
    isEmpty: empty("cartItems"),
    isNotEmpty: not("isEmpty"),
    counter: alias("cartItems.length"),
    user: alias("session.currentUser"),
    userId: alias("user.id"),

    // ---- Hooks

    onUserChange: function onUserChange() {
      var loggedIn = this.get("user");
      if (loggedIn) {
        this.onLogin();
      } else {
        this.onLogout();
      }
    },


    /**
     * When the user logs in we
     *    - fetch the user's cart items
     *    - push any record he/she might have added as a guest before
     *   logging in
     */
    onLogin: function onLogin() {
      var _this3 = this;

      this.populate().then(function () {
        _this3.persistLocalRecords();
      });
    },


    /**
     * When logging out, we clear the local data
     */
    onLogout: function onLogout() {
      var _this4 = this;

      this.get("store").peekAll("requested_package").forEach(function (record) {
        return _this4.get("store").unloadRecord(record);
      });
    },


    // ---- Control methods

    /**
     * Checkout the cart and fill the order
     */
    checkoutOrder: function checkoutOrder(order) {
      var _this5 = this;

      return this.POST("/requested_packages/checkout", {
        order_id: order.get("id")
      }).then(function (data) {
        _this5.get("store").pushPayload(data);
        _this5.refresh();
        return data.orders_packages.map(function (data) {
          return _this5.get("store").findRecord("package", data.package_id, {
            reload: true
          });
        });
      });
    },
    updateRequestedQuantity: function updateRequestedQuantity(pkgId, quantity) {
      var record = this.get("cartItems").findBy("packageId", pkgId);
      if (record) {
        record.set("quantity", +quantity);
        return record.save();
      }
    },


    /**
     * Fetches the user's cart items from the api
     */
    populate: function populate() {
      if (!this.get("isLoggedIn")) {
        return resolve();
      }
      return this.get("store").findAll("requested_package", { reload: true });
    },


    /**
     * Clears the local data and re-fetches the data
     */
    refresh: function refresh() {
      this.get("store").unloadAll("requested_package");
      return this.populate();
    },


    /**
     * Cart items that were added as guest are pushed to the db
     */
    persistLocalRecords: function persistLocalRecords() {
      var _this6 = this;

      if (!this.get("isLoggedIn")) {
        return resolve();
      }

      this.clearDuplicates();

      return all(this.get("offlineCartItems").map(function (it) {
        it.set("user", _this6.get("user"));
        it.set("userId", _this6.get("userId"));
        return it.save();
      })).then(function (res) {
        _this6.forgetGuestItems();
        return res;
      });
    },
    add: function add(pkgOrSet, quantity) {
      if (pkgOrSet.get("isSet")) {
        this.runTask(this.addSet(pkgOrSet));
      } else {
        this.runTask(this.addPackage(pkgOrSet, quantity));
      }
    },


    /**
     * Adds the packages of the set to the cloud cart.
     * If the user is not logged in, will only save it locally
     */
    addSet: function addSet(packageSet) {
      var _this7 = this;

      return all(packageSet.get("packages").map(function (pkg) {
        return _this7.addPackage(pkg, pkg.get("quantity"));
      }));
    },


    /**
     * Adds a package to the cloud cart.
     * If the user is not logged in, will only save it locally
     */
    addPackage: function addPackage(pkg, quantity) {
      var _this = this;
      var record = this.get("store").createRecord("requested_package", {
        packageId: pkg.get("id"),
        package: pkg,
        isAvailable: pkg.get("isAvailable"),
        quantity: +quantity
      });

      this.notifyPropertyChange("cartItems");

      if (!this.get("isLoggedIn")) {
        // Will be persisted when the user logs in
        this.rememberGuestItems();
        return resolve();
      }

      record.set("user", this.get("user"));
      record.set("userId", this.get("userId"));

      return record.save().catch(function (error) {
        var errorMessage = error.errors[0].detail.message;
        // Remove record from Cart
        _this.get("store").unloadRecord(record);
        _this.get("messageBox").alert(errorMessage, function () {
          _this.get("router").transitionTo("/");
        });
      });
    },
    remove: function remove(pkgOrSet) {
      if (pkgOrSet.get("isSet")) {
        this.runTask(this.removeSet(pkgOrSet));
      } else {
        this.runTask(this.removePackage(pkgOrSet));
      }
    },


    /**
     * Removes the packages of the set from the cart
     * The changes are local only if the user is not logged in
     */
    removeSet: function removeSet(packageSet) {
      var _this8 = this;

      return all(packageSet.get("packages").map(function (pkg) {
        return _this8.removePackage(pkg);
      }));
    },


    /**
     * Remove the cart item of the specified package
     * The changes are local only if the user is not logged in
     */
    removePackage: function removePackage(pkg) {
      var cartItem = this.getCartItemForPackage(pkg);
      if (!cartItem) {
        return resolve();
      }
      return this.removeCartItem(cartItem, pkg.id);
    },


    /**
     * Remove a cart item from the cart
     * The changes are local only if the user is not logged in
     */
    removeCartItem: function removeCartItem(cartItem, pkgId) {
      cartItem.deleteRecord();

      if (!this.get("isLoggedIn")) {
        this.removeGuestCartItem(pkgId);
        return resolve();
      }
      return cartItem.save();
    },


    /**
     * Returns the cart item associated with the package
     */
    getCartItemForPackage: function getCartItemForPackage(pkg) {
      return this.get("cartItems").findBy("packageId", pkg.id);
    },


    /**
     * Returns true if the package or set is already in the cart
     */
    contains: function contains(pkgOrSet) {
      if (pkgOrSet.get("isSet")) {
        return this.containsSet(pkgOrSet);
      }
      return this.containsPackage(pkgOrSet);
    },


    /**
     * Returns true if the package is already in the cart
     */
    containsPackage: function containsPackage(pkg) {
      return !!this.getCartItemForPackage(pkg);
    },


    /**
     * Returns true if all of the set's packages are in the cart
     */
    containsSet: function containsSet(packageSet) {
      var _this9 = this;

      return packageSet.get("packages").every(function (pkg) {
        return _this9.containsPackage(pkg);
      });
    },


    /**
     * Returns true if the package or set is both in the cart and available
     */
    isAvailable: function isAvailable(pkgOrSet) {
      var _this10 = this;

      if (!this.contains(pkgOrSet)) {
        return false;
      }

      var packages = pkgOrSet.get("isSet") ? pkgOrSet.get("packages").toArray() : [pkgOrSet];

      return _lodash.default.every(packages, function (pkg) {
        return _this10.getCartItemForPackage(pkg).get("isAvailable");
      });
    },


    /**
     * If an offline item already exists in the user cart, clear it
     */
    clearDuplicates: function clearDuplicates() {
      var _this11 = this;

      this.get("offlineCartItems").forEach(function (it) {
        var existingItem = _this11.get("cartItems").find(function (ci) {
          return ci.get("id") && ci.get("packageId") === it.get("packageId");
        });

        if (existingItem) {
          it.deleteRecord();
          _this11.notifyPropertyChange("cartItems");
        }
      });
    },
    navigateToItemDetails: function navigateToItemDetails(record) {
      var isPackageSet = record.get("isSet");
      var categoryId = record.get("allPackageCategories.firstObject.id");
      var sortBy = "createdAt:desc";

      var route = isPackageSet ? "package_set" : "package";
      var routeId = record.get("id");
      this.get("router").transitionTo(route, routeId, {
        queryParams: {
          categoryId: categoryId,
          sortBy: sortBy
        }
      });
    },


    /**
     * If a package is in the cart, as well as all it's siblings
     * It will appear as an 'item' (if they are available)
     *
     * This property groups them as such
     *
     * e.g
     *  [
     *    ...
     *    {
     *       record: <the package or item>
     *       isAvailabel: <availability>
     *    }
     *  ]
     */
    groupedPackages: computed("cartItems.[]", "cartItems.@each.isAvailable", function () {
      var _this12 = this;

      var res = [];
      var refs = {};

      this.get("packages").forEach(function (pkg) {
        // Single packages
        if (!pkg.get("hasSiblingPackages")) {
          res.push(pkg);
          return;
        }

        // Items
        var packageSet = pkg.get("packageSet");
        var packageSetId = packageSet.get("id");

        if (refs[packageSetId]) {
          return; // Already processed
        }

        if (_this12.isAvailable(packageSet)) {
          // We mark this item as in cart and available
          refs[packageSetId] = packageSet;
          res.push(packageSet);
          return;
        }

        res.push(pkg); // We store the package a singleton
      });

      return _lodash.default.map(res, function (record) {
        var isAvailable = _this12.isAvailable(record);
        return { record: record, isAvailable: isAvailable };
      });
    }),

    // --- Helpers

    rememberGuestItems: function rememberGuestItems() {
      var refs = this.get("offlineCartItems").mapBy("packageId");
      this.get("localStorage").write("offlineCart", refs);
    },
    forgetGuestItems: function forgetGuestItems() {
      this.get("localStorage").remove("offlineCart");
    },
    removeGuestCartItem: function removeGuestCartItem(pkgId) {
      var pkgIds = this.get("localStorage").read("offlineCart", []);
      pkgIds = _lodash.default.filter(pkgIds, function (record) {
        return +record !== +pkgId;
      });
      this.get("localStorage").write("offlineCart", pkgIds);
    },
    restoreGuestItems: function restoreGuestItems() {
      var _this13 = this;

      var pkgIds = this.get("localStorage").read("offlineCart", []);

      pkgIds.map(function (id) {
        return _this13.get("store").peekRecord("package", id);
      }).filter(_lodash.default.identity).reject(function (pkg) {
        return _this13.contains(pkg);
      }).forEach(function (pkg) {
        return _this13.add(pkg, 1);
      });
    }
  });
});