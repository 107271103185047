define("browse/components/language-switcher", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    isEnglish: computed("session.language", function () {
      return this.get("session.language") === "en";
    }),

    isChinese: computed("session.language", function () {
      return this.get("session.language") === "zh-tw";
    }),

    actions: {
      setLanguage: function setLanguage(language) {
        this.set("session.language", language);
        window.location.reload();
      }
    }
  });
});