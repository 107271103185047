define("browse/helpers/message-time", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var buildHelper = Ember.Helper.helper;
  exports.default = buildHelper(function (value) {
    var _MS_PER_DAY = 86400000;
    var messageTime = Date.parse(value);
    var currentTime = Date.now();

    var dayDifference = Math.floor((currentTime - messageTime) / _MS_PER_DAY);

    if (!messageTime) {
      return "";
    } else if (dayDifference < 1) {
      return moment(messageTime).format("HH:mm");
    } else if (dayDifference < 7) {
      return moment(messageTime).format("dddd");
    } else {
      return moment(messageTime).format("DD.MM");
    }
  });
});