define("browse/services/preload-service", ["exports", "lodash", "browse/config/environment", "browse/services/api-base-service"], function (exports, _lodash, _environment, _apiBaseService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var Evented = Ember.Evented;
  var _config$APP = _environment.default.APP,
      PRELOAD_TYPES = _config$APP.PRELOAD_TYPES,
      PRELOAD_AUTHORIZED_TYPES = _config$APP.PRELOAD_AUTHORIZED_TYPES,
      PRELOAD_OF_TYPE_ORDER = _config$APP.PRELOAD_OF_TYPE_ORDER;
  exports.default = _apiBaseService.default.extend(Evented, {
    store: service(),
    session: service(),
    messages: service(),
    isLoggedIn: alias("session.isLoggedIn"),

    preloadData: function preloadData() {
      var _this = this;

      return all([this.loadStaticData(), this.loadUserData()]).then(function (res) {
        _this.trigger("data");
        return res;
      });
    },

    loadStaticData: function loadStaticData() {
      return this.fetchAll(PRELOAD_TYPES);
    },
    loadOrderData: function loadOrderData() {
      return this.fetchAll(PRELOAD_OF_TYPE_ORDER);
    },
    loadMessages: function loadMessages() {
      return this.get("messages").fetchUnreadMessages();
    },
    loadUserData: function loadUserData() {
      if (!this.get("isLoggedIn")) {
        return resolve();
      }

      return all([this.get("session").loadUserProfile(), this.fetchAll(PRELOAD_AUTHORIZED_TYPES), this.loadOrderData(), this.loadMessages()]);
    },
    fetchAll: function fetchAll(types) {
      var _this2 = this;

      return all(_lodash.default.map(types, function (t) {
        return _this2.fetch(t);
      }));
    },
    fetch: function fetch(type) {
      if (_lodash.default.isString(type)) {
        type = [type, {}];
      }

      var _type = type,
          _type2 = _slicedToArray(_type, 2),
          model = _type2[0],
          params = _type2[1];

      return this.get("store").query(model, params);
    }
  });
});