define("browse/routes/session", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      if (window.localStorage.getItem("authToken") && this.session.get("isLoggedIn")) {
        this.transitionTo("/");
      } else {
        this.set("cart.checkout", true);
      }
    }
  });
});