define("browse/models/package_type", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    code: (0, _attr.default)("string"),
    allowRequests: (0, _attr.default)("boolean"),
    package_sets: (0, _relationships.hasMany)("package_set", { async: false }),
    packages: (0, _relationships.hasMany)("package", { async: false }),

    packagesAndSets: computed("packages.@each.allowWebPublish", "_packages.@each.packageType", "packages.@each.hasSiblingPackages", "packages.@each.isAvailable", function () {
      var packages = this.get("packages").filterBy("isAvailable");
      var records = [];

      if (packages.length) {
        var singlePackages = packages.rejectBy("hasSiblingPackages") || [];
        records = records.concat(singlePackages.toArray());

        var multiPackages = packages.filterBy("hasSiblingPackages") || [];
        records = records.concat(multiPackages.map(function (pkg) {
          return pkg.get("packageSet");
        }).uniq());
      }
      return records.uniq();
    }),

    _packages: computed("packages.[]", function () {
      return this.get("store").peekAll("package");
    }),

    _packageCategories: computed(function () {
      return this.store.peekAll("package_category");
    }),

    packageCategories: computed("code", "_packageCategories.[]", function () {
      var _this = this;

      return this.get("_packageCategories").filter(function (p) {
        return p.get("packageTypeCodes") && p.get("packageTypeCodes").indexOf(_this.get("code")) > -1;
      });
    }),

    allPackageCategories: computed("code", "_packageCategories.[]", function () {
      var categories = this.get("packageCategories").toArray();
      this.get("packageCategories").forEach(function (pkg) {
        var parentCategory = pkg.get("parentCategory");
        if (parentCategory) {
          categories = categories.concat(parentCategory);
        }
      });
      return categories.uniq();
    })
  });
});