define("browse/routes/post_login", ["exports", "browse/utils/ajax-promise"], function (exports, _ajaxPromise) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Route$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var resolve = Ember.RSVP.resolve;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend((_Route$extend = {
    cart: service(),
    messageBox: service(),
    session: service(),
    preloadService: service()
  }, _defineProperty(_Route$extend, "session", service()), _defineProperty(_Route$extend, "isBookAppointment", false), _defineProperty(_Route$extend, "beforeModel", function beforeModel(params) {
    this.set("isBookAppointment", params.queryParams.bookAppointment);
  }), _defineProperty(_Route$extend, "model", function model() {
    return this.get("preloadService").preloadData();
  }), _defineProperty(_Route$extend, "loadIfAbsent", function loadIfAbsent(modelName, id) {
    var cachedRecord = this.store.peekRecord(modelName, id);
    if (cachedRecord) {
      return resolve(cachedRecord);
    }
    return this.store.findRecord(modelName, id);
  }), _defineProperty(_Route$extend, "afterModel", function afterModel() {
    this.redirectToTransitionOrDetails();
    localStorage.removeItem("loginParam");
    localStorage.removeItem("loginParamEmail");
  }), _defineProperty(_Route$extend, "redirectToTransitionOrDetails", function redirectToTransitionOrDetails() {
    if (this.get("session").accountDetailsComplete()) {
      var attemptedTransition = this.controllerFor("login").get("attemptedTransition");
      var isBookAppointment = this.get("isBookAppointment");
      if (attemptedTransition) {
        attemptedTransition.retry();
        this.controllerFor("login").set("attemptedTransition", null);
      } else if (isBookAppointment === "true") {
        this.transitionTo("request_purpose");
      } else {
        this.transitionTo("browse");
      }
    } else {
      this.transitionTo("account_details");
    }
  }), _Route$extend));
});