define("browse/models/goodcity_request", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    quantity: (0, _attr.default)("string"),
    description: (0, _attr.default)("string"),
    itemSpecifics: (0, _attr.default)("string"),
    packageType: (0, _relationships.belongsTo)("package_type", { async: false }),
    orderId: (0, _attr.default)(),
    order: (0, _relationships.belongsTo)("order", { async: false }),
    code: (0, _attr.default)("number")
  });
});