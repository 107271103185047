define("browse/helpers/js-x", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.jsX = jsX;
  function jsX(params) {
    var paramNames = params.slice(1).map(function (val, idx) {
      return "p" + idx;
    });
    var func = Function.apply(this, paramNames.concat("return " + params[0] + ";"));
    return func.apply(params[1] === undefined ? this : params[1], params.slice(1));
  }

  exports.default = Ember.Helper.helper(jsX);
});