define('browse/routes/order/booking_success', ['exports', 'browse/routes/authorize'], function (exports, _authorize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    model: function model() {
      var orderId = this.paramsFor('order').order_id;
      return this.store.peekRecord('order', orderId) || this.store.findRecord('order', orderId);
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showSidebar', false);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('showSidebar', true);
    }
  });
});