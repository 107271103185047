define("browse/controllers/account_details", ["exports", "browse/utils/ajax-promise", "browse/config/environment"], function (exports, _ajaxPromise, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var getOwner = Ember.getOwner;
  exports.default = Controller.extend({
    showCancelBookingPopUp: false,
    queryParams: ["orgId", "bookAppointment", "onlineOrder"],

    authenticate: controller(),
    messageBox: service(),
    orderService: service(),
    i18n: service(),
    organisationId: alias("model.organisation.id"),
    organisationsUserId: alias("model.organisationsUser.id"),
    user: alias("model.user"),
    position: "",
    email: "",
    bookAppointment: false,
    onlineOrder: false,
    preferredContactNumber: "",
    mobilePhone: "",
    isMobileApp: _environment.default.cordova.enabled,
    userInfoError: "",
    inValidUserName: computed("user.firstName", "user.lastName", function () {
      return (this.get("user.firstName") || "").trim().length === 0 || (this.get("user.lastName") || "").trim().length === 0;
    }),

    userTitle: computed("model", function () {
      var userTitle = this.get("model.user.title");
      var titles = this.get("titles");

      if (userTitle) {
        var filteredUserTitle = titles.filter(function (title) {
          return userTitle === title.id;
        });
        return {
          name: filteredUserTitle[0].name.string,
          id: userTitle
        };
      }
      return {
        name: titles.get("firstObject.name").string,
        id: "Mr"
      };
    }),

    selectedTitle: computed("userTitle", function () {
      return {
        name: this.get("userTitle.name"),
        id: this.get("userTitle.id")
      };
    }),

    titles: computed(function () {
      var translation = this.get("i18n");
      var mr = translation.t("account.user_title.mr");
      var mrs = translation.t("account.user_title.mrs");
      var miss = translation.t("account.user_title.miss");
      var ms = translation.t("account.user_title.ms");

      return [{
        name: mr,
        id: "Mr"
      }, {
        name: mrs,
        id: "Mrs"
      }, {
        name: miss,
        id: "Miss"
      }, {
        name: ms,
        id: "Ms"
      }];
    }),

    redirectToTransitionOrBrowse: function redirectToTransitionOrBrowse(bookAppointment) {
      var onlineOrder = this.get("onlineOrder");
      var attemptedTransition = this.get("authenticate").get("attemptedTransition");
      if (bookAppointment) {
        this.transitionToRoute("request_purpose", {
          queryParams: {
            bookAppointment: true,
            prevPath: "account_details"
          }
        });
      } else if (onlineOrder) {
        this.transitionToRoute("submitted_order_selection");
      } else if (attemptedTransition) {
        this.set("attemptedTransition", null);
        attemptedTransition.retry();
      } else {
        this.transitionToRoute("browse");
      }
    },
    organisationsUserParams: function organisationsUserParams() {
      var organisationsUserId = this.get("organisationsUserId");
      var user = this.get("user");
      var position = organisationsUserId ? this.get("model.organisationsUser.position") : this.get("position");
      var preferredNumber = this.get("preferredContactNumber") || $("#preferred_contact").val();
      var title = this.get("selectedTitle.id");
      var params = {
        user_id: user.get("id"),
        organisation_id: this.get("organisationId"),
        position: position,
        preferred_contact_number: preferredNumber,
        user_attributes: {
          first_name: user.get("firstName").trim(),
          last_name: user.get("lastName").trim(),
          mobile: this.mobileParam(user),
          email: this.emailParam(user),
          title: title
        }
      };
      if (organisationsUserId) {
        params.id = organisationsUserId;
      }

      return params;
    },
    mobileParam: function mobileParam() {
      var mobile = this.get("user.mobile") || this.get("mobilePhone");

      if (!mobile) {
        return;
      }

      if (mobile.startsWith("+852")) {
        return mobile;
      } else {
        return "+852" + mobile;
      }
    },
    emailParam: function emailParam(user) {
      var email = user && user.get("email");
      return email && email.length ? email : this.get("email");
    },


    actions: {
      saveAccount: function saveAccount() {
        if (this.get("inValidUserName")) {
          return false;
        }

        var url = void 0,
            actionType = void 0;
        var organisationUserId = this.get("organisationsUserId");
        if (organisationUserId) {
          url = "/organisations_users/" + organisationUserId;
          actionType = "PUT";
        } else {
          url = "/organisations_users";
          actionType = "POST";
        }
        this.send("saveOrUpdateAccount", url, actionType);
      },
      saveOrUpdateAccount: function saveOrUpdateAccount(url, actionType) {
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        var bookAppointment = this.get("bookAppointment");

        new _ajaxPromise.default(url, actionType, this.get("session.authToken"), {
          organisations_user: this.organisationsUserParams()
        }).then(function (data) {
          _this.get("store").pushPayload(data);
          loadingView.destroy();
          _this.redirectToTransitionOrBrowse(bookAppointment);
        }).catch(function (xhr) {
          loadingView.destroy();
          _this.get("messageBox").alert(xhr.responseJSON.error);
        });
      },
      goToSearchOrg: function goToSearchOrg() {
        if (!this.get("organisationsUserId")) {
          this.transitionToRoute("search_organisation");
        }
      },
      validateUserInfo: function validateUserInfo() {
        if (this.get("inValidUserName")) {
          this.set("userInfoError", "user-info-error");
        } else {
          this.set("userInfoError", "");
        }
      }
    }
  });
});