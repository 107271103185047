define("browse/components/popup-overlay", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      //Fix: Too deeply nested component(3 levels) failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});