define("browse/controllers/orders/conversation", ["exports", "browse/config/environment", "browse/controllers/orders/detail"], function (exports, _environment, _detail) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = Ember.run.later;
  var $ = Ember.$;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var empty = Ember.computed.empty;
  var sort = Ember.computed.sort;
  var service = Ember.inject.service;
  exports.default = _detail.default.extend({
    subscription: service(),
    messagesUtil: service("messages"),
    isPrivate: false,
    order: alias("model"),
    isMobileApp: _environment.default.cordova.enabled,
    i18n: service(),
    sortProperties: ["createdAt: asc"],

    noMessage: empty("model.messages"),

    displayChatNote: computed("noMessage", "disabled", function () {
      return this.get("noMessage") && !this.get("disabled");
    }),

    sortedMessages: sort("model.messages", "sortProperties"),

    groupedMessages: computed("sortedMessages", function () {
      this.autoScroll();
      return this.groupBy(this.get("sortedMessages"), "createdDate");
    }),

    on: function on() {
      this.get("subscription").on("change:message", this, this.markReadAndScroll);
    },
    off: function off() {
      this.get("subscription").off("change:message", this, this.markReadAndScroll);
    },
    autoScroll: function autoScroll() {
      window.scrollTo(0, document.body.scrollHeight);
    },


    groupBy: function groupBy(content, key) {
      var result = [];
      var object, value;

      content.forEach(function (item) {
        value = item.get(key);
        object = result.findBy("value", value);
        if (!object) {
          object = {
            value: value,
            items: []
          };
          result.push(object);
        }
        return object.items.push(item);
      });
      return result.getEach("items");
    },

    createMessage: function createMessage(values) {
      var _this = this;

      if (values.body) {
        var message = this.store.createRecord("message", values);
        message.save().then(function () {
          _this.set("body", "");
        }).catch(function (error) {
          _this.store.unloadRecord(message);
          throw error;
        });
      }
    },


    markReadAndScroll: function markReadAndScroll(_ref) {
      var record = _ref.record;

      var message = this.store.peekRecord("message", record.id);
      if (!message || message.get("isRead") || message.get("designationId") != this.get("model.id")) {
        return;
      }

      this.get("messagesUtil").markRead(message);

      if (!$(".message-textbar").length) {
        return;
      }

      var scrollOffset = $(document).height();
      var screenHeight = document.documentElement.clientHeight;
      var pageHeight = document.documentElement.scrollHeight;

      if (pageHeight > screenHeight) {
        later(this, function () {
          window.scrollTo(0, scrollOffset);
        });
      }
    },

    actions: {
      sendMessage: function sendMessage() {
        $("textarea").trigger("blur");
        var values = this.getProperties("body");
        values.body = values.body.trim();
        values.order = this.get("model");
        values.body = values.body.trim();

        values.body = Ember.Handlebars.Utils.escapeExpression(values.body || "");
        values.body = values.body.replace(/(\r\n|\n|\r)/gm, "<br>");
        values.isPrivate = false;
        values.createdAt = new Date();
        values.messageableType = "Order";
        values.messageableId = this.get("model.id");
        values.sender = this.store.peekRecord("user", this.get("session.currentUser.id"));

        this.createMessage(values);

        // Animate and scroll to bottom
        this.autoScroll();
      },
      markRead: function markRead() {
        var _this2 = this;

        this.get("sortedMessages").filterBy("state", "unread").forEach(function (message) {
          _this2.get("messagesUtil").markRead(message);
        });
      }
    }
  });
});