define("browse/components/faq-questions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: "li",
    scrollDuration: 500,
    actions: {
      goToLink: function goToLink() {
        $("html, body").animate({
          scrollTop: $(this.id).offset().top
        }, this.get("scrollDuration"));
      }
    }
  });
});