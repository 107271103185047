define("browse/services/session", ["exports", "browse/services/api-base-service", "browse/computed/local-storage"], function (exports, _apiBaseService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var notEmpty = Ember.computed.notEmpty;
  var computed = Ember.computed;
  exports.default = _apiBaseService.default.extend({
    authToken: computed.localStorage(),
    otpAuthKey: computed.localStorage(),
    isLoggedIn: notEmpty("authToken"),
    language: computed.localStorage(),
    store: service(),
    deviceId: Math.random().toString().substring(2),

    loadUserProfile: function loadUserProfile() {
      var _this = this;

      return this.GET("/auth/current_user_profile").then(function (data) {
        _this.get("store").pushPayload(data);
        _this.get("store").pushPayload({ user: data.user_profile });
        _this.set("currentUserId", data.user_profile.id);
        return data;
      });
    },
    accountDetailsComplete: function accountDetailsComplete() {
      var user = this.get("currentUser");

      return user && user.get("isInfoComplete") && user.getWithDefault("defaultOrganisationsUser.isInfoComplete", false);
    },


    currentUserId: null,

    currentUser: computed("currentUserId", function () {
      if (!this.get("authToken") || !this.get("currentUserId")) {
        return null;
      }
      return this.get("store").peekRecord("user", this.get("currentUserId"));
    }),

    clear: function clear() {
      this.set("currentUserId", null);
      this.set("authToken", null);
      this.set("otpAuthKey", null);
    }
  });
});