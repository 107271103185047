define("browse/routes/package_category", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _browse_pages.default.extend({
    messageBox: service(),
    i18n: service(),

    model: function model(params) {
      return this.store.peekRecord("package_category", params["id"]);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (!model) {
        transition.abort();
        this.get("messageBox").alert(this.get("i18n").t("invalid_category_error"), function () {
          _this.transitionTo("browse");
        });
      }
    },
    setupController: function setupController(controller, model) {
      if (model) {
        controller.set("model", model);
        controller.set("category", model);
        this.controllerFor("application").set("pageTitle", model.get("name"));
      }
      controller.set("selectedSort", ["createdAt:desc"]);
    },
    resetController: function resetController(controller) {
      controller.set("selectedCategoryId", null);
    }
  });
});