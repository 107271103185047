define("browse/controllers/offers/detail", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    uid: Ember.computed.alias("model.public_uid"),
    offerDistrict: Ember.computed("model", "model.district_id", function () {
      var districtId = this.get("model.district_id");

      if (!districtId) {
        return "Hong Kong";
      }

      return this.get("store").peekRecord("district", districtId).getWithDefault("name", "Hong Kong");
    })
  });
});