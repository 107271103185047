define("browse/models/package", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "browse/mixins/cloudinary_image"], function (exports, _model, _attr, _relationships, _cloudinary_image) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool;
  var alias = Ember.computed.alias;
  var and = Ember.computed.and;
  var once = Ember.run.once;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  exports.default = _model.default.extend(_cloudinary_image.default, {
    onHandQuantity: (0, _attr.default)("number"),
    designatedQuantity: (0, _attr.default)("number"),
    dispatchedQuantity: (0, _attr.default)("number"),
    availableQuantity: (0, _attr.default)("number"),
    quantity: alias("availableQuantity"), // Temporary fallback, do not use
    maxOrderQuantity: (0, _attr.default)("number"),

    computedMaxOrderQuantity: computed("maxOrderQuantity", "availableQuantity", function () {
      var available = this.get("availableQuantity");
      var max = this.get("maxOrderQuantity");

      if (typeof max === "number" && max >= 0 && max < available) {
        return max;
      }
      return available;
    }),

    length: (0, _attr.default)("number"),
    width: (0, _attr.default)("number"),
    height: (0, _attr.default)("number"),
    notes: (0, _attr.default)("string"),
    notes_zh_tw: (0, _attr.default)("string"),
    inventoryNumber: (0, _attr.default)("string"),

    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    packageType: (0, _relationships.belongsTo)("package_type", { async: false }),
    imageIds: (0, _attr.default)(),
    images: (0, _relationships.hasMany)("image", { async: false }),
    donorCondition: (0, _relationships.belongsTo)("donor_condition", { async: false }),
    stockitSentOn: (0, _attr.default)("date"),
    orderId: (0, _attr.default)("number"),
    allowWebPublish: (0, _attr.default)("boolean"),
    packageSet: (0, _relationships.belongsTo)("packageSet", {
      async: false
    }),
    packageSetId: (0, _attr.default)("number"),
    ordersPackage: (0, _relationships.belongsTo)("orders_package", { async: false }),
    requestedPackage: (0, _relationships.belongsTo)("requested_package", { async: false }),
    isPartOfSet: bool("packageSetId"),

    sharingExpiresAt: (0, _attr.default)("date"),
    isShared: (0, _attr.default)("boolean"),
    offerId: (0, _attr.default)("number"),
    offer: (0, _relationships.belongsTo)("offer", { async: false }),

    //This is fix for live update for ticket GCW-1632(only implemented on singleton packages, nee to change for qty packages)
    updateAllowwebpublishQtyIfDesignated: observer("allowWebPublish", "availableQuantity", "orderId", function () {
      once(this, function () {
        if (this.get("orderId")) {
          this.set("allowWebPublish", false);
        }
      });
    }),

    isDispatched: bool("stockitSentOn"),

    isAvailable: computed("isDispatched", "allowWebPublish", function () {
      return Boolean(!this.get("isDispatched") && (this.get("allowWebPublish") || this._internalModel._data && this._internalModel._data.allowWebPublish) && this.get("availableQuantity"));
    }),

    isUnavailableAndDesignated: computed("isDispatched", "allowWebPublish", function () {
      return !this.get("isDispatched") && (!this.get("allowWebPublish") || this.get("orderId"));
    }),

    allPackageCategories: alias("packageType.allPackageCategories"),

    hasSiblingPackages: computed("isPartOfSet", "packageSet.packages.[]", function () {
      if (!this.get("isPartOfSet")) {
        return false;
      }

      return this.get("packageSet.packages.length") > 1;
    }),

    packageName: computed("packageType", function () {
      return this.get("packageType.name");
    }),

    orderedQuantity: computed.alias("ordersPackage.quantity"),

    packageTypeObject: computed("packageType", function () {
      var obj = this.get("packageType").getProperties("id", "name", "isItemTypeNode");
      obj.id = obj.packageTypeId = parseInt(obj.id, 10);
      return obj;
    }),

    dimensions: computed("width", "height", "length", function () {
      var res = "";
      var append = function append(val) {
        if (val) {
          res += !res ? val : " x " + val;
        }
      };
      append("W " + this.get("width"));
      append("H " + this.get("height"));
      append("L " + this.get("length"));
      return !res ? "" : res + " cm";
    }),

    packageDescription: computed("i18n.locale", "notes", "notes_zh_tw", function () {
      var lang = this.get("i18n.locale");
      var chineseDescription = (this.get("notes_zh_tw") || "").trim();

      if (lang === "zh-tw" && !!chineseDescription) {
        return chineseDescription;
      }
      return this.get("notes");
    }),

    isDimensionPresent: and("width", "height", "length"),

    image: computed("images.@each.favourite", function () {
      return this.get("images").filterBy("favourite").get("firstObject");
    }),

    favouriteImage: alias("image"),

    otherImages: computed("images.[]", function () {
      return this.get("images").toArray().removeObject(this.get("image"));
    }),

    sortedImages: computed("otherImages.[]", "image", function () {
      var images = this.get("otherImages").toArray();
      images.unshift(this.get("image"));
      return images;
    }),

    displayImage: alias("image"),

    displayImageUrl: computed("image", function () {
      return this.get("image.defaultImageUrl") || this.generateUrl(500, 500, true);
    }),

    previewImageUrl: computed("image", function () {
      return this.get("image.previewImageUrl") || this.generateUrl(265, 265, true);
    }),

    miniImageUrl: computed("image", function () {
      return this.get("image.miniImageUrl") || this.generateUrl(30, 30, true);
    })
  });
});