define("browse/models/orders_package", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    quantity: (0, _attr.default)("number"),
    state: (0, _attr.default)("string"),
    package: (0, _relationships.belongsTo)("package", { async: false }),
    order: (0, _relationships.belongsTo)("order", { async: false }),
    orderId: (0, _attr.default)("number"),
    packageId: (0, _attr.default)("number"),
    createdAt: (0, _attr.default)("date"),
    isSingleQuantity: computed("quantity", function () {
      return this.get("quantity") === 1;
    })
  });
});