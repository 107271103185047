define("browse/services/screenresize", ["exports", "browse/computed/local-storage"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Service = Ember.Service;


  var MEDIA_QUERIES = {
    SMALL: Foundation.media_queries.small,
    MEDIUM: Foundation.media_queries.medium,
    LARGE: Foundation.media_queries.large
  };

  exports.default = Service.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      window.addEventListener("resize", function () {
        _this.notifyPropertyChange("isSmallScreen");
        _this.notifyPropertyChange("isMediumScreen");
      });
    },


    isSmallScreen: computed(function () {
      return matchMedia(MEDIA_QUERIES.SMALL).matches && !matchMedia(MEDIA_QUERIES.MEDIUM).matches;
    }),

    isMediumScreen: computed(function () {
      return matchMedia(MEDIA_QUERIES.SMALL).matches && matchMedia(MEDIA_QUERIES.MEDIUM).matches && !matchMedia(MEDIA_QUERIES.LARGE).matches;
    }),

    isWideScreen: computed(function () {
      return matchMedia(MEDIA_QUERIES.MEDIUM).matches || matchMedia(MEDIA_QUERIES.LARGE).matches;
    })
  });
});