define("browse/models/order_transport", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  exports.default = _model.default.extend({
    timeslot: (0, _attr.default)("string"),
    transportType: (0, _attr.default)("string"),
    vehicleType: (0, _attr.default)("string"),
    scheduledAt: (0, _attr.default)("date"),
    orderId: (0, _attr.default)("number"),

    contact: (0, _relationships.belongsTo)("contact", { async: false }),
    order: (0, _relationships.belongsTo)("order", { async: false }),
    gogovanTransport: (0, _relationships.belongsTo)("gogovan_transport", { async: false }),

    isGGV: equal("transportType", "ggv"),

    needEnglish: (0, _attr.default)("boolean"),
    needCart: (0, _attr.default)("boolean"),
    needCarry: (0, _attr.default)("boolean"),
    needOverSixFt: (0, _attr.default)("boolean"),
    removeNet: (0, _attr.default)("string"),

    scheduledDate: computed("scheduledAt", function () {
      return moment(this.get("scheduledAt")).format("ddd LL");
    }),

    isMorning: computed("scheduledAt", function () {
      return moment.tz(this.get("scheduledAt"), "Asia/Hong_Kong").hour() < 12;
    }),

    isAfternoon: not("isMorning"),

    type: computed("transportType", function () {
      var type = this.get("transportType");
      if (type === "ggv") {
        return type.toUpperCase();
      } else if (type === "self") {
        return type.charAt(0).toUpperCase() + type.slice(1);
      } else {
        return "";
      }
    }),

    isDelivery: computed("transportType", function () {
      return this.get("transportType") === "ggv";
    }),

    isAppointment: computed("bookingType", function () {
      var bookingType = this.get("bookingType");
      if (!bookingType) {
        // Orders created before appointments were supported may not have bookingTypes
        return false;
      }
      return bookingType.get("isAppointment");
    })
  });
});