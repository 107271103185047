define("browse/utils/ajax-promise", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultHeaders = {
    "X-GOODCITY-APP-NAME": _environment.default.APP.NAME,
    "X-GOODCITY-APP-VERSION": _environment.default.APP.VERSION,
    "X-GOODCITY-APP-SHA": _environment.default.APP.SHA
  };

  function _read(data) {
    if (typeof data == "function") {
      return data();
    }
    return data;
  }

  function getServerPath(namespace) {
    var host = _environment.default.APP.API_HOST_URL + "/";
    return Number(namespace) == 2 ? "" + host + _environment.default.APP.NAMESPACE_V2 : "" + host + _environment.default.APP.NAMESPACE;
  }
  function AjaxPromise(url, type, authToken, data, args) {
    var language = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "en";

    var _ref = args ? args : "",
        version = _ref.version;

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var headers = Ember.$.extend({}, _read(defaultHeaders), {
        "Accept-Language": language
      });

      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }

      Ember.$.ajax(Ember.$.extend({}, {
        type: type,
        dataType: "json",
        data: data,
        language: language,
        url: url.indexOf("http") === -1 ? getServerPath(version) + url : url,
        headers: headers,
        success: function success(data) {
          Ember.run(function () {
            resolve(data);
          });
        },
        error: function error(jqXHR) {
          jqXHR.url = url;
          Ember.run(function () {
            reject(jqXHR);
          });
        }
      }, args));
    });
  }

  AjaxPromise.setDefaultHeaders = function (headers) {
    defaultHeaders = headers;
  };

  exports.default = AjaxPromise;
});