define("browse/models/beneficiary", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    firstName: (0, _attr.default)("string"),
    lastName: (0, _attr.default)("string"),
    identityNumber: (0, _attr.default)("string"),
    title: (0, _attr.default)("string"),
    phoneNumber: (0, _attr.default)("string"),
    identityTypeId: (0, _attr.default)("number"),
    identityType: (0, _relationships.belongsTo)("identityType", { async: false }),

    fullName: computed("firstName", "lastName", function () {
      return this.get("title") + " " + this.get("firstName") + " " + this.get("lastName");
    })
  });
});