define("browse/models/donor_condition", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    items: (0, _relationships.hasMany)("item", { async: false }),

    conditionName: computed("name", function () {
      return this.get("name");
    })
  });
});