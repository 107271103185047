define("browse/controllers/faq", ["exports", "ember-i18n"], function (exports, _emberI18n) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    i18n: service(),
    questionLinks: computed("", function () {
      var i18n = this.get("i18n");
      var questions = [];
      [].concat(_toConsumableArray(Array(7))).map(function (i, index) {
        questions.push({
          id: "#question" + (index + 1),
          text: i18n.t("faq.questions.question" + (index + 1))
        });
      });
      return questions;
    }),
    isEnglish: computed("i18n.locale", function () {
      var lang = this.get("i18n.locale");
      return (/zh/.test(lang) ? false : true
      );
    })
  });
});