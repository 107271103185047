define("browse/components/paginated-items", ["exports", "ember-cli-pagination/computed/paged-array"], function (exports, _pagedArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    categoryId: null,
    sortBy: null,

    pagedContent: (0, _pagedArray.default)("content", { perPageBinding: "perPage" }),
    pageBinding: "pagedContent.page",
    totalPagesBinding: "pagedContent.totalPages",

    showPaginationBar: computed("pagedContent.totalPages", function () {
      return this.get("pagedContent.totalPages") > 1;
    })
  });
});