define("browse/components/observe-screen-resize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    screenresize: service(),

    isSmallScreen: alias("screenresize.isSmallScreen"),
    isMediumScreen: alias("screenresize.isMediumScreen"),

    onScreenResized: function onScreenResized() {
      throw "Method not implemented";
    },


    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.__updateScreen = function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.onScreenResized();
      };
      window.addEventListener("resize", this.__updateScreen);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener("resize", this.__updateScreen);
    }
  });
});