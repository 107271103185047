define("browse/services/subscription", ["exports", "lodash", "browse/config/environment"], function (exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var $ = Ember.$;
  var bind = Ember.run.bind;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var alias = Ember.computed.alias;
  var Evented = Ember.Evented;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  function run(func) {
    if (func) {
      func();
    }
  }

  var ALL_OPERATIONS = ["create", "update", "delete"];

  var UPDATE_STRATEGY = {
    RELOAD: function RELOAD(store, type, record) {
      return store.findRecord(type, record.id, { reload: true });
    },
    MERGE: function MERGE(store, type, record) {
      store.pushPayload(_defineProperty({}, type, record));
    },
    SAVE_SENDER: function SAVE_SENDER(store, type, record, sender) {
      store.pushPayload(sender);
    }
  };

  /**
   * Subscription service
   *
   */
  exports.default = Service.extend(Evented, {
    messagesUtil: service("messages"),
    session: service(),
    logger: service(),
    store: service(),
    socket: null,
    lastOnline: Date.now(),
    deviceTtl: 0,
    hooks: { before: {}, after: {} },
    deviceId: alias("session.deviceId"),
    isSubscribed: false,
    status: {
      online: false
    },

    connectUrl: computed("session.authToken", "deviceId", function () {
      return _environment.default.APP.SOCKETIO_WEBSERVICE_URL + ("?token=" + encodeURIComponent(this.get("session.authToken"))) + ("&deviceId=" + this.get("deviceId")) + ("&meta=appName:" + _environment.default.APP.NAME);
    }),

    // -----------
    // Config
    // -----------
    unhandledTypes: [
    // those types will be ignored
    "offer"],
    importStrategies: {
      // define how we handle incoming changes
      package: {
        strategy: UPDATE_STRATEGY.RELOAD
      },
      message: {
        strategy: [UPDATE_STRATEGY.MERGE, UPDATE_STRATEGY.SAVE_SENDER]
      },
      defaults: {
        operations: ALL_OPERATIONS,
        strategy: UPDATE_STRATEGY.MERGE
      }
    },
    internalTypeMapping: {
      // type renaming
      designation: "order"
    },

    // -----------
    // Watch
    // -----------
    updateStatus: observer("socket", function () {
      var socket = this.get("socket");
      var online = navigator.connection ? navigator.connection.type !== "none" : navigator.onLine;
      online = socket && socket.connected && online;
      this.set("status", { online: online });
    }),

    // resync if offline longer than deviceTtl
    checkdeviceTTL: observer("status.online", function () {
      var online = this.get("status.online");
      var deviceTtl = this.get("deviceTtl");

      if (!online) {
        this.set("lastOnline", Date.now());
        return;
      }

      var overtime = Date.now() - this.get("lastOnline") > deviceTtl * 1000;
      if (deviceTtl && overtime) {
        this.resync();
      }
    }),

    // -----------
    // Setup
    // -----------

    init: function init() {
      var updateStatus = bind(this, this.updateStatus);
      window.addEventListener("online", updateStatus);
      window.addEventListener("offline", updateStatus);
    },
    wire: function wire() {
      if (this.isSubscribed) {
        return;
      }

      this.isSubscribed = true;
      var updateStatus = bind(this, this.updateStatus);
      var socket = io(this.get("connectUrl"), {
        autoConnect: false,
        forceNew: true
      });

      this.set("socket", socket);
      socket.on("connect", function () {
        updateStatus();
        socket.io.engine.on("upgrade", updateStatus);
      });
      socket.on("notification", bind(this, this.notification));
      socket.on("disconnect", updateStatus);
      socket.on("error", bind(this, this.error));
      socket.on("update_store", bind(this, this.update_store));
      socket.on("_batch", bind(this, this.batch));
      socket.on("_resync", bind(this, this.resync));
      socket.on("_settings", bind(this, function (settings) {
        this.set("deviceTtl", settings.device_ttl);
        this.set("lastOnline", Date.now());
      }));
      socket.connect(); // manually connect since it's not auto-connecting if you logout and then back in
    },
    unwire: function unwire() {
      this.isSubscribed = false;
      var socket = this.get("socket");
      if (socket) {
        socket.close();
        this.set("socket", null);
      }
    },
    before: function before(operation, type, func) {
      var key = operation + ":" + type;
      this.hooks.before[key] = this.hooks.before[key] || [];
      this.hooks.before[key].push(func);
    },
    runBeforeHooks: function runBeforeHooks(operation, type, data) {
      var key = operation + ":" + type;
      var hooks = this.hooks.before[key] || [];
      _lodash.default.each(hooks, function (h) {
        return h(data);
      });
    },


    // -----------
    // Helpers
    // -----------

    getStrategy: function getStrategy(type) {
      var defaultStrat = this.importStrategies.defaults;
      var typeStrat = this.importStrategies[type] || {};
      return _lodash.default.extend({}, defaultStrat, typeStrat);
    },
    operationIsAllowed: function operationIsAllowed(operation, type) {
      var _getStrategy = this.getStrategy(type),
          operations = _getStrategy.operations;

      return operations.indexOf(operation) >= 0;
    },
    resolveTypeAliases: function resolveTypeAliases(type) {
      var typeMapping = this.get("internalTypeMapping");
      return typeMapping[type] || type;
    },
    parseData: function parseData(data) {
      var payload = data.item,
          operation = data.operation,
          sender = data.sender,
          deviceId = data.device_id;

      var rawType = Object.keys(payload)[0].toLowerCase();
      var type = this.resolveTypeAliases(rawType);
      var record = $.extend({}, payload[rawType]);
      return { payload: payload, record: record, operation: operation, type: type, rawType: rawType, sender: sender, deviceId: deviceId };
    },
    isUnhandled: function isUnhandled(data) {
      var operation = data.operation,
          deviceId = data.deviceId,
          rawType = data.rawType,
          type = data.type;


      if (deviceId == this.get("deviceId")) {
        return true; // Change initiated by us
      }

      if (this.get("unhandledTypes").indexOf(rawType) >= 0) {
        console.warn("[Subscription] Unhandled data type '" + rawType + "'");
        return true;
      }

      if (!this.operationIsAllowed(operation, type)) {
        console.warn("[Subscription] Ignoring a '" + operation + "' operation for type '" + type + "'");
        return true;
      }
      return false;
    },
    applyUpdateStrategy: function applyUpdateStrategy(record, type, sender) {
      var store = this.get("store");

      var _getStrategy2 = this.getStrategy(type),
          strategy = _getStrategy2.strategy;

      var tasks = _lodash.default.flatten([strategy]);
      var promises = tasks.map(function (fn) {
        return fn(store, type, record, sender);
      });
      return all(promises);
    },


    // -----------
    // Socket hooks
    // -----------

    notification: function notification(data, success) {
      this.trigger("notification", data);
      run(success);
    },

    error: function error(reason) {
      if ((typeof reason === "undefined" ? "undefined" : _typeof(reason)) !== "object" || reason.type !== "TransportError" && reason.message !== "xhr post error") {
        console.warn("[Subscription] Socker error", reason);
      }
    },


    batch: function batch(events, success) {
      events.forEach(function (args) {
        var event = args[0];
        if (this[event]) {
          this[event].apply(this, args.slice(1));
        }
      }, this);
      run(success);
    },

    resync: function resync() {
      this.get("store").findAll("package");
    },

    update_store: function update_store(data, success) {
      var parsedData = this.parseData(data);
      if (this.isUnhandled(parsedData)) {
        return false;
      }

      var operation = parsedData.operation,
          type = parsedData.type;


      this.runBeforeHooks(operation, type, parsedData);
      this.applyChanges(parsedData, success);
    },
    applyChanges: function applyChanges(data, success) {
      var _this = this;

      var record = data.record,
          operation = data.operation,
          type = data.type,
          sender = data.sender;

      var task = resolve(true);

      switch (operation) {
        case "create":
        case "update":
          task = this.applyUpdateStrategy(record, type, sender);
          break;
        case "delete":
          var existingItem = this.get("store").peekRecord(type, record.id);
          if (existingItem) {
            this.get("store").unloadRecord(existingItem);
          }
          break;
        default:
          console.error("[Subscription] Unsupported operation '" + operation + "'");
          return false;
      }

      task.then(function () {
        _this.trigger("change:" + type, { type: type, operation: operation, record: record });
        run(success);
      }).catch(function (e) {
        return _this.get("logger").error(e);
      });
      return true;
    }
  });
});