define("browse/services/flash-message", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var $ = Ember.$;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    i18n: service(),

    //Need to pass message to show
    show: function show(message) {
      var element = $("#flash_message").clone().text(this.get("i18n").t(message));
      $(".flash_message_block").addClass("visible");
      element.prependTo(".flash_message_block");
      debounce(this, this.hideFlashMessage, 500);
    },
    hideFlashMessage: function hideFlashMessage() {
      $(".flash_message_block").fadeOut(3000);
      debounce(this, this.removeFlashMessage, 2500);
    },
    removeFlashMessage: function removeFlashMessage() {
      $(".flash_message_block").empty();
      $(".flash_message_block").addClass("visible");
    }
  });
});