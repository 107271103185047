define("browse/app", ["exports", "browse/resolver", "ember-load-initializers", "browse/config/environment"], function (exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Application = Ember.Application;


  var App = void 0;

  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});