define("browse/routes/static_pages", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    backLink: null,

    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var applicationController = this.controllerFor("application");
      applicationController.set("hideHeaderBar", true);
      applicationController.set("pageTitle", this.get("i18n").t(transition.targetName + ".title"));
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        var applicationController = this.controllerFor("application");
        applicationController.set("hideHeaderBar", false);
        applicationController.set("pageTitle", this.get("i18n").t("browse.title"));
      }
    }
  });
});