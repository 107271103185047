define("browse/controllers/my_orders", ["exports", "browse/controllers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var all = Ember.RSVP.all;
  var reject = Ember.RSVP.reject;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var controller = Ember.inject.controller;
  var service = Ember.inject.service;
  var sort = Ember.computed.sort;
  var alias = Ember.computed.alias;
  exports.default = _application.default.extend({
    sortProperties: ["createdAt:desc"],
    arrangedOrders: sort("model.orders", "sortProperties"),
    orders: alias("model"),
    flashMessage: service(),
    messageBox: service(),
    queryParams: ["submitted"],
    triggerFlashMessage: false,
    showCancelBookingPopUp: false,
    cancellationReasonWarning: false,
    applicationController: controller("application"),
    isMyOrdersRoute: computed("applicationController.currentPath", function () {
      return this.get("applicationController.currentPath") === "my_orders";
    }),

    fetchPackageImages: function fetchPackageImages(pkg) {
      var _this = this;

      return all(pkg.getWithDefault("imageIds", []).map(function (id) {
        return _this.store.findRecord("image", id, { reload: false });
      }));
    },
    fetchMissingImages: function fetchMissingImages(order) {
      var _this2 = this;

      var ordersPackages = order.getWithDefault("ordersPackages", []);
      return all(ordersPackages.map(function (op) {
        return _this2.fetchPackageImages(op.get("package"));
      }));
    },


    submitted: false,

    submittedOrderFlashMessage: observer("submitted", "triggerFlashMessage", function () {
      if (this.get("submitted")) {
        this.get("flashMessage").show("order.flash_submit_message");
      }
    }),

    preloadOrder: function preloadOrder(order) {
      var _this3 = this;

      this.startLoading();
      return this.get("store").findRecord("order", order.get("id"), { reload: true }).catch(function (e) {
        _this3.stopLoading();
        return reject(e);
      }).then(function (res) {
        _this3.stopLoading();
        return res;
      });
    },


    actions: {
      viewOrder: function viewOrder(order) {
        var _this4 = this;

        var id = order.get("id");
        this.preloadOrder(order).then(function () {
          _this4.transitionToRoute("orders.detail", id);
        });
      },
      editOrder: function editOrder(order) {
        var _this5 = this;

        var id = order.get("id");
        this.preloadOrder(order).then(function () {
          _this5.transitionToRoute("request_purpose", {
            queryParams: {
              bookAppointment: _this5.get("isAppointmentDraft"),
              orderId: id,
              prevPath: "my_orders"
            }
          });
        });
      }
    }
  });
});