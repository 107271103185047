define("browse/routes/offers/index", ["exports", "browse/routes/browse_pages", "browse/mixins/cloudinary_image", "lodash"], function (exports, _browse_pages, _cloudinary_image, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var service = Ember.inject.service;
  exports.default = _browse_pages.default.extend(_cloudinary_image.default, {
    offerService: service(),

    normalizeResponse: function normalizeResponse(response) {
      var _this = this;

      var normalizedResponse = _lodash.default.reduce(response.data, function (results, offer) {
        var offerObj = _extends({}, offer.attributes);
        var items = _lodash.default.filter(response.included, function (pkg) {
          return pkg.attributes.offer_id == offer.id;
        });
        items.map(function (item) {
          var cloudinaryImage = "";
          item.images = _lodash.default.filter(response.included, function (image) {
            return image.attributes.imageable_id == item.id;
          });
          var favouriteImage = item.images.find(function (e) {
            return e.id == item.attributes.favourite_image_id;
          }) || item.images[0];
          cloudinaryImage = favouriteImage && favouriteImage.attributes.cloudinary_id;
          _this.set("cloudinaryId", cloudinaryImage);
          item.previewUrl = _this.generateUrl(500, 500, true);
        });
        offerObj.items = items;
        results.push(offerObj);
        return results;
      }, []);
      return normalizedResponse;
    },
    model: function model() {
      var _this2 = this;

      return this.get("offerService").getAllOffers().then(function (data) {
        return _this2.normalizeResponse(data);
      });
    }
  });
});