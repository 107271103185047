define("browse/computed/local-storage", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isNone = Ember.isNone;
  var $ = Ember.$;


  var storageSupported = false;
  try {
    localStorage.test = 2;
    delete localStorage.test;
    storageSupported = true;
  } catch (err) {
    console.log(err);
  }

  var cookiesSupported = false;
  try {
    $.cookie("test", 2);
    $.removeCookie("test");
    cookiesSupported = true;
  } catch (err) {
    console.log(err);
  }

  var localStorageProvider = {
    get: function get(key) {
      return JSON.parse(localStorage[key] || null);
    },
    set: function set(key, value) {
      if (isNone(value)) {
        delete localStorage[key];
      } else {
        localStorage[key] = JSON.stringify(value);
      }
      return value;
    }
  };

  var cookieStorageProvider = {
    get: function get(key) {
      return $.cookie(key);
    },
    set: function set(key, value) {
      $.cookie.json = true;
      if (isNone(value)) {
        $.removeCookie(key);
      } else {
        $.cookie(key, value, {
          expires: 365,
          path: "/",
          secure: _environment.default.environment === "production"
        });
      }
      return value;
    }
  };

  var memoryStorageProvider = {
    get: function get(key) {
      if (!window.goodcityStorage) {
        window.goodcityStorage = {};
      }
      return window.goodcityStorage[key];
    },
    set: function set(key, value) {
      if (!window.goodcityStorage) {
        window.goodcityStorage = {};
      }
      if (isNone(value)) {
        delete window.goodcityStorage[key];
      } else {
        window.goodcityStorage[key] = value;
      }
      return value;
    }
  };

  exports.default = computed.localStorage = function () {
    if (storageSupported) {
      return computed(localStorageProvider);
    }

    if (cookiesSupported) {
      return computed(cookieStorageProvider);
    }

    return computed(memoryStorageProvider);
  };
});