define("browse/instance-initializers/ajax", ["exports", "browse/utils/ajax-promise"], function (exports, _ajaxPromise) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: "ajax",
    initialize: function initialize(app) {
      var _app$container = app.container,
          container = _app$container === undefined ? app : _app$container;

      var adapter = container.lookup("adapter:application");

      _ajaxPromise.default.setDefaultHeaders(function () {
        return adapter.get("headers");
      });
    }
  };
});