define("browse/adapters/application", ["exports", "browse/config/environment", "active-model-adapter"], function (exports, _environment, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _activeModelAdapter.default.extend({
    namespace: _environment.default.APP.NAMESPACE,
    host: _environment.default.APP.API_HOST_URL,
    session: service(),

    headers: computed("session.authToken", function () {
      return {
        Authorization: "Bearer " + this.get("session.authToken"),
        "Accept-Language": this.get("session.language"),
        "X-GOODCITY-APP-NAME": _environment.default.APP.NAME,
        "X-GOODCITY-APP-VERSION": _environment.default.APP.VERSION,
        "X-GOODCITY-APP-SHA": _environment.default.APP.SHA,
        "X-GOODCITY-DEVICE-ID": this.get("session.deviceId")
      };
    }),

    urlForQuery: function urlForQuery(query, modelName, snapshot) {
      var _config$APP = _environment.default.APP,
          NAMESPACE = _config$APP.NAMESPACE,
          API_HOST_URL = _config$APP.API_HOST_URL;

      var url = query.url;
      if (url) {
        return API_HOST_URL + "/" + NAMESPACE + "/" + url;
      }
      return this._super.apply(this, arguments);
    }
  });
});