define('browse/components/infinite-list', ['exports', 'ember-infinite-list/components/infinite-list'], function (exports, _infiniteList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _infiniteList.default;
    }
  });
});