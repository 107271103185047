define("browse/components/item-thumbnail-view", ["exports", "browse/components/image-preview", "browse/mixins/cloudinary_image", "browse/mixins/safe_get", "lodash"], function (exports, _imagePreview, _cloudinary_image, _safe_get, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _imagePreview.default.extend(_cloudinary_image.default, _safe_get.default, {
    store: service(),

    initializeLightgallery: function initializeLightgallery() {
      var gallery = $("#lightGallery" + this.get("item.id")).data("lightGallery");
      if (gallery) {
        gallery.destroy();
      }

      var lightGalleryObj = $("#lightGallery" + this.get("item.id")).lightGallery({
        mode: "lg-slide",
        zoom: true,
        download: false,
        scale: 1,
        hideControlOnEnd: true,
        closable: true,
        loop: true,
        counter: true,
        enableTouch: true,
        enableDrag: true,
        selector: ".imageZoom"
      });
      this.set("lightGalleryObj", lightGalleryObj);
    },


    packageType: computed("item", function () {
      return this.safeGet("package_type", this.get("item").attributes.package_type_id, "name");
    }),

    description: computed("item.id", "i18n.locale", "item.attributes.notes_zh_tw", "item.attributes.notes", function () {
      var lang = this.get("i18n.locale");
      var chineseDescription = (this.get("item").attributes.notes_zh_tw || "").trim();

      if (lang === "zh-tw" && !!chineseDescription) {
        return chineseDescription;
      }

      return this.get("item").attributes.notes;
    }),

    condition: computed("item.id", "item.attributes.donor_condition_id", function () {
      return this.safeGet("donor_condition", this.get("item").attributes.donor_condition_id, "name");
    }),

    dimension: computed("item", "item.id", function () {
      var length = this.get("item").attributes.length;
      var width = this.get("item").attributes.width;
      var height = this.get("item").attributes.height;

      if (!!length && !!width && !!height) {
        return "L " + length + " X W " + width + " X H " + height + " cm";
      } else {
        return false;
      }
    }),

    itemImages: computed("item", function () {
      var _this = this;

      return _lodash.default.reduce(this.get("item").images, function (results, item) {
        var cloudinaryId = item.attributes.cloudinary_id || "";
        _this.set("cloudinaryId", cloudinaryId);
        var url = _this.get("noResize") ? _this.generateUrl() : _this.generateUrl(500, 500, true);
        _lodash.default.set(item, "imageUrl", url);
        results.push(item);
        return results;
      }, []);
    })
  });
});