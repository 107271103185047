define('browse/initializers/offline', ['exports', 'browse/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    // apply config to global Offline
    window.Offline.options = _environment.default.emberOffline;

    application.register('offline:main', window.Offline, { instantiate: false });
    application.inject('service:offline', 'offline', 'offline:main');
  }

  exports.default = {
    name: 'offline',
    initialize: initialize
  };
});