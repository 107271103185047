define("browse/routes/package", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _browse_pages.default.extend({
    model: function model(params) {
      return this.store.peekRecord("package", params["id"]);
    },
    renderTemplate: function renderTemplate() {
      this.render("package_set", { controller: "package" });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model) {
        controller.set("model", model);
        controller.set("prevPath", this.router.currentPath);
        controller.set("requestedQty", 1);
        controller.set("previewUrl", model.get("previewImageUrl"));
      }
      this.controllerFor("application").set("pageTitle", this.get("i18n").t("itemdetail.view"));
    }
  });
});