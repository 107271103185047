define("browse/controllers/browse", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  exports.default = Controller.extend({
    isMobileApp: _environment.default.cordova.enabled,
    packageCategory: controller(),
    showCartDetailSidebar: false,
    packageCategoryReloaded: false,
    flashMessage: service(),
    subscription: service(),
    settings: service(),
    queryParams: ["orderCancelled"],
    triggerFlashMessage: false,
    origin_url: _environment.default.APP.ORIGIN,

    orderCancelled: false,

    freeDeliveryEnabled: computed(function () {
      return this.get("settings").readBoolean("browse.free_delivery_enabled");
    }),

    freeDeliveryPackageId: computed(function () {
      return this.get("settings").readString("browse.free_delivery_package_id");
    }),

    freeDeliveryQuantityAvailable: computed(function () {
      var packageId = this.get("freeDeliveryPackageId");
      var pkg = this.store.peekRecord("package", packageId);
      return pkg && pkg.get("availableQuantity") > 0;
    }),

    on: function on() {
      this.get("subscription").on("change:package", this, this.onPackageChange);
    },
    off: function off() {
      this.get("subscription").off("change:package", this, this.onPackageChange);
    },
    onPackageChange: function onPackageChange() {
      this.toggleProperty("packageCategoryReloaded");
    },


    cancelOrderFlashMessage: observer("orderCancelled", "triggerFlashMessage", function () {
      if (this.get("orderCancelled")) {
        this.get("flashMessage").show("order.flash_cancelled_message");
      }
    }),

    parentCategories: computed("model.[]", "packageCategoryReloaded", function () {
      var model = this.get("model");
      model.forEach(function (packageCategory) {
        packageCategory.toggleProperty("reloadPackageCategory");
      });
      return this.get("model").filterBy("parentId", null);
    }),
    actions: {
      setChildCategory: function setChildCategory(childCategory) {
        var parentId = childCategory.get("parentId");
        this.transitionToRoute("package_category", parentId);
        this.get("packageCategory").set("selectedCategoryId", childCategory);
      }
    }
  });
});