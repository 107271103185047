define("browse/services/logger", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    rollbar: Ember.inject.service(),

    notifyErrorCollector: function notifyErrorCollector(reason) {
      var currentUser = this.get("session.currentUser");
      var userName = currentUser.get("fullName");
      var userId = currentUser.get("id");
      var error = reason instanceof Error || (typeof reason === "undefined" ? "undefined" : _typeof(reason)) !== "object" ? reason : JSON.stringify(reason);
      var environment = _environment.default.environment;
      this.set("rollbar.currentUser", currentUser);
      this.get("rollbar").error(error, {
        id: userId,
        username: userName,
        environment: environment
      });
    },
    error: function error(reason) {
      if (reason.status === 0) return;
      console.info(reason);
      this.notifyErrorCollector(reason);
    }
  });
});