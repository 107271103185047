define("browse/components/app-canvas", ["exports", "browse/components/observe-screen-resize"], function (exports, _observeScreenResize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _observeScreenResize.default.extend({
    cartscroll: service(),
    application: computed(function () {
      return getOwner(this).lookup("controller:application");
    }),

    isHomePage: computed(function () {
      return this.get("application.isHomePage");
    }).volatile(),

    onScreenResized: function onScreenResized() {
      if (this.get("isSmallScreen")) {
        $(".left-off-canvas-toggle").show();
        this.closeSideBars();
        this.applySmallScreenSettings();
      } else {
        $(".left-off-canvas-toggle").hide();
        if (this.get("application.showOffCanvas")) {
          this.showSideBar();
        }
        this.applyDesktopScreenSettings();
      }
      this.get("cartscroll").resize();
    },
    closeSideBars: function closeSideBars() {
      $(".off-canvas-wrap").removeClass("move-right").removeClass("move-left");
    },
    showSideBar: function showSideBar() {
      $(".off-canvas-wrap").addClass("move-right");
    },


    applySmallScreenSettings: function applySmallScreenSettings() {
      $(document).foundation({ offcanvas: { close_on_click: true } });
    },

    applyDesktopScreenSettings: function applyDesktopScreenSettings() {
      $(document).foundation({ offcanvas: { close_on_click: false } });
    },

    didInsertElement: function didInsertElement() {
      this.onScreenResized();
    }
  });
});