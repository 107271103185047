define("browse/routes/order/goods_details", ["exports", "browse/routes/authorize", "browse/utils/ajax-promise"], function (exports, _authorize, _ajaxPromise) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _authorize.default.extend({
    backLinkPath: computed.localStorage(),
    orderId: null,
    queryParams: {
      fromClientInformation: false
    },

    model: function model() {
      this._super.apply(this, arguments);
      var orderId = this.paramsFor("order").order_id;
      var goodcityRequestParams = {};
      goodcityRequestParams["quantity"] = 1;
      goodcityRequestParams["order_id"] = orderId;
      this.set("orderId", orderId);

      return this.loadIfAbsent("order", orderId);
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      if (this.get("backLinkPath") !== null) {
        controller.set("backLinkPath", this.get("backLinkPath"));
      } else {
        controller.set("backLinkPath", "order.client_information");
      }
      this.controllerFor("application").set("showSidebar", false);
      this.setupGoods(controller, model);
    },
    setupGoods: function setupGoods(controller, model) {
      var goodcityRequests = [];
      if (!model.get("goodcityRequests").length) {
        goodcityRequests.push({
          description: null,
          quantity: 1,
          packageType: null
        });
      } else {
        model.get("goodcityRequests").map(function (gr) {
          goodcityRequests.push({
            id: gr.get("id"),
            description: gr.get("description"),
            quantity: gr.get("quantity"),
            packageType: gr.get("packageType")
          });
        });
      }
      controller.set("goodcityRequests", goodcityRequests);
    },
    deactivate: function deactivate() {
      this.controllerFor("application").set("showSidebar", true);
    }
  });
});