define("browse/components/loading", ["exports", "browse/templates/loading"], function (exports, _loading) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _loading.default,
    classNames: ["loading-indicator"]
  });
});