define("browse/models/order", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = _model.default.extend({
    store: service(),
    code: (0, _attr.default)("string"),
    state: (0, _attr.default)("string"),
    purposeDescription: (0, _attr.default)("string"),
    orderType: (0, _attr.default)("string"),
    cancellationReason: (0, _relationships.belongsTo)("cancellation_reason", { async: false }),
    ordersPackages: (0, _relationships.hasMany)("orders_packages", {
      async: false
    }),
    orderTransportId: (0, _attr.default)("string"),
    orderTransport: (0, _relationships.belongsTo)("order_transport", {
      async: false
    }),
    addressId: (0, _attr.default)("number"),
    address: (0, _relationships.belongsTo)("address", {
      async: false
    }),
    organisation: (0, _relationships.belongsTo)("organisation", {
      async: false
    }),
    createdById: (0, _relationships.belongsTo)("user", {
      async: false
    }),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    detailType: (0, _attr.default)("string"),
    districtId: (0, _attr.default)("number"),
    messageIds: (0, _attr.default)(),
    messages: (0, _relationships.hasMany)("message", {
      async: false
    }),
    ordersPurposes: (0, _relationships.hasMany)("orders_purpose", {
      async: false
    }),
    beneficiaryId: (0, _attr.default)("string"),
    bookingTypeId: (0, _attr.default)("number"),
    beneficiary: (0, _relationships.belongsTo)("beneficiary", {
      async: true
    }),
    peopleHelped: (0, _attr.default)("number"),
    goodcityRequests: (0, _relationships.hasMany)("goodcity_request", {
      async: false
    }),
    district: (0, _relationships.belongsTo)("district", {
      async: false
    }),
    bookingType: (0, _relationships.belongsTo)("booking_type", {
      async: false
    }),

    isGoodCityOrder: equal("detailType", "GoodCity"),
    isDraft: equal("state", "draft"),
    isSubmitted: equal("state", "submitted"),
    isAwaitingDispatch: equal("state", "awaiting_dispatch"),
    isDispatching: equal("state", "dispatching"),
    isClosed: equal("state", "closed"),
    isProcessing: equal("state", "processing"),
    isCancelled: equal("state", "cancelled"),
    i18n: service(),

    isAppointment: computed("bookingType", function () {
      return this.get("bookingType.isAppointment");
    }),

    isOnlineOrder: computed("bookingType", function () {
      return this.get("bookingType.isOnlineOrder");
    }),

    // only to show ordersPackages on confirmation screen
    orderItems: computed("ordersPackages.[]", function () {
      var items = [];
      this.get("ordersPackages").forEach(function (record) {
        if (record) {
          var pkg = record.get("package");
          items.push(pkg);
        }
      });
      return items.uniq();
    }),

    isAppointmentDraft: computed("state", "orderType", function () {
      return this.get("isAppointment") && this.get("isDraft");
    }),

    isOnlineOrderDraft: computed("state", "orderType", function () {
      return this.get("isOnlineOrder") && this.get("isDraft");
    }),

    isEditAllowed: computed("state", function () {
      var editableStates = ["draft", "submitted", "processing", "restart_process", "awaiting_dispatch"];
      return editableStates.indexOf(this.get("state")) >= 0;
    }),

    isCancelAllowed: computed("state", function () {
      var cancellableStates = ["submitted", "processing", "restart_process", "awaiting_dispatch"];
      return cancellableStates.indexOf(this.get("state")) >= 0;
    }),

    clientIdType: computed("beneficiary", "beneficiary.identityType", function () {
      return this.get("beneficiary.identityType.name");
    }),

    clientIdNumber: alias("beneficiary.identityNumber"),

    clientName: alias("beneficiary.fullName"),

    clientPhone: alias("beneficiary.phoneNumber"),

    appointmentTransport: computed("orderTransport.transportType", function () {
      var i18n = this.get("i18n");
      return this.get("orderTransport.transportType") === "self" ? i18n.t("order.appointment.self_vehicle") : i18n.t("order.appointment.hire_vehicle");
    }),

    appointmentDate: computed("orderTransport.scheduledAt", function () {
      var orderTransport = this.get("orderTransport");
      if (!orderTransport) {
        return "";
      }
      return moment(orderTransport.get("scheduledAt")).format("dddd MMMM Do");
    }),

    appointmentTime: computed("appointmentDate", "orderTransport.timeslot", function () {
      var orderTransport = this.get("orderTransport");
      if (!orderTransport) {
        return "";
      }
      return this.get("appointmentDate") + ", " + orderTransport.get("timeslot");
    }),

    stateIcon: computed("state", function () {
      var state = this.get("state");
      switch (state) {
        case "awaiting_dispatch":
        case "scheduled":
          return "clock";
        case "processing":
          return "list";
        case "submitted":
          return "envelope";
        case "dispatching":
          return "paper-plane";
        case "cancelled":
          return "thumbs-down";
        case "closed":
          return "lock";
        case "draft":
          return "pencil-alt";
        default:
          return "";
      }
    }),

    purposeName: computed("ordersPurposes.[]", function () {
      return this.get("ordersPurposes.firstObject.purpose.description");
    }),

    orderDetailTypeIcon: Ember.computed("isAppointment", "isOnlineOrder", function () {
      if (this.get("isAppointment")) {
        return "warehouse";
      } else if (this.get("isOnlineOrder")) {
        return "desktop";
      }
    }),

    transportIcon: computed("transportKey", function () {
      var key = this.get("transportKey");
      switch (key) {
        case "gogovan_transport":
          return "truck";
        case "collection_transport":
          return "male";
        default:
          return "";
      }
    }),

    transportLabel: computed("transportKey", function () {
      var key = this.get("transportKey");
      return this.get("i18n").t("my_orders.order_transports." + key);
    }),

    transportKey: computed("orderTransport.transportType", function () {
      var transportId = this.get("orderTransportId");
      if (!transportId || !this.get("store").peekRecord("order_transport", transportId)) {
        return "unknown_transport";
      }

      var transportType = this.get("orderTransport.transportType");
      switch (transportType) {
        case "ggv":
          return "gogovan_transport";
        case "self":
          return "collection_transport";
        default:
          return "unknown_transport";
      }
    }),

    // unread order messages
    unreadMessagesCount: computed("messages.@each.state", function () {
      return this.get("messages").filterBy("state", "unread").length;
    }),

    hasUnreadMessages: computed("unreadMessagesCount", function () {
      return this.get("unreadMessagesCount") > 0;
    })
  });
});