define("browse/routes/my_orders", ["exports", "lodash", "browse/routes/authorize"], function (exports, _lodash, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = _authorize.default.extend({
    orderService: service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.set("cart.checkout", false);
    },
    model: function model() {
      var _this = this;

      return hash({
        organisation: this.store.peekAll("organisation").objectAt(0),
        user: this.store.peekAll("user").objectAt(0),
        orders: this.get("orderService").fetchOrdersOfType({
          shallow: true,
          appointment: false
        })
      }).then(function (res) {
        return _this.get("orderService").loadOrderTransports().then(function () {
          return res;
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.toggleProperty("triggerFlashMessage");
      this.controllerFor("application").set("hideHeaderBar", false);
      this.controllerFor("application").set("pageTitle", this.get("i18n").t("my_dashboard.title"));
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.controllerFor("application").set("pageTitle", this.get("i18n").t("browse.title"));
      }
    }
  });
});