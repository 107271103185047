define("browse/routes/search_organisation", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorize.default.extend({
    setupController: function setupController() {
      this.controllerFor("application").set("showSidebar", false);
    },


    resetController: function resetController(isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.controllerFor("application").set("showSidebar", true);
      }
    }
  });
});