define("browse/controllers/offers/index", ["exports", "lodash"], function (exports, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    showSearch: false,

    selectedSort: computed({
      get: function get() {
        return this.get("sortOptions.firstObject.id");
      },
      set: function set(key, value) {
        return value.id || value;
      }
    }),

    offers: computed("model", "selectedSort", function () {
      var sortedOffer = _lodash.default.sortBy(this.get("model"), [function (offer) {
        return offer.submitted_at || offer.created_at;
      }]);
      return this.get("selectedSort") == "asc" ? sortedOffer : sortedOffer.reverse();
    }),

    sortOptions: computed(function () {
      return [{
        name: this.get("i18n").t("category.sort.newfirst"),
        id: "desc"
      }, { name: this.get("i18n").t("category.sort.oldfirst"), id: "asc" }];
    })
  });
});