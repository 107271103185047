define("browse/mixins/quantity_update", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    cart: service(),
    updatedCartValues: {},

    updateRequestedQuantityValue: function updateRequestedQuantityValue(record) {
      var _this = this;

      return Object.keys(record).map(function (pkgId) {
        _this.get("cart").updateRequestedQuantity(pkgId, record[pkgId]);
      });
    },


    actions: {
      UpdateRequestedValue: function UpdateRequestedValue(value, id) {
        var quantityHash = _defineProperty({}, id, value);
        Object.assign(this.get("updatedCartValues"), quantityHash);
      },
      resetUpdatedQuantity: function resetUpdatedQuantity() {
        this.set("updatedCartValues", {});
      }
    }
  });
});