define("browse/routes/search_goods", ["exports", "browse/routes/browse_pages"], function (exports, _browse_pages) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _browse_pages.default.extend({
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var applicationController = this.controllerFor("application");
      applicationController.set("hideHeaderBar", true);
    },


    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        var applicationController = this.controllerFor("application");
        applicationController.set("hideHeaderBar", false);
      }
    }
  });
});