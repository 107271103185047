define("browse/models/address", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    flat: (0, _attr.default)("string"),
    building: (0, _attr.default)("string"),
    street: (0, _attr.default)("string"),
    addressType: (0, _attr.default)("string"),
    notes: (0, _attr.default)("string"),

    district: (0, _relationships.belongsTo)("district", { async: false }),

    addressableType: (0, _attr.default)("string"),
    addressable: (0, _relationships.belongsTo)("addressable", { polymorphic: true, async: false }),

    fullAddress: computed("flat", "building", "street", function () {
      return this.get("flat") + " " + this.get("building") + " " + this.get("street");
    })
  });
});