define("browse/instance-initializers/cloudinary", ["exports", "browse/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: "cloudinary",
    initialize: function initialize() {
      $.cloudinary.config({
        cloud_name: _environment.default.APP.CLOUD_NAME,
        api_key: _environment.default.APP.CLOUD_API_KEY
      });
    }
  };
});