define("browse/models/goodcity_setting", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    key: (0, _attr.default)("string"),
    value: (0, _attr.default)("string")
  });
});