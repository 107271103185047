define("browse/routes/account_details", ["exports", "browse/routes/authorize"], function (exports, _authorize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  exports.default = _authorize.default.extend({
    model: function model(params) {
      var user = this.get("session.currentUser");
      var organisationsUser = user.get("organisationsUsers") && user.get("organisationsUsers.firstObject");
      var organisation = organisationsUser && organisationsUser.get("organisation");

      return hash({
        organisation: params.orgId ? this.store.peekRecord("organisation", parseInt(params.orgId)) : organisation,
        organisationsUser: organisationsUser,
        user: user
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("application").set("showSidebar", false);
    },
    deactivate: function deactivate() {
      this.controllerFor("application").set("showSidebar", true);
    }
  });
});