define("browse/services/account-service", ["exports", "browse/services/api-base-service"], function (exports, _apiBaseService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _apiBaseService.default.extend({
    isOpen: false,
    openPhoneOverlay: false,

    setOverlayVisibility: function setOverlayVisibility(val) {
      this.set("openPhoneOverlay", val);
    },
    initiatePinFor: function initiatePinFor(mobile) {
      return this.POST("/auth/resend_pin", {
        mobile: mobile
      }, { version: "2" });
    },
    updatePhoneNumber: function updatePhoneNumber(userId, _ref) {
      var token = _ref.token,
          pin = _ref.pin;

      return this.PUT("/users/" + userId + "/update_phone_number", {
        token: token,
        pin: pin
      }, {
        version: "2"
      });
    }
  });
});