define("browse/serializers/application", ["exports", "active-model-adapter", "lodash"], function (exports, _activeModelAdapter, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /*
  Ember is unable to pickup the polymorphic association with the hasMany relationship for messages
  We are intercepting the payload response and mapping the messageable_id with the order_id
  */
  function normalize(payload) {
    if (!payload) return;
    var messages = _lodash.default.flatten([payload.messages, payload.message]).filter(_lodash.default.identity);

    _lodash.default.each(messages, function (m) {
      if (m.messageable_type == "Order") {
        m.order_id = m.messageable_id;
      }

      if (m.messageable_type == "OfferResponse") {
        m.offer_response_id = m.messageable_id;
      }

      // This is done to handle inconsistent mapping of jsonb datatype
      if (_typeof(m.lookup) === "object") {
        m.lookup = JSON.stringify(m.lookup);
      }
    });
  }

  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      normalize(payload);
      return this._super.apply(this, arguments);
    },
    pushPayload: function pushPayload(store, payload) {
      normalize(payload);
      return this._super.apply(this, arguments);
    }
  });
});