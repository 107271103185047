define("browse/services/api-base-service", ["exports", "browse/utils/ajax-promise"], function (exports, _ajaxPromise) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    // ----- Services -----
    session: service(),

    // ----- Utilities -----
    _request: function _request(url, options, authorizedRequest) {
      var action = options.action,
          body = options.body,
          version = options.version;

      return new _ajaxPromise.default(url, action, authorizedRequest ? this.get("session.authToken") : null, body, { version: version }, this.get("session.language"));
    },


    // ----- CRUD ACTIONS -----
    /**
      authorizedRequest is optional parameter to be be sent during request.
      By default requests are authorized
    **/
    GET: function GET(url) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _opts$authorizedReque = opts.authorizedRequest,
          authorizedRequest = _opts$authorizedReque === undefined ? true : _opts$authorizedReque,
          version = opts.version;


      return this._request(url, {
        action: "GET",
        version: version
      }, authorizedRequest);
    },


    /**
     *
     * @param {*} url
     * @param {*} body
     * @param {*} opts
     * @returns
     */
    POST: function POST(url, body) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var _opts$authorizedReque2 = opts.authorizedRequest,
          authorizedRequest = _opts$authorizedReque2 === undefined ? true : _opts$authorizedReque2,
          version = opts.version;

      return this._request(url, {
        action: "POST",
        body: body,
        version: version
      }, authorizedRequest);
    },
    PUT: function PUT(url, body) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var _opts$authorizedReque3 = opts.authorizedRequest,
          authorizedRequest = _opts$authorizedReque3 === undefined ? true : _opts$authorizedReque3,
          version = opts.version;

      return this._request(url, {
        action: "PUT",
        body: body,
        version: version
      }, authorizedRequest);
    },
    DELETE: function DELETE(url) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _opts$authorizedReque4 = opts.authorizedRequest,
          authorizedRequest = _opts$authorizedReque4 === undefined ? true : _opts$authorizedReque4;

      return this._request(url, {
        action: "DELETE"
      }, authorizedRequest);
    }
  });
});