define("browse/components/online-orders-icon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  exports.default = Component.extend({
    messages: service(),

    unreadOrdersMessagesCount: alias("messages.unreadOrdersMessagesCount"),

    hasMessages: gt("unreadOrdersMessagesCount", 0)
  });
});