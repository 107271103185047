define("browse/models/offer_response", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  exports.default = _model.default.extend({
    userId: (0, _attr.default)("string"),
    offerId: (0, _attr.default)("string"),

    messages: (0, _relationships.hasMany)("message", {
      async: false
    }),

    offer: (0, _relationships.belongsTo)("offer", { async: false })
  });
});