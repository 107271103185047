define('browse/components/slick-carousel', ['exports', 'ember-cli-slick-carousel/components/slick-carousel'], function (exports, _slickCarousel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _slickCarousel.default;
    }
  });
});