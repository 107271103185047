define("browse/components/message-box", ["exports", "browse/templates/components/message-box"], function (exports, _messageBox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _messageBox.default,
    message: "",
    btn1Text: "",
    btn1Callback: function btn1Callback() {},
    btn2Text: "",
    btn2Callback: function btn2Callback() {},
    displayCloseLink: false,

    isVisible: false,

    close: function close() {
      if (this.get("isVisible")) {
        this.set("isVisible", false);
      } else {
        this.destroy();
      }
    },


    actions: {
      btn1Click: function btn1Click() {
        var callbackOutput = true;
        if (this.btn1Callback) {
          callbackOutput = this.btn1Callback();
        }
        if (callbackOutput !== false) {
          this.close();
        }
      },
      btn2Click: function btn2Click() {
        if (this.btn2Callback) {
          this.btn2Callback();
        }
        this.close();
      },
      closeModal: function closeModal() {
        this.close();
      },


      //Fix: Too deeply nested component(3 levels) failing randomly(Known issue with Ember)
      //Remove when Ember is upgraded to >= 3.0
      updateErrorMessage: function updateErrorMessage() {
        return false;
      }
    }
  });
});