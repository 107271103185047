define("browse/components/month-calender", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var scheduleOnce = Ember.run.scheduleOnce;
  var next = Ember.run.next;
  var TextField = Ember.TextField;
  exports.default = TextField.extend({
    tagName: "input",
    classNames: "pickadate",
    attributeBindings: ["name", "type", "value", "id", "required", "pattern", "available", "placeholder"],

    currentMinutes: function currentMinutes() {
      var currentTime = new Date();
      var hours = currentTime.getHours();
      var minutes = currentTime.getMinutes();
      var total_mins = hours * 60 + minutes;
      return total_mins > 961 ? 961 : total_mins;
    },

    _currentDay: function _currentDay() {
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return currentDate;
    },

    _getValidDate: function _getValidDate(selectedDate) {
      var today = new Date();
      var currentDate = new Date();
      var selected = selectedDate;
      currentDate.setHours(0, 0, 0, 0);
      selected.setHours(0, 0, 0, 0);
      return currentDate > selected ? today : selectedDate;
    },

    // TO DO: when we have final logic for when to allow user to book slot.
    _setTimeSlots: function _setTimeSlots() {
      // var selectedDate = date;
      // var currentDate = new Date();
      // currentDate.setHours(0,0,0,0);
      // selectedDate.setHours(0,0,0,0);
      // if(selectedDate.getTime() === currentDate.getTime()) {
      //   var total_mins = this.currentMinutes();
      //   var option;
      //   if(total_mins >= 780 && total_mins < 960) {
      //     option = Ember.$(".time_selector select option:eq(1)");
      //   } else if(total_mins >= 960) {
      //     option = Ember.$(".time_selector select option:eq(2)");
      //   }
      //   if(option && option.length > 0) {
      //     option.addClass("hidden");
      //     option[0].disabled = true;
      //     if(option.is(':selected')) { option.prop("selected", false) }
      //     option.prevAll().each(function() {
      //       Ember.$( this ).addClass("hidden");
      //       this.disabled = true;
      //     });
      //   }
      // } else {
      //   // Enable all select options
      //   Ember.$(".time_selector select option").each(function() {
      //     Ember.$( this ).removeClass("hidden");
      //     this.disabled = false;
      //   });
      // }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      var list = this.get("available");
      var available_count = 0,
          available_array = [true];
      var setting = false;

      if (list) {
        available_count = list.length;
        for (var i = available_count - 1; i >= 0; i--) {
          var date = new Date(list[i]);
          var date_array = [];
          date_array.push(date.getFullYear());
          date_array.push(date.getMonth());
          date_array.push(date.getDate());
          available_array.push(date_array);
        }

        // var firstDateArray = available_array.get("lastObject");
        // var firstDate = new Date(firstDateArray[0], firstDateArray[1],firstDateArray[2]);
        // var isTodayListed = _this._currentDay().getTime() === firstDate.getTime();
        // if(_this.currentMinutes() === 961 && isTodayListed) { available_array.pop(); }
        available_array.pop();
      }

      scheduleOnce("afterRender", this, function () {
        $(".pickadate").pickadate({
          format: "ddd mmm d",
          monthsFull: moment.months(),
          monthsShort: moment.monthsShort(),
          weekdaysShort: moment.weekdaysShort(),
          disable: available_array,
          clear: false,
          today: false,
          close: false,
          min: available_array[available_array.length - 1],
          max: available_array[1],

          onClose: function onClose() {
            var _this2 = this;

            $(document.activeElement).blur();
            if (setting) {
              return;
            }
            var date = this.get("select") && this.get("select").obj;

            if (date) {
              _this.set("selection", date);
              $(".time_selector select").val("");

              setting = true;
              next(function () {
                _this2.set("select", new Date(date), { format: "ddd mmm d" });
                setting = false;
              });
              _this._setTimeSlots(date);
            }
          },

          onStart: function onStart() {
            var date = _this.get("selection");
            if (date) {
              date = _this._getValidDate(date);
              this.set("select", new Date(date), { format: "ddd mmm d" });
              _this._setTimeSlots(date);
            }
          }
        });

        validateForm();
        validateInputs();
        closeOnClick();
      });

      function closeOnClick() {
        $(".picker__holder").click(function (e) {
          if (e.target !== this) {
            return;
          }
          $("#selectedDate").trigger("blur");
        });
      }

      function validateForm() {
        $(".button.drop_off").click(function () {
          var date = checkInput($("#selectedDate"));
          var time = checkInput($(".time_selector select"));
          return date && time;
        });
      }

      function validateInputs() {
        $("#selectedDate").focus(function () {
          return removeHighlight(this);
        });
        $(".time_selector select").focus(function () {
          return removeHighlight(this);
        });
      }

      function checkInput(element) {
        var parent = $(element).parent();
        var value = $(element).val();

        if (value === undefined || value.length === 0) {
          parent.addClass("form__control--error");
          return false;
        } else {
          parent.removeClass("form__control--error");
          return true;
        }
      }

      function removeHighlight(element) {
        var parent = $(element).parent();
        parent.removeClass("form__control--error");
      }
    }
  });
});