define("browse/routes/orders/booking", ["exports", "browse/routes/orders/detail"], function (exports, _detail) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _detail.default.extend({
    afterModel: function afterModel(model) {
      this._super(model);
      // https://github.com/dollarshaveclub/ember-router-scroll.
      // Read this link for nested route issue for not scrolling at top of the page
      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});