define("browse/models/image", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "browse/mixins/cloudinary_image"], function (exports, _model, _attr, _relationships, _cloudinary_image) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend(_cloudinary_image.default, {
    favourite: (0, _attr.default)("boolean"),
    cloudinaryId: (0, _attr.default)("string"),
    package: (0, _relationships.belongsTo)("package", { async: false }),
    angle: (0, _attr.default)("string"),

    imageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl();
    }),

    defaultImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(500, 500, true);
    }),

    thumbImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(50, 50, true);
    }),

    miniImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(30, 30, true);
    }),

    cartImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(80, 80, true);
    }),

    previewImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(265, 265, true);
    }),

    smallScreenPreviewImageUrl: computed("cloudinaryId", "angle", function () {
      return this.generateUrl(640, 365, true);
    })
  });
});