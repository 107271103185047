define("browse/routes/orders/conversation", ["exports", "browse/routes/orders/detail"], function (exports, _detail) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  exports.default = _detail.default.extend({
    model: function model(params) {
      return hash({
        order: this.store.peekRecord("order", params.order_id, {
          reload: true
        }) || this.store.findRecord("order", params.order_id),
        messages: this.store.query("message", {
          order_id: params.order_id
        })
      });
    },
    afterModel: function afterModel(model) {
      //jshint ignore:line
      //Overriding to neglect afterModel in detail
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model.order);
      controller.send("markRead");
      this.controllerFor("application").set("hideHeaderBar", true);
      controller.on();
    },
    resetController: function resetController(controller) {
      controller.off();
    }
  });
});