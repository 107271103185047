define('browse/initializers/i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'i18n',
    after: 'ember-i18n',

    initialize: function initialize(app) {
      ['component', 'controller', 'model', 'route', 'view'].forEach(function (type) {
        app.inject(type, 'i18n', 'service:i18n');
      });
    }
  };
});